import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { MAP_API_KEYS, SHOW_TOOL_FINDER } from '../store/map';
import { METADATA_URL } from '../store/url';
import { ClientProject, USER_LABEL } from '../store/user';

const mapApiProviders = ['nearmap'] as const;
export type MapApiProvider = typeof mapApiProviders[number];
export type MapApiKey = { provider: MapApiProvider; key: string };

// Type can be improved when required, but at the moment we only need the map api keys.
export type ServiceConfig = {
  service_config?: Record<string, unknown>;
  underground_config?: Record<string, unknown>;
  apply_to?: unknown[];
  in_time_windows?: unknown[];
  offline_bounds?: Feature<Polygon>;
  map_api_keys?: MapApiKey[];
  showToolFinder?: boolean;
};

export const useServiceConfigCallback = () => {
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const user = useAtomValue(USER_LABEL);
  const setShowToolFinder = useSetAtom(SHOW_TOOL_FINDER);
  const setMapApiKeys = useSetAtom(MAP_API_KEYS);
  const httpBaseUrl = useAtomValue(METADATA_URL);

  const fetchServiceConfig = useCallback(
    async ({ clientId, projectId }: ClientProject) => {
      const { data }: { data: ServiceConfig } = await axios.post(
        `${httpBaseUrl}/${clientId}/${projectId}/serviceconfig/for`,
        { assetId: { user }, iso8601: new Date().toISOString() },
        authedRequestConfig,
      );
      setShowToolFinder(!!data.service_config?.beacon_proximity_enabled);
      setMapApiKeys(
        Object.fromEntries((data.map_api_keys || []).map(({ provider, key }) => [provider, key])),
      );
    },
    [httpBaseUrl, user, authedRequestConfig, setMapApiKeys, setShowToolFinder],
  );

  return fetchServiceConfig;
};
