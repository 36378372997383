import { option } from 'fp-ts/es6';
import { GeoJSONPoint } from 'ol/format/GeoJSON';
import { MicrofenceAssetId } from './GeofenceEditor/GeofenceEditorFunctions';

export interface GlobalProjectId {
  cid: string;
  pid: string;
}
export interface GlobalLayerId {
  clientId: string;
  projectId: string;
  layerId: string;
}

export function gpidToPath(path: string, gpid: GlobalProjectId): string {
  return `${path}/${gpid.cid}/${gpid.pid}`;
}
export function glidToPath(glid: GlobalLayerId): string {
  return `/collider/${glid.clientId}/${glid.projectId}/${glid.layerId}`;
}
export function gcidToPath(gcid: GlobalClusterId): string {
  return `/cluster/${gcid.clientId}/${gcid.projectId}/${gcid.clusterId}`;
}

export interface GlobalClusterId {
  clientId: string;
  projectId: string;
  clusterId: string;
}

export enum MessageType {
  Coordinates = 'coordinates',
  CoordinatesDwell = 'coordinates.dwell',
  CoordinatesTriggered = 'coordinates.triggered',
  Sensed = 'sensed',
  SensedTriggered = 'sensed.triggered',
  SensorTriggeredBoundary = 'sensor.triggered.boundary',
  Battery = 'battery',
  Temperature = 'temperature',
  Spo2 = 'spo2',
  Notification = 'notification',

  Undefined = 'Undefined',
  NoOp = 'NoOp',
  DeviceLocation = 'DeviceLocation',
  GeofenceEvent = 'GeofenceEvent',
  SensedBeacons = 'SensedBeacons',
  LocalBeacons = 'LocalBeacons',
  ClusterEvent = 'ClusterEvent',
  SensedTemp = 'SensedTemp',
  TempRangeEvent = 'TempRangeEvent',
  WelfareCheckResponse = 'WelfareCheckResponse',
  SensedSpo2 = 'SensedSpo2',
}

export type FenceEvent = 'Entered' | 'Exited' | 'Dwell';
export type FenceType = 'polygon' | 'line' | 'point' | 'multipolygon';

export function idFromFenceEvent(id: string, fe: FenceEvent, ft: FenceType): string {
  if (ft === 'polygon' || ft === 'multipolygon' || ft === 'point') {
    return '' + id + '_' + fe;
  } else {
    return '' + id + '_' + (fe === 'Entered' ? 'Left to Right' : 'Right to Left');
  }
}

export interface NoOp {
  empty: string;
}

export interface GeofenceEvent {
  id: Record<string, string | undefined>;
  assetId: string;
  layerId: string;
  fenceId: string;
  fenceName: string;
  type: FenceType;
  y: number;
  x: number;
  event: FenceEvent;
  timestamp: string;
  dwellSeconds?: number;
}

export interface ReportBeacon {
  beacon_id: string;
  label: string;
  uuid: string;
  major: number;
  minor: number;
  rssi: number;
  txpower: number;
  distance: number;
  battery_level: number;
}

export interface SensedAsset {
  id: Record<string, string | unknown>;
  label?: string;
  beaconId?: string;
  rssi: number;
  txpower: number;
  distance?: number;
  battery_level?: number;
  type?: 'TOOL' | undefined;
  toolType?: string;
}

export interface SensedAssetsReport {
  id: Record<string, string | undefined>;
  assetId?: string | undefined;
  assets: SensedAsset[];
  timestamp: string;
}

export interface SensedTriggeredEvent {
  id: Record<string, string | undefined>;
  assetId: string | undefined;
  sensedId: Record<string, string | undefined>;
  sensedLabel: string | undefined;
  timestamp: string;
  entered: boolean;
}

export interface LocalBeacons {
  id: Record<string, string | undefined>;
  label?: string;
  assetId: undefined;
  deviceLocation: DeviceLocation;
  nearbyBeacons: ReportBeacon[];
}

export interface DeviceLocation {
  id: Record<string, string | undefined>;
  assetId: string;
  label: string;
  beaconId?: string;
  source?: { label: string; id: { [key: string]: string } };
  lat: number;
  lon: number;
  radius: number;
  timestamp: string;
  type?: 'TOOL' | undefined;
  toolType?: string;
}

export interface SensedByDevice {
  beaconId?: string;
  source?: { label: string; id: { [key: string]: string } };
  timestamp: string;
}

export interface WelfareCheckResponse {
  id: Record<string, string | undefined>;
  assetId: string;
  ok: boolean;
  timestamp: string;
}

export interface NotificationEvent {
  id: Record<string, string | undefined>;
  notificationId: string;
  assetId: string;
  label: string;
  timestamp: string;
  interaction?:
    | {
        fenceId: string;
        type: 'find-fence';
      }
    | {
        assetId: string;
        type: 'find-asset' | 'find-fence-event';
      }
    | {
        microfenceId: Record<string, string>;
        microfenceName: string;
        type: 'find-microfence';
      };
}

export interface CMContainer {
  toUnixTs: number;
  gpid: GlobalProjectId;
  created: string;
  type: MessageType;
  message:
    | GeofenceEvent
    | SensedAssetsReport
    | LocalBeacons
    | DeviceLocation
    | ClusterEvent
    | SensedTemp
    | TempRangeEvent
    | NoOp
    | WelfareCheckResponse;
}

export interface ClusterBeaconData {
  beaconId: string;
  path: string;
  enter_rssi: number;
  exit_rssi: number;
  uuid: string;
  major: number;
  minor: number;
}

export interface LayerFenceData {
  layerId: string;
  fenceId: string;
  layerName?: string;
  fenceName?: string;
}

export interface PointData {
  fenceId: string;
  name: string;
  fenceType: FenceType;
  layerId: string;
  type: string;
}

export interface MicrofenceData extends LayerFenceData {
  assetId: MicrofenceAssetId;
  boundaryRssi: number;
  timeoutSeconds: number;
  point: GeoJSONPoint;
}

export interface ClusterEvent {
  assetId: string;
  clusterId: string;
  beaconId: string;
  event: FenceEvent;
  timestamp: string;
}

export interface SensedTemp {
  id: Record<string, string | undefined>;
  assetId: string;
  temp: number;
  timestamp: string;
}

export interface TempRangeEvent {
  id: Record<string, string | undefined>;
  assetId: string;
  tempRangeId: string;
  tempRangeLabel: string;
  timestamp: string;
  event: FenceEvent;
  limit: number;
  temp: number;
}

export interface AssetState {
  id: Record<string, string | undefined>;
  label: string;
  source?: { label: string; id: { [key: string]: string } };
  lastLocation: option.Option<DeviceLocation>;
  lastFenceEvent: option.Option<GeofenceEvent>;
  lastSensed: option.Option<SensedAssetsReport>;
  lastLocalBeacons: option.Option<LocalBeacons>;
  lastTemp: option.Option<SensedTemp>;
  lastTempRangeEvent: option.Option<TempRangeEvent>;
  lastWelfareCheckResponse: option.Option<WelfareCheckResponse>;
  recentSensedTriggered: option.Option<SensedTriggeredEvent[]>;
}
