import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useAsyncTask } from '../../util/AsyncTask';

export type Boundary = {
  id: string;
  name: string;
  type: number;
  higherIsEnter: boolean;
  boundary: number;
  createdAt: string;
  updatedAt: string;
};

export const useAllBoundaries = () => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const boundaries = useCallback(
    async () =>
      (await axios.get<Boundary[]>(`${httpBaseUrl}/${cid}/${pid}/boundary`, authedConfig)).data,
    [httpBaseUrl, authedConfig, cid, pid],
  );

  return useAsyncTask<Boundary[], typeof boundaries>(boundaries);
};
