import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { TRIGGERS_URL } from '../store/url';
import { CID, PID } from '../store/user';
import { AsyncTask } from '../util/AsyncTask';

export type Geofence = {
  id: string;
  name: string;
  clientId: string;
  projectId: string;
  createdAt: Date;
  updatedAt: Date | null;
  polygons: {
    id: string;
    name: string;
    points: { type: 'Polygon'; coordinates: [longitude: number, latitude: number][][] };
  }[];
  lines: {
    id: string;
    name: string;
    points: { type: 'LineString'; coordinates: [longitude: number, latitude: number][] };
  }[];
};

export const useGeofences = (): AsyncTask<Geofence[]> & { load: () => void } => {
  const [data, setData] = useState<Geofence[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const load = useCallback(async () => {
    try {
      setData(undefined);
      setError(undefined);
      setLoading(true);
      const response = await axios.get<Geofence[]>(
        `${triggersUrl}/${cid}/${pid}/geofences`,
        authedConfig,
      );
      if (response.status === 200) {
        setData(response.data);
      } else {
        setError(response);
      }
    } catch (error: unknown) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [cid, pid, triggersUrl, authedConfig]);

  return { load, data, loading, error };
};
