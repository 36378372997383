import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLogout } from '../../Authn/useLogout';
import { NOTIFICATIONS } from '../../store/notifications';
import { CID, PID, SUPER_ADMIN } from '../../store/user';
import { AccountCircle, CircleNotifications } from '@mui/icons-material';
import { NotificationPanel } from '../../Notifications/NotificationPanel';
import { SHOW_TOOL_FINDER } from '../../store/map';

const SPECIAL_PROJECT_ID = 'test_project'; // Has extra tabs enabled

export const NavigationTabs = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const isSuperAdmin = useAtomValue(SUPER_ADMIN);
  const showToolFinder = useAtomValue(SHOW_TOOL_FINDER);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [notificationElement, setNotificationElement] =
    React.useState<HTMLButtonElement | null>(null);
  const pathname = useLocation().pathname;

  const currentTabPath = useMemo(() => {
    if (pathname === '/events-creator') {
      return '/events';
    } else if (pathname === '/super-admin') {
      return false;
    }
    return pathname;
  }, [pathname]);

  const notifications = useAtomValue(NOTIFICATIONS);

  const notificationsProps: SxProps<Theme> = useMemo(() => {
    const mt = pathname === '/replay-map' ? 8.5 : 1.5;
    return { mt };
  }, [pathname]);
  const mapType: 'replay' | 'live' | undefined = useMemo(() => {
    if (pathname === '/replay-map') {
      return 'replay';
    } else if (pathname === '/live-map') {
      return 'live';
    }
    return undefined;
  }, [pathname]);

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Box pl={2} height={22}>
            <img src="./GeoMoby-full-white.svg" alt="Logo Geomoby" height="100%" />
          </Box>
          <Tabs value={currentTabPath} variant="scrollable" scrollButtons="auto">
            {[
              {
                label: 'Live Map',
                path: `/live-map`,
              },
              { label: 'Replay', path: '/replay-map' },
              { label: 'Editor', path: '/geofence-editor' },
              { label: 'Event Heat Map', path: '/event-heat-map' },
              { label: 'Events', path: '/events' },
              { label: 'Tools', path: '/tools-editor', disabled: !showToolFinder },
              { label: 'Contact Tracing', path: '/contact-tracing', disabled: true },
              { label: 'Reports', path: '/productivity-reports', disabled: true },
            ].map(({ path, label, disabled }) => (
              <Tab
                key={path}
                value={path}
                label={label}
                disabled={disabled}
                onClick={() => {
                  navigate(path);
                }}
              />
            ))}
          </Tabs>
          <Box pr={1}>
            <Stack direction="row">
              <IconButton
                onClick={event => {
                  setNotificationElement(event.currentTarget);
                }}
              >
                <Badge
                  color="error"
                  overlap="circular"
                  sx={{ padding: '4px' }}
                  badgeContent={
                    notifications.filter(({ seen, replayed }) =>
                      mapType === 'replay' ? !seen : !seen && !replayed,
                    ).length
                  }
                >
                  <CircleNotifications />
                </Badge>
              </IconButton>
              <IconButton
                onClick={event => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <AccountCircle />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      </AppBar>
      <Popover
        open={Boolean(notificationElement)}
        anchorEl={notificationElement}
        onClose={() => setNotificationElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: notificationsProps,
        }}
      >
        <NotificationPanel map={mapType} />
      </Popover>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack p={2} alignItems="center">
          <Typography pb={1}>
            <strong>
              {cid} / {pid}
            </strong>
          </Typography>
          {isSuperAdmin && (
            <Button fullWidth onClick={() => navigate('/super-admin')}>
              Super admin
            </Button>
          )}
          <Button fullWidth onClick={() => navigate('/my-account')}>
            My account
          </Button>
          <Button fullWidth onClick={() => navigate('/project-config')}>
            Project Configuration
          </Button>
          <Button fullWidth onClick={() => navigate('/project-selection')}>
            Change project
          </Button>
          <Button fullWidth onClick={logout}>
            Logout
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
