import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { useServiceConfigCallback } from '../Authn/useServcieConfig';
import { CID, PID } from '../store/user';

export const ServiceConfigProvider = ({ children }: { children: ReactNode }) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const fetchServiceConfig = useServiceConfigCallback();
  fetchServiceConfig({ clientId: cid, projectId: pid });
  return <>{children}</>;
};
