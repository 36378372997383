import axios from 'axios';
import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { uniqBy } from 'lodash';
import { hardRedirectToLoginPage } from '../util/hardRedirectToLoginPage';
import { AUTHED_REQUEST_CONFIG } from './auth';
import { AUTHN_URL } from './url';

export type ClientProject = { clientId: string; projectId: string };
export type UserAccess = { type: 'superadmin' | 'client-project' } & ClientProject;

export const ME = atom(async get => {
  try {
    return (
      await axios.get<{
        id: string;
        label: string;
        access: UserAccess[];
      }>(`${get(AUTHN_URL)}/open/user/me`, get(AUTHED_REQUEST_CONFIG))
    ).data;
  } catch (e) {
    return hardRedirectToLoginPage('Failed to load claims');
  }
});
export const USER_ID = atom(get => get(ME).id);
export const USER_LABEL = atom(get => get(ME).label);
export const USER_ACCESS = atom(get => get(ME).access);

export const PERSISTED_CID = atomWithStorage<null | string>(
  '@geomoby/cid',
  null,
  createJSONStorage(() => sessionStorage),
);
export const PERSISTED_PID = atomWithStorage<null | string>(
  '@geomoby/pid',
  null,
  createJSONStorage(() => sessionStorage),
);

export const SUPER_ADMIN = atom(get =>
  get(USER_ACCESS).some(access => access.type === 'superadmin'),
);

export const CID_PID_PAIRS = atom(async get => {
  const access = get(USER_ACCESS).map(access => access);

  if (!get(SUPER_ADMIN))
    return access.sort(
      (a, b) => a.clientId.localeCompare(b.clientId) || a.projectId.localeCompare(b.projectId),
    );

  try {
    const { data: users } = await axios.get<
      {
        access: [{ type: 'superadmin' | 'client-project'; clientId: string; projectId: string }];
      }[]
    >(`${get(AUTHN_URL)}/open/user`, get(AUTHED_REQUEST_CONFIG));

    return uniqBy(
      users.flatMap(user => user.access).filter(access => access.clientId && access.projectId),
      ({ clientId, projectId }) => `${clientId}/${projectId}`,
    ).sort(
      (a, b) => a.clientId.localeCompare(b.clientId) || a.projectId.localeCompare(b.projectId),
    );
  } catch (e) {
    return hardRedirectToLoginPage('Failed to load claims');
  }
});

export const CID = atom(
  get => get(PERSISTED_CID) || get(CID_PID_PAIRS)[0]?.clientId,
  (_, set, payload: string | null) => set(PERSISTED_CID, payload),
);

export const PID = atom(
  get => get(PERSISTED_PID) || get(CID_PID_PAIRS)[0]?.projectId,
  (_, set, payload: string | null) => set(PERSISTED_PID, payload),
);
