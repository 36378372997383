import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any*/
interface IconTemperatureRangeProps {
  style: any;
  color: any;
}
/* eslint-enable @typescript-eslint/no-explicit-any*/

const TemperatureRangeIcon: React.FC<IconTemperatureRangeProps> = ({ style, color }) => {
  return (
    <SvgIcon style={style} color={color}>
      <path d="M17 3H21V5H17V3M17 7H21V9H17V7M17 11H21V13H17.75L17 12.1V11M21 15V17H19C19 16.31 18.9 15.63 18.71 15H21M7 3V5H3V3H7M7 7V9H3V7H7M7 11V12.1L6.25 13H3V11H7M3 15H5.29C5.1 15.63 5 16.31 5 17H3V15M15 13V5C15 3.34 13.66 2 12 2S9 3.34 9 5V13C6.79 14.66 6.34 17.79 8 20S12.79 22.66 15 21 17.66 16.21 16 14C15.72 13.62 15.38 13.28 15 13M12 4C12.55 4 13 4.45 13 5V8H11V5C11 4.45 11.45 4 12 4Z" />
    </SvgIcon>
  );
};

export default TemperatureRangeIcon;
