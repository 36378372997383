import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AsyncTask } from '../../util/AsyncTask';

export type GeofenceTime = {
  creatorId: number;
  creatorIdentifiers: Record<string, unknown>;
  creatorLabel: string;
  geofenceId: string;
  geofenceType: string;
  duration: {
    hours: number;
    minutes: number;
    seconds: number;
  };
};

export const useGeofenceTimes = (
  from?: Date,
  to?: Date,
): AsyncTask<GeofenceTime[]> & { load: () => void } => {
  const [data, setData] = useState<GeofenceTime[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const fromTs = from ? Math.floor(from.getTime() / 1000) : null;
  const toTs = to ? Math.floor(to.getTime() / 1000) : null;

  const load = useCallback(async () => {
    try {
      setData(undefined);
      setError(undefined);
      setLoading(true);
      const response = await axios.get<GeofenceTime[]>(
        `${persistorUrl}/${cid}/${pid}/analytics/geofence-times?fromUnixTs=${fromTs}&toUnixTs=${toTs}`,
        authedConfig,
      );
      if (response.status === 200) {
        setData(
          response.data
            .sort(
              (a, b) =>
                Number(!!a.creatorIdentifiers.uuid) - Number(!!b.creatorIdentifiers.uuid) ||
                a.creatorLabel.localeCompare(b.creatorLabel),
            )
            .map((x, y, z) => ({
              ...x,
              uniqueLabel:
                z[y - 1]?.creatorLabel !== x.creatorLabel &&
                z[y + 1]?.creatorLabel !== x.creatorLabel,
            })),
        );
      } else {
        setError(response);
      }
    } catch (error: unknown) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [cid, pid, persistorUrl, authedConfig, fromTs, toTs]);

  return { load, data, loading, error };
};
