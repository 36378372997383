/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Box, TextField } from '@mui/material';
import { map, option, set } from 'fp-ts/es6';
import { ordString } from 'fp-ts/es6/Ord';
import { pipe } from 'fp-ts/es6/pipeable';
import { eqString } from 'fp-ts/lib/Eq';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { msToHM } from '../Global/TimeFormattingFunctions';

interface LayerFenceId {
  lid: string;
  fid: string;
}
export interface FenceVisit {
  aid: string;
  milliseconds: number;
  lfid: LayerFenceId;
}

interface ToGraph {
  assetId: string;
  layerFenceId: string;
  milliseconds: number;
  wage: number;
}

function fvsToAids(fvs: FenceVisit[]) {
  return pipe(
    fvs.map(fv => fv.aid),
    set.fromArray(eqString),
    set.toArray(ordString),
  );
}

function fvsToGraph(fvs: FenceVisit[], aid: string) {
  const lfidStr = (lfid: LayerFenceId) => `${lfid.lid}/${lfid.fid}`;
  const millisBtwn = (from: string, to: string) =>
    option.tryCatch(() => {
      const fms = new Date(from);
      const tms = new Date(to);
      return tms.getTime() - fms.getTime();
    });

  const res = new Map<string, ToGraph>();
  fvs
    .filter(fv => fv.aid === aid)
    .forEach(fv => {
      const lfid = lfidStr(fv.lfid);
      const tosave = pipe(
        // existing value or appropriate new value
        option.fromNullable(res.get(lfid)),
        option.getOrElse(() => {
          return {
            assetId: fv.aid,
            layerFenceId: lfid,
            milliseconds: 0,
            wage: 0,
          };
        }),
      );
      pipe(fv.milliseconds, millis => {
        // If we have milliseconds, update the map
        //this line use to be without return, but js complained
        return res.set(tosave.layerFenceId, {
          ...tosave,
          milliseconds: tosave.milliseconds + millis,
          wage: ((tosave.milliseconds + millis) / 1000 / 60 / 60) * 45,
        });
      });
    });
  return map
    .toArray(ordString)(res)
    .map(v => v[1]);
}

export const ChartTravelTimeInFence = ({ fenceVisits }: { fenceVisits: FenceVisit[] }) => {
  const [aid, setAid] = useState<string>();

  const aids = useMemo(() => fvsToAids(fenceVisits), [fenceVisits]);
  const graph = useMemo(() => (aid ? fvsToGraph(fenceVisits, aid) : []), [fenceVisits, aid]);

  useEffect(() => {
    setAid(aids[0] ?? undefined);
  }, [aids]);

  return (
    <Box fontSize={13} width="100%">
      <Autocomplete
        defaultValue={aids[0]}
        options={aids}
        onChange={(_, value) => setAid(value ?? undefined)}
        renderInput={params => <TextField {...params} label="Report for device" />}
      />
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={graph} barSize={30}>
          <CartesianGrid
            stroke="grey"
            vertical={false}
            opacity={0.1}
            strokeWidth={1}
            strokeDasharray="6 6"
          />
          <XAxis dataKey="layerFenceId" axisLine={false} stroke="#A6ACBE" />
          <YAxis
            yAxisId="left"
            orientation="left"
            axisLine={false}
            stroke="#A6ACBE"
            tickLine={false}
            tickFormatter={tickFormatter}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            stroke="#A6ACBE"
            tickLine={false}
            tickFormatter={moneyFormatter}
          />
          <Tooltip
            labelStyle={{ color: '#23272D' }}
            labelFormatter={label => `GeoFence: ${label}`}
            formatter={tooltipFormater}
            cursor={{ fill: 'none' }}
            isAnimationActive={true}
          />
          <Legend verticalAlign={'top'} align={'center'} layout={'horizontal'} />
          <Bar
            yAxisId="left"
            dataKey="milliseconds"
            fill="#4CB8C4"
            radius={[5, 5, 5, 5]}
            name="Time spent in GeoFence"
            legendType="circle"
          />
          <Bar
            yAxisId="right"
            dataKey="wage"
            fill="#4F4F4F"
            radius={[5, 5, 5, 5]}
            name="Cost based on $45 hourly"
            legendType="circle"
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const tickFormatter = (value: any) => {
  const numValue = typeof value === 'number' ? value : 0;
  return msToHM(numValue);
};
const moneyFormatter = (value: any) => {
  const numValue = typeof value === 'number' ? value : 0;
  return '$' + numValue.toFixed(0);
};

const tooltipFormater = (value: string | number | Array<string | number>, name: string) => {
  if (name === 'Cost based on $45 hourly') {
    const numValue = typeof value === 'number' ? value : 0;
    return <>{moneyFormatter(numValue)}</>;
  } else {
    const numValue = typeof value === 'number' ? value : 0;
    return <>{msToHM(numValue)}</>;
  }
};
