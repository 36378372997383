import { useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { CMContainer } from '../Components/Map/Messages';
import { LTP_WS, STREAMER_URL } from '../store/url';
import { LIVE_MESSAGES, LIVE_NEW_MESSAGES, LIVE_NEW_NOTIFICATIONS } from '../store/liveEvents';
import { CID, PID, USER_ID } from '../store/user';
import { useAuthedStream, useStreamMessages } from '../util/stream';
import { useServiceConfigCallback } from '../Authn/useServcieConfig';

// Use at the top of the "logged in" app tree; to manage live events lifecycle
export const LiveEventsProvider = ({ children }: { children: ReactNode }) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const streamerUrl = useAtomValue(STREAMER_URL);

  const [start] = useState(Math.floor(new Date().getTime()));

  const stream = useAuthedStream(
    `${streamerUrl}/${cid}/${pid}/live`,
    `${streamerUrl}/${cid}/${pid}/public/live/${start}`,
  );

  const messagesQueue = useRef<CMContainer[]>([]);

  const setMessages = useSetAtom(LIVE_MESSAGES);
  const setNewMessages = useSetAtom(LIVE_NEW_MESSAGES);
  const setNewNotifications = useSetAtom(LIVE_NEW_NOTIFICATIONS);

  const consumeEvent = (message: CMContainer) => messagesQueue.current.push(message);

  useStreamMessages<CMContainer>(stream, consumeEvent);

  setInterval(() => {
    const newMessages = messagesQueue.current.splice(0, 100);
    if (newMessages.length) {
      setMessages(m => [...m.slice(-100), ...newMessages]);
      setNewMessages(newMessages);
      setNewNotifications(newMessages);
    }
  }, 100);

  return <>{children}</>;
};
