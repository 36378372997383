import { ChevronLeft, ChevronRight, LocationOn } from '@mui/icons-material';
import { DateRangePicker } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { addWeeks, differenceInDays, subDays } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { CenteredProgress, Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AssetEntity, EntityType } from '../../util/enums';
import { normaliseErrorMessage } from '../../util/ErrorMessages';
import InputContainer from '../Global/InputContainer';
import { jsUcFirst } from '../Global/StringFormatterFunctions';
import {
  Asset,
  LastKnownLocation,
  OrderType,
  OrderTypeValue,
  PortableAssetTool,
  ToolOrderByTypeValue,
  ToolType,
} from '../Map/types';
import { OrderTypes, ToolOrderByTypes } from '../Map/values';

export const getAssetType = (ids: Record<string, string>): string => {
  for (const varName in ids) {
    switch (varName) {
      case 'gatewayId':
        return jsUcFirst(EntityType.Gateway);
      case 'gpsTrackerId':
        return jsUcFirst(EntityType.Tracker);
    }
  }
  return jsUcFirst(EntityType.Device);
};

export const timeFrameTooLargeError = (
  openDiaglog: boolean,
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
) => {
  return (
    <>
      <Dialog open={openDiaglog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          Your search request has timed out. Please try using a smaller timeframe.
        </DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const LastKnownLocationTable = ({
  asset,
  lastKnownLocation,
  range,
}: {
  asset: { id: string; label: string; type?: string; tool?: string } | undefined;
  lastKnownLocation: LastKnownLocation | undefined;
  range: [Date | null, Date | null];
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{
        width: '99.8%',
        marginTop: '20px',
      }}
    >
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {/* ID */}
          <TableRow key={'ID'}>
            <Tooltip title={'ID'}>
              <TableCell align="left" component="th" scope="row" style={{ width: '30%' }}>
                <Typography>ID</Typography>
              </TableCell>
            </Tooltip>
            <Tooltip title={asset?.type === AssetEntity.Device ? asset?.label : asset?.id}>
              <TableCell align="left" component="th" scope="row">
                <Typography>
                  {asset?.type === AssetEntity.Device ? asset?.label : asset?.id}
                </Typography>
              </TableCell>
            </Tooltip>
          </TableRow>

          {/* Label/Serial */}
          {asset?.type !== AssetEntity.Device && (
            <TableRow key={'Label'}>
              <Tooltip title={asset?.tool ? 'Label/Serial' : 'Label'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{asset?.tool ? 'Label/Serial' : 'Label'}</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={asset?.label}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{asset?.label}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Tool Type */}
          {!!asset?.tool && (
            <TableRow key={'ToolType'}>
              <Tooltip title={'Tool Type'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>Tool Type</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={asset?.tool}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{asset?.tool}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* No Location */}
          {!lastKnownLocation && (
            <TableRow key={'NoLocation'}>
              <Tooltip title={'Location'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>Location</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={`No location found between: ${new Date(range[0] ?? '')} and ${new Date(
                  range[1] ?? '',
                )}`}
              >
                <TableCell align="left" component="th" scope="row">
                  <Typography>{`No location found between: ${new Date(
                    range[0] ?? '',
                  ).toLocaleString()} and ${new Date(
                    range[1] ?? '',
                  ).toLocaleString()}`}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Location */}
          {!!lastKnownLocation && (
            <TableRow key={'Location'}>
              <Tooltip title={'Location'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>Location</Typography>
                </TableCell>
              </Tooltip>

              <Tooltip
                title={`${lastKnownLocation.coordinates?.latitude?.toFixed(
                  5,
                )}, ${lastKnownLocation.coordinates?.longitude?.toFixed(5)}`}
              >
                <TableCell align="left" component="th" scope="row">
                  <Typography>{`${lastKnownLocation.coordinates?.latitude?.toFixed(
                    5,
                  )}, ${lastKnownLocation.coordinates?.longitude?.toFixed(5)}`}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Time */}
          {!!lastKnownLocation && (
            <TableRow key={'Time'}>
              <Tooltip title={'At'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>At</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={new Date(String(lastKnownLocation.iso8601)).toLocaleString()}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>
                    {new Date(String(lastKnownLocation.iso8601)).toLocaleString()}
                  </Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}

          {/* Sensed By */}
          {!!lastKnownLocation?.sourceIds && (
            <TableRow key={'SensedBy'}>
              <Tooltip title={'Sensed By'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{`Sensed By ${
                    lastKnownLocation.sourceIds?.id
                      ? getAssetType(lastKnownLocation.sourceIds?.id)
                      : ''
                  }`}</Typography>
                </TableCell>
              </Tooltip>
              <Tooltip title={lastKnownLocation.sourceIds?.label ?? 'UNKNOWN'}>
                <TableCell align="left" component="th" scope="row">
                  <Typography>{lastKnownLocation.sourceIds?.label ?? 'UNKNOWN'}</Typography>
                </TableCell>
              </Tooltip>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const LastKnownLocationForAsset = ({
  asset,
  range,
  setRange,
  lastKnownLocation,
  setLastKnownLocation,
}: {
  asset: { id: string; label: string; type: string; tool?: string } | undefined;
  range: [Date | null, Date | null];
  setRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  lastKnownLocation: LastKnownLocation | undefined;
  setLastKnownLocation: Dispatch<SetStateAction<LastKnownLocation | undefined>>;
}) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeframeTooLarge, setTimeframeTooLarge] = useState<boolean>(false);

  const getLastKnownLocation = useCallback(async () => {
    setIsLoading(true);

    try {
      setIsLoading(false);
      let lastLocation = (
        await axios.post<LastKnownLocation>(
          `${persistorUrl}/${cid}/${pid}/analytics/last-known-coordinates`,
          {
            fromIso8601: new Date(range[0] ?? '')?.toISOString(),
            toIso8601: new Date(range[1] ?? '')?.toISOString(),
            id:
              asset?.type === AssetEntity.Device
                ? { deviceId: asset?.id }
                : asset?.type === AssetEntity.GPSTracker
                ? { gpsTrackerId: asset?.id }
                : { beaconId: asset?.id },
          },
          authedConfig,
        )
      ).data;

      if (lastLocation) {
        lastLocation = {
          ...lastLocation,
          ids: {
            ...lastLocation.ids,
            label: asset?.label,
          },
        };
      }
      setLastKnownLocation(lastLocation);
    } catch (error) {
      setIsLoading(false);
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Layer);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: '',
        message: errorMessage,
      });
      if (((error as AxiosError).response as AxiosResponse)?.data?.message?.statusCode === 408)
        setTimeframeTooLarge(true);
    }
  }, [
    cid,
    pid,
    persistorUrl,
    authedConfig,
    asset,
    setLastKnownLocation,
    setTimeframeTooLarge,
    setSaveNotification,
    range,
  ]);

  useEffect(() => {
    getLastKnownLocation();
  }, [getLastKnownLocation]);

  useEffect(() => {
    if (differenceInDays(new Date(range[1] ?? ''), new Date(range[0] ?? '')) > 7) {
      setRange([new Date(range[0] ?? ''), addWeeks(new Date(range[0] ?? ''), 1)]);
    }
  }, [range, setRange]);

  return (
    <>
      <div
        style={{
          alignSelf: 'start',
          margin: '25px 0px 20px 25px',
        }}
      >
        <Header icon={<LocationOn />}>Last Known Location</Header>
      </div>

      <Grid
        style={{
          alignSelf: 'center',
          margin: '20px',
          minWidth: '93%',
        }}
      >
        {/* Select Dates */}
        <DateRangePicker
          value={range}
          onChange={value => setRange(value)}
          disableFuture
          renderInput={(startProps, endProps) => (
            <Stack direction="row" spacing={2} style={{ width: '100%' }}>
              <TextField fullWidth {...startProps} label="Start date" />
              <TextField fullWidth {...endProps} label="End date" />
            </Stack>
          )}
        />

        <Grid
          container
          direction="column"
          style={{
            marginTop: '20px',
            display: 'grid',
            gap: '76%',
            gridTemplateColumns: '10% 10%',
          }}
        >
          <Tooltip title={'Previous Week'}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={isLoading}
              onClick={() => {
                setRange([addWeeks(new Date(range[0] ?? ''), -1), new Date(range[0] ?? '')]);
                getLastKnownLocation();
              }}
            >
              <ChevronLeft />
            </Button>
          </Tooltip>

          <Tooltip title={'Next Week'}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={isLoading}
              onClick={() => {
                setRange([new Date(range[1] ?? ''), addWeeks(new Date(range[1] ?? ''), 1)]);
                getLastKnownLocation();
              }}
            >
              <ChevronRight />
            </Button>
          </Tooltip>
        </Grid>

        {isLoading && (
          <Grid
            container
            justifyContent={'center'}
            style={{
              marginTop: '100px',
            }}
          >
            <CenteredProgress />
          </Grid>
        )}

        {!isLoading && (
          <LastKnownLocationTable
            asset={asset}
            lastKnownLocation={lastKnownLocation}
            range={range}
          />
        )}
      </Grid>

      {timeFrameTooLargeError(timeframeTooLarge, setTimeframeTooLarge)}
    </>
  );
};
