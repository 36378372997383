import {
  AppRegistration as EditMany,
  Build,
  Clear,
  Delete,
  Edit,
  FilterList,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { METADATA_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useMobile } from '../../util/useMobile';
import { truncationStyle } from '../Map/AssetRenderer';
import { PortableAssetTool, ToolType, ToolTypeFilter } from '../Map/types';
import { ToolsFilterDialog } from './ToolsFilterDialog';
import { ToolTypeFilterDialog } from './ToolTypeFilterDialog';

interface ToolsListProps {
  openToolTypeFilterDialog: boolean;
  setOpenToolTypeFilterDialog: Dispatch<SetStateAction<boolean>>;
  toolTypes: ToolType[];
  setToolTypes: Dispatch<SetStateAction<ToolType[]>>;
  editingTooltype: ToolType | undefined;
  setEditingTooltype: Dispatch<SetStateAction<ToolType | undefined>>;
  setNewTooltype: Dispatch<SetStateAction<string | undefined>>;
  setRefreshToolTypes: Dispatch<SetStateAction<boolean>>;
}

const ToolTypeList: FC<ToolsListProps> = ({
  openToolTypeFilterDialog,
  setOpenToolTypeFilterDialog,
  toolTypes,
  setToolTypes,
  editingTooltype,
  setEditingTooltype,
  setNewTooltype,
  setRefreshToolTypes,
}) => {
  const metadataUrl = useAtomValue(METADATA_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isMobile = useMobile();

  const [deleting, setDeleting] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toolTypeFilter, setToolTypeFilter] = useState<ToolTypeFilter | undefined>();

  const deleteToolType = async (id: string) => {
    try {
      await axios.delete(`${metadataUrl}/${cid}/${pid}/portableasset/tool/${id}`, authedConfig);
      setToolTypes(toolTypes.filter(t => t.id !== id));
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Delete',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
    setEditingTooltype(undefined);
    setDeleting(undefined);
  };

  return (
    <>
      <div
        style={{
          marginBottom: '24px',
          marginRight: '26px',
          marginLeft: '2.5%',
        }}
      >
        <Header icon={<Build />}>Active tool types</Header>
      </div>

      <Grid
        container
        direction="column"
        style={{
          marginLeft: '40px',
          marginBottom: '-30px',
          display: 'grid',
          gridTemplateColumns: '80px 80px 80px',
          overflowX: isMobile ? 'auto' : 'hidden',
          width: isMobile ? '300px' : '97%',
        }}
      >
        <Button
          onClick={() => {
            setOpenToolTypeFilterDialog(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Filter</span>
          <FilterList />
        </Button>

        <Button
          onClick={() => {
            setRefreshToolTypes(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Clear</span>
          <Clear />
        </Button>

        <Button
          style={{
            marginLeft: '-5px',
          }}
          disabled={!editingTooltype}
          onClick={() => setDeleting(editingTooltype?.id)}
        >
          <span style={{ fontSize: '10px' }}>Delete</span>
          <Delete />
        </Button>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          marginBottom: '15px',
          marginTop: '-26px',
          paddingRight: '40px',
          alignContent: 'end',
        }}
      >
        <Typography>{`${toolTypes.length} ${
          Number(toolTypes.length) === 1 ? 'result' : 'results'
        }`}</Typography>
      </Grid>

      <Grid
        id="tool-type-pagination-container"
        container
        justifyContent="left"
        style={{
          paddingTop: '5px',
          marginLeft: '2%',
          border: 'inset #2D3748',
          maxWidth: '96%',
          width: 'fit-content, 30%',
          height: `1000px`,
          overflowX: 'hidden',
          overflowY: 'auto',
          flexFlow: 'column',
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            width: '99.8%',
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                style={{
                  height: '55px',
                }}
              >
                <TableCell style={{ fontSize: '15px' }}></TableCell>
                <TableCell style={{ fontSize: '15px' }} align="left">
                  Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {toolTypes.map(toolType => (
                <TableRow
                  key={toolType.id}
                  sx={{
                    height: '55px',
                    fontSize: '15px',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left" sx={{ width: '1em' }}>
                    <Checkbox
                      checked={editingTooltype === toolType}
                      onChange={() => {
                        setEditingTooltype(editingTooltype === toolType ? undefined : toolType);
                      }}
                    />
                  </TableCell>
                  <Tooltip title={toolType.tool}>
                    <TableCell align="left" component="th" scope="row">
                      {toolType.tool}
                    </TableCell>
                  </Tooltip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Dialog open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>{`Are you sure you want to delete this Tool?`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              if (deleting) {
                const deletable = toolTypes.find(d => d.id === deleting);
                if (!deletable) return;
                deleteToolType(deletable.id);
              }
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openToolTypeFilterDialog}
        onClose={() => setOpenToolTypeFilterDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70%',
            maxHeight: '40%',
            width: '650px',
            height: '80%',
          },
        }}
      >
        <ToolTypeFilterDialog
          setOpenToolTypeFilterDialog={setOpenToolTypeFilterDialog}
          toolTypes={toolTypes}
          setToolTypes={setToolTypes}
          setRefreshToolTypes={setRefreshToolTypes}
          toolTypeFilter={toolTypeFilter}
          setToolTypeFilter={setToolTypeFilter}
        ></ToolTypeFilterDialog>
      </Dialog>
    </>
  );
};
export default ToolTypeList;
