import { Key, Password } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { PasskeyInfo } from '../../Authn/PasskeyInfo';
import { usePasskeyCount } from '../../Authn/usePasskeyCount';
import { usePasswordLastChanged } from '../../Authn/usePasswordLastChanged';
import { useRequestRecoveryEmail } from '../../Authn/useRequestRecoveryEmail';
import { CID_PID_PAIRS, ME, SUPER_ADMIN } from '../../store/user';

export const UserAccount = () => {
  const user = useAtomValue(ME);
  const isSuperAdmin = useAtomValue(SUPER_ADMIN);
  const clientProjects = useAtomValue(CID_PID_PAIRS);
  const {
    execute: requestRecovery,
    loading: recoveryLoading,
    data: recoverySent,
    error: recoveryError,
  } = useRequestRecoveryEmail(user.label);
  const { execute: getPasskeyCount, data: passkeyCount } = usePasskeyCount();
  const { execute: getPasswordLastChanged, data: passwordLastChanged } = usePasswordLastChanged();

  useEffect(() => {
    getPasskeyCount();
  }, [getPasskeyCount]);

  useEffect(() => {
    getPasswordLastChanged();
  }, [getPasswordLastChanged]);

  return (
    <Container component="div" maxWidth="md">
      <Stack p={2} spacing={4} marginTop={2}>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">User account</Typography>
            <Typography>
              {user.label}
              {isSuperAdmin && <Typography variant="caption">{' (STAFF)'}</Typography>}
            </Typography>
          </Stack>
        </Paper>

        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Projects</Typography>
            {clientProjects.map(({ clientId, projectId }) => (
              <Typography
                key={`${clientId}/${projectId}`}
              >{`${clientId} / ${projectId}`}</Typography>
            ))}
          </Stack>
        </Paper>

        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Sign-in methods</Typography>
            <List dense={false}>
              <ListItem>
                <ListItemIcon>
                  <Key color={(passkeyCount || 0) !== 0 ? 'success' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  primary={`Passkey`}
                  secondary={
                    (passkeyCount || 0) === 0
                      ? 'None'
                      : passkeyCount === 1
                      ? '1 passkey'
                      : `${passkeyCount} passkeys`
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Password color={passwordLastChanged !== undefined ? 'success' : 'disabled'} />
                </ListItemIcon>
                <ListItemText
                  primary={`Password`}
                  secondary={
                    passwordLastChanged === undefined
                      ? 'Not set'
                      : 'Last changed ' + new Date(passwordLastChanged).toLocaleDateString()
                  }
                />
              </ListItem>
            </List>
            <PasskeyInfo />
            <Typography>
              To create a passkey or reset your password, request account recovery.
            </Typography>
            <Stack direction="row">
              <LoadingButton
                variant="contained"
                fullWidth={false}
                loading={recoveryLoading}
                size="large"
                color="secondary"
                onClick={requestRecovery}
                disabled={recoverySent}
              >
                {recoverySent ? 'Submitted' : 'Request account recovery'}
              </LoadingButton>
            </Stack>
            {recoveryError && (
              <Typography variant="caption" color="red">
                {(recoveryError as Error).message}
              </Typography>
            )}
            {recoverySent && <Typography>Check your email for instructions.</Typography>}
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};
