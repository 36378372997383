import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleCalendar {
  className?: string;
}

const IconSingleCalendar: React.FC<IconSingleCalendar> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 356 356" className={className}>
      <g clipPath="url(#clip0)">
        <path d="M192.16 159.923H164.426V187.735H192.16V159.923Z" fill="#F2F2F2" />
        <path
          d="M314.191 27.8125H297.551V0H269.816V27.8125H86.7695V0H59.0352V27.8125H42.3945C19.4554 27.8125 0.792969 46.5275 0.792969 69.5312V314.281C0.792969 337.285 19.4554 356 42.3945 356H134.611H136.691H185.062C175.474 348.064 167.123 338.679 160.334 328.188H136.691H134.611H42.3945C34.7482 328.188 28.5273 321.949 28.5273 314.281V130.719H328.059V158.531V159.922V159.991C338.52 166.799 347.879 175.174 355.793 184.788V159.922V158.531V69.5312C355.793 46.5275 337.131 27.8125 314.191 27.8125ZM328.059 102.906H28.5273V69.5312C28.5273 61.8633 34.7482 55.625 42.3945 55.625H59.0352V83.4375H86.7695V55.625H269.816V83.4375H297.551V55.625H314.191C321.838 55.625 328.059 61.8633 328.059 69.5312V102.906Z"
          fill="#F2F2F2"
        />
        <path
          d="M262.191 168.265C210.578 168.265 168.588 210.373 168.588 262.132C168.588 313.89 210.578 355.999 262.191 355.999C313.804 355.999 355.795 313.89 355.795 262.132C355.795 210.373 313.804 168.265 262.191 168.265ZM262.191 328.186C225.871 328.186 196.322 298.554 196.322 262.132C196.322 225.709 225.871 196.077 262.191 196.077C298.512 196.077 328.061 225.709 328.061 262.132C328.061 298.554 298.512 328.186 262.191 328.186Z"
          fill="#F2F2F2"
        />
        <path d="M275.364 215.548H247.63V276.04H298.938V248.228H275.364V215.548Z" fill="#F2F2F2" />
        <path d="M136.695 215.548H108.96V243.36H136.695V215.548Z" fill="#F2F2F2" />
        <path d="M81.2222 215.548H53.4878V243.36H81.2222V215.548Z" fill="#F2F2F2" />
        <path d="M81.2222 159.923H53.4878V187.735H81.2222V159.923Z" fill="#F2F2F2" />
        <path d="M81.2222 271.172H53.4878V298.985H81.2222V271.172Z" fill="#F2F2F2" />
        <path d="M136.695 159.923H108.96V187.735H136.695V159.923Z" fill="#F2F2F2" />
        <path d="M136.695 271.172H108.96V298.985H136.695V271.172Z" fill="#F2F2F2" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="355" height="356" fill="white" transform="translate(0.792969)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default IconSingleCalendar;
