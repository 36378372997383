import { useSetAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TIMED_OUT } from '../store/auth';
import { useLogout } from './useLogout';

export const useTimedOut = () => {
  const logout = useLogout();
  const setTimedOut = useSetAtom(TIMED_OUT);
  const hasTimeoutOut = useAtomValue(TIMED_OUT);
  const navigate = useNavigate();

  // Temporary fix. We shouldn't need to do a hard redirct and then a logout. LTP-958
  useEffect(() => {
    if (hasTimeoutOut) navigate('/sign-in');
  }, [navigate, hasTimeoutOut]);

  return useCallback(() => {
    setTimedOut(true);
    logout();
  }, [logout, setTimedOut]);
};
