/* eslint-disable react/no-children-prop */
import { Toolbar } from './Toolbar';
import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';

export const PenToolbar = ({
  children,
  marginLeft,
}: {
  children: ReactNode[];
  marginLeft?: string;
}) => {
  return (
    <Grid
      sx={{
        position: 'relative',
        top: '20px',
        '& .MuiBox-root': {
          overflow: 'auto',
          height: window.outerWidth < 915 ? '64vh' : 'auto',
          maxHeight: 'max-content',
          borderRadius: '0px',
        },
        marginLeft: marginLeft ?? '0px',
      }}
    >
      <Toolbar children={children} /*top={'40%'}*/ left={8} />
    </Grid>
  );
};
