export function autoId(): string {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function jsUcFirst(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function noUnderscore(string: string): string {
  return jsUcFirst(string.split('_').join(' '));
}

export default {
  autoId,
  jsUcFirst,
  noUnderscore,
};
