/* eslint-disable @typescript-eslint/no-empty-interface */
import { SvgIcon } from '@mui/material';
import React from 'react';

interface IconEntryProps {}

const IconEntry: React.FC<IconEntryProps> = () => {
  return (
    <SvgIcon viewBox="0 0 350 364">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.059 91C129.059 101.052 120.816 109.2 110.646 109.2C100.477 109.2 92.2333 101.052 92.2333 91V72.8C92.2333 32.5937 125.209 -1.52588e-05 165.886 -1.52588e-05H276.348C317.025 -1.52588e-05 350 32.5937 350 72.8V291.2C350 331.406 317.025 364 276.348 364H165.758C125.081 364 92.1055 331.406 92.1055 291.2V273C92.1055 262.948 100.349 254.8 110.519 254.8C120.688 254.8 128.932 262.948 128.932 273V291.2C128.932 311.303 145.419 327.6 165.758 327.6H276.348C296.686 327.6 313.174 311.303 313.174 291.2V72.8C313.174 52.6968 296.686 36.4 276.348 36.4H165.886C145.547 36.4 129.059 52.6968 129.059 72.8L129.059 91Z"
        fill="#F2F2F2"
      />
      <path
        d="M202.632 200.2C212.805 200.2 221.053 192.051 221.053 182C221.053 171.948 212.805 163.8 202.632 163.8H18.4211C8.24738 163.8 0 171.948 0 182C0 192.051 8.24738 200.2 18.4211 200.2H202.632Z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.185 140.269C163.991 133.162 163.991 121.638 171.185 114.53C178.379 107.423 190.042 107.423 197.236 114.53L252.499 169.13C259.693 176.238 259.693 187.762 252.499 194.869L197.236 249.469C190.042 256.577 178.379 256.577 171.185 249.469C163.991 242.362 163.991 230.838 171.185 223.73L213.422 182L171.185 140.269Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconEntry;
