/* eslint-disable @typescript-eslint/no-empty-interface */
import { SvgIcon } from '@mui/material';
import React from 'react';

interface IconExitProps {}

const IconExit: React.FC<IconExitProps> = () => {
  return (
    <SvgIcon viewBox="0 0 366 365">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.515 91.4495C219.515 101.507 227.682 109.659 237.757 109.659C247.832 109.659 255.999 101.507 255.999 91.4495V73.2395C255.999 33.0111 223.33 0.399597 183.031 0.399597H73.5943C33.295 0.399597 0.626003 33.0111 0.626003 73.2395L0.626003 291.759C0.626003 331.988 33.295 364.599 73.5943 364.599H183.158C223.457 364.599 256.126 331.988 256.126 291.759V273.549C256.126 263.492 247.959 255.339 237.884 255.339C227.809 255.339 219.642 263.492 219.642 273.549V291.759C219.642 311.873 203.307 328.179 183.158 328.179H73.5943C53.4447 328.179 37.1102 311.873 37.1102 291.759L37.1102 73.2395C37.1102 53.1253 53.4447 36.8195 73.5943 36.8195H183.031C203.181 36.8195 219.515 53.1253 219.515 73.2395L219.515 91.4495Z"
        fill="#F2F2F2"
      />
      <path
        d="M310.75 200.71C320.829 200.71 329 192.557 329 182.5C329 172.443 320.829 164.29 310.75 164.29H128.25C118.17 164.29 110 172.443 110 182.5C110 192.557 118.17 200.71 128.25 200.71H310.75Z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.595 140.747C272.468 133.635 272.468 122.105 279.595 114.994C286.722 107.882 298.277 107.882 305.404 114.994L360.154 169.624C367.281 176.735 367.281 188.265 360.154 195.376L305.404 250.006C298.277 257.118 286.722 257.118 279.595 250.006C272.468 242.895 272.468 231.365 279.595 224.254L321.44 182.5L279.595 140.747Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconExit;
