import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleString {
  className?: string;
}

const IconSingleString: React.FC<IconSingleString> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 320 356" className={className}>
      <path
        d="M128.731 266.25H88.7932V17.7489C88.7932 7.93355 80.8564 0 71.0411 0C61.2257 0 53.2921 7.93355 53.2921 17.7489V266.25H13.3546C8.06343 266.25 3.27275 269.393 1.14392 274.239C-0.988156 279.085 -0.0098692 284.746 3.59127 288.634L61.2809 350.756C63.8193 353.457 67.3327 355.001 71.0411 355.001C74.7527 355.001 78.2661 353.457 80.8044 350.756L138.491 288.634C142.095 284.746 143.054 279.101 140.941 274.239C138.829 269.373 134.019 266.25 128.731 266.25Z"
        fill="#F2F2F2"
      />
      <path
        d="M318.225 220.949C316.029 216.3 311.355 213.333 306.221 213.333H211.945C204.612 213.333 198.667 219.289 198.667 226.635V239.939C198.667 247.285 204.612 253.24 211.945 253.24C219.279 253.24 225.224 247.285 225.224 239.939L278.145 239.937L201.682 333.259C198.425 337.235 197.747 342.736 199.942 347.385C202.137 352.035 206.812 355 211.945 355H304.23C311.563 355 317.508 349.045 317.508 341.698V328.396C317.508 321.05 311.563 315.094 304.23 315.094C296.896 315.094 290.951 321.05 290.951 328.396H240.023L316.485 235.075C319.743 231.099 320.421 225.598 318.225 220.949Z"
        fill="#F2F2F2"
      />
      <path
        d="M259.084 0C225.77 0 198.667 27.1505 198.667 60.5244V128.365C198.667 135.711 204.612 141.667 211.945 141.667C219.279 141.667 225.224 135.711 225.224 128.365V101.096H292.944V128.365C292.944 135.711 298.889 141.667 306.222 141.667C313.555 141.667 319.5 135.711 319.5 128.365V60.5244C319.501 27.1505 292.398 0 259.084 0ZM292.944 74.4915H225.224V60.5244C225.224 41.8206 240.413 26.604 259.084 26.604C277.754 26.604 292.944 41.8199 292.944 60.5244V74.4915Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleString;
