/* eslint-disable react/jsx-key */
import React, { Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { Fab, Grid, Tooltip, Typography } from '@mui/material';
import { some, none, isSome, toNullable, Option } from 'fp-ts/es6/Option';
import {
  DrawType,
  EditType,
  GeofenceTypes,
  MeasurementType,
  MicrofenceTypes,
} from '../MapDefaults';

type Tools = [JSX.Element, MeasurementType | undefined, string][];

export const MEASUREMENT_TOOLS: Tools = [
  [<b>m</b>, 'M', `Metres`],
  [<b>ft</b>, 'FT', `Feet`],
  [<b>km</b>, 'KM', `Kilometres`],
  [<b>mi</b>, 'MI', `Miles`],
  [
    <b>
      m<sup>2</sup>
    </b>,
    'M2',
    `Square Metres`,
  ],
  [
    <b>
      ft<sup>2</sup>
    </b>,
    'FT2',
    `Square Feet`,
  ],
  [
    <b>
      km<sup>2</sup>
    </b>,
    'KM2',
    `Square Kilometres`,
  ],
  [
    <b>
      mi<sup>2</sup>
    </b>,
    'MI2',
    `Square Miles`,
  ],
];

export const createPenSubTools = (props: {
  mapType: 'INDOOR' | 'OUTDOOR';
  selectedLayer: Option<string>;
  geofenceType: GeofenceTypes | MicrofenceTypes | undefined;
  geofenceTooBig: boolean;
  editing: boolean;
  isLoading: boolean;
  setEditing: () => void;
  unsetEditing: () => void;
  drawType: Option<DrawType>;
  setDrawType: Dispatch<SetStateAction<Option<DrawType>>>;
  editType: Option<EditType>;
  setEditType: Dispatch<SetStateAction<Option<EditType>>>;
  measurementType: MeasurementType | undefined;
  setMeasurementType: Dispatch<SetStateAction<MeasurementType | undefined>>;
}): ReactNode[] => {
  return [
    <Typography variant="caption" key="units">
      Units
    </Typography>,
    ...MEASUREMENT_TOOLS.map(([label, measurementType, tooltip], index) => {
      return (
        <Grid item key={'MEASURE_' + index}>
          <Tooltip title={tooltip} arrow>
            <Fab
              color={
                measurementType && measurementType === props.measurementType
                  ? 'secondary'
                  : undefined
              }
              size="small"
              onClick={() => {
                props.setMeasurementType(measurementType);
              }}
            >
              {label}
            </Fab>
          </Tooltip>
        </Grid>
      );
    }),
  ];
};
