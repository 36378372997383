import { GridRowData } from '@material-ui/data-grid';
import { Delete, Edit, ChevronLeft, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../../../../store/auth';
import { AUTHN_URL } from '../../../../../../store/url';
import { CID, PID } from '../../../../../../store/user';
import { GeomobyPropertiesValues } from '../../../../../../util/enums';
import InputContainer from '../../../../../Global/InputContainer';
import { lineLimitStyle } from '../../../../AssetRenderer';
import { Device, GeomobyOverride } from '../../../../types';
import { FRESH_UNKNOWN_LAYER } from '../../../GeofenceEditor';

export const OverridesTable = ({ rows }: { rows: GeomobyOverride[] }) => {
  const [selectedOverride, setSelectedOverride] = useState<GeomobyOverride | undefined>();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '15px' }}>Property</TableCell>
            <TableCell style={{ fontSize: '15px' }} align="left">
              Value
            </TableCell>
            <TableCell style={{ fontSize: '15px' }} align="right">
              Devices
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell>No properties found</TableCell>
            </TableRow>
          )}

          {[...new Map(rows.map(row => [row['property'] && row['value'], row])).values()]
            .sort((a, b) => a.property.localeCompare(b.property))
            .map((row, index) => (
              <Fragment key={`${row.property}-${row.value}-${index}`}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <Tooltip title={row.property}>
                    <TableCell>{row.property}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.value}>
                    <TableCell
                      align="left"
                      style={{ ...lineLimitStyle, overflowWrap: 'anywhere', borderBottom: 'unset' }}
                    >
                      {row.value}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="right" style={{ borderBottom: 'unset' }}>
                    {row.deviceIds.length}
                    <>
                      <IconButton
                        style={{
                          height: '27px',
                          width: '27px',
                        }}
                        onClick={() => {
                          setSelectedOverride(
                            row.property === selectedOverride?.property &&
                              row.value === selectedOverride?.value
                              ? undefined
                              : row,
                          );
                        }}
                        onBlur={() => {
                          if (
                            row.property === selectedOverride?.property &&
                            row.value === selectedOverride?.value
                          )
                            setSelectedOverride(undefined);
                        }}
                      >
                        {row.property === selectedOverride?.property &&
                        row.value === selectedOverride?.value ? (
                          <ExpandMore />
                        ) : (
                          <ChevronLeft />
                        )}
                      </IconButton>
                    </>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse
                      in={
                        row.property === selectedOverride?.property &&
                        row.value === selectedOverride?.value
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box>
                        <Table size="small" aria-label="devices">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ paddingLeft: '10px' }}>Device</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.deviceIds
                              .sort((a, b) => a.localeCompare(b))
                              .map(device => (
                                <TableRow key={`${row.property}-${row.value}-${device}`}>
                                  <TableCell component="th" scope="row">
                                    {device}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const GeomobyOverrides = ({
  selectedGeofence,
  displayGeomobyOverrides,
  setDisplayGeomobyOverrides,
  properties,
  saveAllChanges,
  updateGeomobyOverrides,
}: {
  selectedGeofence: GridRowData | undefined;
  displayGeomobyOverrides: (GeomobyOverride & { index: number })[];
  setDisplayGeomobyOverrides: Dispatch<SetStateAction<(GeomobyOverride & { index: number })[]>>;
  properties: { label: string; id: string; isCustom: boolean }[];
  saveAllChanges: () => Promise<void>;
  updateGeomobyOverrides: (
    geomobyOverrides: GeomobyOverride[],
  ) => Promise<GeomobyOverride[] | undefined>;
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const theme = useTheme();
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const [availableDevices, setAvailableDevices] = useState<{ id: string; label: string }[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const getDevices = useCallback(async () => {
    const { devices, count } = (
      await axios.get<{ devices: Device[]; count: number }>(
        `${httpBaseUrl}/open/device/paginate/${cid}/${pid}/1?perPage=500`,
        authedConfig,
      )
    ).data;

    setAvailableDevices(
      devices.map(device => {
        return {
          id: device.label,
          label: device.label,
        };
      }),
    );
  }, [authedConfig, httpBaseUrl, cid, pid]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const onChangeFields = (updatedOverride: GeomobyOverride & { index: number }) => {
    return [
      ...displayGeomobyOverrides.filter(displayed => displayed.index !== updatedOverride.index),
      updatedOverride,
    ].sort((a, b) => {
      return String(a.index).localeCompare(String(b.index));
    });
  };

  return (
    <>
      <Grid
        style={{
          marginTop: '30px',
        }}
      >
        <Tooltip title={'Override Rules'}>
          <Typography style={{ color: '#4CB8C4' }}>Override Rules</Typography>
        </Tooltip>

        <Grid
          container
          style={{
            display: 'grid',
            gap: '2%',
            gridTemplateColumns: '49% 49%',
          }}
        >
          <Tooltip title={'Property'}>
            <Typography>Property</Typography>
          </Tooltip>
          <Tooltip title={'Value'}>
            <Typography>Value</Typography>
          </Tooltip>
        </Grid>

        {displayGeomobyOverrides
          .sort((a, b) => {
            return String(a.index).localeCompare(String(b.index));
          })
          .map(override => {
            return (
              <Grid container key={override.index}>
                <Grid
                  item
                  container
                  key={override.index}
                  sx={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '45% 45% 5%',
                    '& .MuiInputBase-root': {
                      height: '35px',
                    },
                  }}
                >
                  {/* Property */}
                  <FormControl
                    sx={{
                      marginTop: '8px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'inherit',
                      },
                    }}
                  >
                    <Select
                      fullWidth
                      id={'override-name-' + override.index}
                      key={'override-' + override.index}
                      name={'override' + override.index}
                      value={override.property}
                      onChange={async (e: { target: { value: string } }) => {
                        const updatedDisplayGeomobyOverrides = onChangeFields({
                          ...override,
                          property: e.target.value,
                        });
                        await updateGeomobyOverrides(
                          updatedDisplayGeomobyOverrides.map(override => {
                            return {
                              index: override.index,
                              property: override.property,
                              value: override.value,
                              deviceIds: override.deviceIds,
                            };
                          }),
                        );
                        setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                      }}
                    >
                      {(properties.find(p => p.label === override.property)
                        ? properties
                        : [{ id: -1, label: override.property }, ...properties].sort((a, b) =>
                            a.label.localeCompare(b.label),
                          )
                      ).map(propName => (
                        <MenuItem
                          key={propName.id}
                          value={propName.label}
                          disabled={!properties.find(p => p.label === propName.label)}
                        >
                          <Tooltip title={propName.label}>
                            <Typography
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 50px)',
                              }}
                            >
                              {propName.label}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Value */}
                  <FormControl>
                    <InputContainer
                      id={'override-value-' + override.index}
                      label=""
                      key={'override-value-' + override.index}
                      name={'override-value-' + override.index}
                      value={override.value}
                      onChange={async (e: { target: { value: string } }) => {
                        const updatedDisplayGeomobyOverrides = onChangeFields({
                          ...override,
                          value: e.target.value,
                        });
                        setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                      }}
                      onBlur={async (e: { target: { value: string } }) => {
                        const updatedDisplayGeomobyOverrides = onChangeFields({
                          ...override,
                          value: e.target.value,
                        });
                        setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                        await updateGeomobyOverrides(
                          updatedDisplayGeomobyOverrides.map(override => {
                            return {
                              index: override.index,
                              property: override.property,
                              value: override.value,
                              deviceIds: override.deviceIds,
                            };
                          }),
                        );
                      }}
                      onKeyPress={(event: { key: string }) => {
                        if (event.key === 'Enter') {
                          saveAllChanges();
                        }
                      }}
                      placeholder=""
                    />
                  </FormControl>

                  {/* Delete */}
                  <IconButton
                    color="primary"
                    onClick={async () => {
                      const updatedDisplayGeomobyOverrides = displayGeomobyOverrides.filter(
                        displayed => displayed.index !== override.index,
                      );
                      await updateGeomobyOverrides(
                        updatedDisplayGeomobyOverrides.map(override => {
                          return {
                            index: override.index,
                            property: override.property,
                            value: override.value,
                            deviceIds: override.deviceIds,
                          };
                        }),
                      );
                      setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>

                {/* Device IDs */}
                <Grid
                  item
                  container
                  key={'override-deviceIds-' + override.index}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '92% 7%',
                    marginTop: '15px',
                    marginBottom: '10px',
                  }}
                >
                  <FormControl>
                    <InputLabel id="select-devices-label">Devices</InputLabel>
                    <Select
                      labelId="select-devices-label"
                      id="select-devices"
                      multiple
                      value={override.deviceIds}
                      onChange={async (event: SelectChangeEvent<typeof selectedDevices>) => {
                        const {
                          target: { value },
                        } = event;
                        override.deviceIds = typeof value === 'string' ? value.split(',') : value;

                        const updatedDisplayGeomobyOverrides = [
                          ...displayGeomobyOverrides.filter(
                            displayed => displayed.index !== override.index,
                          ),
                          {
                            ...override,
                            deviceIds: Array.from(event.target.value),
                          },
                        ].sort((a, b) => {
                          return String(a.index).localeCompare(String(b.index));
                        });
                        await updateGeomobyOverrides(
                          updatedDisplayGeomobyOverrides.map(o => {
                            return {
                              index: o.index,
                              property: o.property,
                              value: o.value,
                              deviceIds: o.deviceIds,
                            };
                          }),
                        );
                        setDisplayGeomobyOverrides(updatedDisplayGeomobyOverrides);
                      }}
                      input={<OutlinedInput id="select-device" label="Device" />}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {override.deviceIds.map((id, index) => (
                            <Chip key={`${index}-${id}`} label={id} />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      }}
                    >
                      {availableDevices?.map((device, index) => (
                        <MenuItem
                          key={`${override.index}-${device.id}-${index}`}
                          value={device.id}
                          style={{
                            fontWeight: override.deviceIds.find(id => id === device.id)
                              ? theme.typography.fontWeightMedium
                              : theme.typography.fontWeightRegular,
                          }}
                        >
                          <Typography
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: 'calc(100% - 50px)',
                            }}
                          >
                            {device.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}

        <Button
          variant="outlined"
          style={{
            width: '92%',
          }}
          disabled={
            selectedGeofence?.layerId === FRESH_UNKNOWN_LAYER ||
            selectedGeofence?.previousLayer === FRESH_UNKNOWN_LAYER
          }
          onClick={() => {
            if (displayGeomobyOverrides.find(p => p.property === '')) return;
            setDisplayGeomobyOverrides(
              onChangeFields({
                index: displayGeomobyOverrides.length,
                property: '',
                value: '',
                deviceIds: [],
              }),
            );
          }}
        >
          Add
        </Button>
      </Grid>
    </>
  );
};
