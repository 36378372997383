import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { USER_ID } from '../store/user';

export const usePasswordLastChanged = () => {
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const userId = useAtomValue(USER_ID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const lastChanged = useCallback(async () => {
    const { data } = await axios.get<
      { id: string; username: string; userId: string; createdAt: string; updatedAt?: string }[]
    >(`${httpBaseUrl}/open/user/${userId}/basic-auth`, authedConfig);

    const basicAuth = data.at(0);

    return basicAuth?.updatedAt ?? basicAuth?.createdAt;
  }, [httpBaseUrl, userId, authedConfig]);

  return useAsyncTask<string | undefined, typeof lastChanged>(lastChanged);
};
