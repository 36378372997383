import axios, { AxiosResponse } from 'axios';
import { useAtomValue } from 'jotai';
import { ReactNode, useCallback, useEffect } from 'react';
import { useLogout } from '../Authn/useLogout';
import { useRefresh } from '../Authn/useRefresh';
import { ACCESS_JWT_TOKEN, AUTH_NULLABLE, REFRESH_JWT_TOKEN } from '../store/auth';

const isUnauthedError = (errorResponse: AxiosResponse | Error) =>
  'message' in errorResponse && errorResponse.message.includes('401');
const getConfigOrUndefined = (errorResponse: AxiosResponse | Error) =>
  'config' in errorResponse ? errorResponse.config : undefined;

const jsonParseOrUndefined = (stringified: string) => {
  try {
    return JSON.parse(stringified);
  } catch {
    return undefined;
  }
};

export const ExpireSessionOn401 = ({ children }: { children: ReactNode }) => {
  const logout = useLogout();
  const refresh = useRefresh();
  const refreshToken = useAtomValue(REFRESH_JWT_TOKEN);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      successResponse => successResponse,
      async (errorResponse: AxiosResponse | Error) => {
        if (isUnauthedError(errorResponse)) {
          const refreshResponse = await refresh(refreshToken);
          const config = getConfigOrUndefined(errorResponse);
          if (!config?.headers?.['X-is-retry'] && config) {
            const headers = {
              Authorization: `Bearer ${refreshResponse.accessJwt}`,
              'X-is-retry': '1',
            };
            const data = jsonParseOrUndefined(config.data) ?? config.data;
            return axios({ ...config, data, headers });
          } else {
            logout();
            return Promise.reject(errorResponse);
          }
        }
        return Promise.reject(errorResponse);
      },
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout, refresh, refreshToken]);

  return <>{children}</>;
};
