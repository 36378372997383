/* eslint-disable @typescript-eslint/no-empty-interface */
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

interface IconLocationProps {}

const IconLocation: React.FC<IconLocationProps> = () => {
  return (
    <SvgIcon viewBox="0 0 120 175">
      <path
        d="M59.9997 0C26.9158 0 0 28.0609 0 62.5524C0 76.7141 9.53353 99.1835 28.3363 129.337C42.0739 151.367 56.0071 169.747 56.1465 169.93L60 175L63.8535 169.93C63.9929 169.747 77.9261 151.367 91.6637 129.337C110.466 99.1838 120 76.7141 120 62.5524C120 28.0609 93.0836 0 59.9997 0ZM59.9997 97.7278C40.6618 97.7278 24.9293 81.3259 24.9293 61.1654C24.9293 41.0048 40.6618 24.6029 59.9997 24.6029C79.3375 24.6029 95.07 41.0048 95.07 61.1654C95.0703 81.3259 79.3379 97.7278 59.9997 97.7278Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconLocation;
