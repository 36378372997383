/*
  TODO: LTP-1065 When cleaning up the tech debt, we should move all our enums to this file.
  Also, we should move the type.ts and values.ts from Components/Map into Utils.
  Also, we should move any other types/values into thos files (and order everything phonetically).
  Also, we should probably think about sticking text like 'Geofence name must be unique.' in these files too.
*/

export enum AssetEntity {
  Beacon = 'beacon',
  Device = 'device',
  GPSTracker = 'gpstracker',
  Tool = 'tool',
}

export enum EntityType {
  Asset = 'ASSET',
  Beacon = 'BEACON',
  Device = 'DEVICE',
  Gateway = 'GATEWAY',
  Geofence = 'GEOFENCE',
  Layer = 'LAYER',
  Line = 'LINE',
  Microfence = 'MICROFENCE',
  Multipolygon = 'MULTIPOLYGON',
  Polygon = 'POLYGON',
  Tool = 'TOOL',
  Tracker = 'TRACKER',
}

export enum FenceGeometryType {
  Line = 'line',
  Microfence = 'microfence',
  Multipolygon = 'multipolygon',
  Polygon = 'polygon',
}

export enum MicrofenceEntity {
  Beacon = 'beacon',
  Device = 'device',
  Gateway = 'gateway',
  Smartplug = 'smartplug',
}

export enum GeomobyPropertiesValues {
  alertStyle = 'alertStyle',
  alertText = 'alertText',
  alertTimeout = 'alertTimeout',
  dwellSeconds = 'dwellSeconds',
  showAlert = 'showAlert',
}
