import {
  ArrowBack,
  ArrowForward,
  Battery20,
  Battery50,
  Battery80,
  CheckBoxOutlineBlank,
  DashboardOutlined,
  DirectionsRun,
  Edit,
  HistoryToggleOff,
  PhoneForwarded,
  Sensors,
  SensorsOff,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { keyBy } from 'lodash';
import React, {
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { NOTIFIER_URL, TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { truncationStyle } from '../Map/AssetRenderer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { MICROFENCE_LAYER_ID } from '../Map/BeaconUtils';
import { GridRowData } from '@material-ui/data-grid';
import IconEntry from '../../Style/icons/IconEntry';
import IconExit from '../../Style/icons/IconExit';
import Crossing from '../../Style/icons/Crossing';
import IconSingleTemperatureBelow from '../../Style/icons/IconSingleTemperatureBelow';
import IconSingleTemperatureAbove from '../../Style/icons/IconSingleTemperatureAbove';
import IconSingleOxygenBelow from '../../Style/icons/IconSingleOxygenBelow';
import IconSingleWebhookGet from '../../Style/icons/IconSingleWebhookGet';
import { ActionTypes, Crossings, TriggerTypes } from './values';
import {
  ActionType,
  ActionTypeId,
  ActionTypeValue,
  CrossingTypeId,
  EBT,
  EmailType,
  EventIds,
  Method,
  NotificationType,
  TriggerOption,
  TriggerSubType,
  TriggerSubTypeId,
  TriggerSubTypeValue,
  TriggerType,
  TriggerTypeId,
  TriggerTypeOptionsId,
  TriggerTypeOptionsValue,
  TriggerTypeValue,
  WebhookType,
} from './types';
import InputContainer from '../Global/InputContainer';
import { DataGrid, GridInputRowSelectionModel } from '@mui/x-data-grid';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { initialExtentInDegrees } from '../Map/MapDefaults';
import EventsList from './EventsList';
import IconSingleBeacon from '../../Style/icons/IconSingleBeacon';
import IconLocation from '../../Style/icons/IconLocation';
import IconSingleCalendar from '../../Style/icons/IconSingleCalendar';
import IconSingleOxygen from '../../Style/icons/IconSingleOxygen';
import IconSingleTemperature from '../../Style/icons/IconSingleTemperature';
import IconSingleGlobe from '../../Style/icons/IconSingleGlobe';
import IconSingleCustom from '../../Style/icons/IconSingleCustom';
import IconLeaf from '../../Style/icons/IconLeaf';
import IconSingleOxygenAbove from '../../Style/icons/IconSingleOxygenAbove';
import IconSingleString from '../../Style/icons/IconSingleString';
import IconSingleNumber from '../../Style/icons/IconSingleNumber';
import IconSingleWebhookPost from '../../Style/icons/IconSingleWebhookPost';
import IconSingleNotif from '../../Style/icons/IconSingleNotif';
import IconSingleEmail from '../../Style/icons/IconSingleEmail';
import IconExtensometer from '../../Style/icons/IconExtensometer';
import IconInactivity from '../../Style/icons/IconInactivity';
import IconManDown from '../../Style/icons/IconManDown';
import { GeofenceSearchToolbar } from '../Map/GeofenceSearchToolbar';
import { GeofenceTypes } from '../Map/MapDefaults';
import { getVectorLayers } from '../Map/GeofenceEditor/GeofenceEditorFunctions';

export type Geofence = {
  id: string;
  layerId: string;
  name: string;
  type: string;
  geomobyProperties?: Record<string, string>;
};

const DISPLAY_LIMIT = 5;

const Events = () => {
  const notifierUrl = useAtomValue(NOTIFIER_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const [availableFeaturesCount, setAvailableFeaturesCount] = useState<number>(0);
  const [availableFeatures, setAvailableFeatures] = useState<GridRowData[]>([]);
  const [availableLayers, setAvailableLayers] =
    useState<Map<string, { layer: AnimatedCluster; name: string }> | null>(null);
  const [customIsEqual, setCustomIsEqual] = useState<boolean | null>(null);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<EmailType | null>(null);
  const [ebts, setEbts] = useState<EBT[]>([]);
  const [eventIds, setEventIds] = useState<EventIds | null>(null);
  const [eventName, setEventName] = useState<string | null>(null);
  const [gridRows, setGridRows] = useState<GridRowData[]>([]);
  const [hideForm, setHideForm] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [maxDate, setMaxDate] = useState<Date | null>();
  const [minDate, setMinDate] = useState<Date | null>();
  const [microfences, setMicrofences] = useState<{ id: string; name: string }[]>([]);
  const [notificationData, setNotificationData] = useState<NotificationType | null>();
  const [page, setPage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string | undefined>();
  const [selectedTriggerAction, setSelectedTriggerAction] = useState<ActionType | undefined>();
  const [selectedGridRowData, setSelectedGridRowData] = useState<GridRowData | undefined>();
  const [selectedTriggerType, setSelectedTriggerType] = useState<TriggerType | undefined>();
  const [selectedTriggerOption, setSelectedTriggerOption] = useState<TriggerOption | undefined>();
  const [selectedTriggerSubType, setSelectedTriggerSubType] =
    useState<TriggerSubType | undefined>();
  const [selectedCrossingDirection, setSelectedCrossingDirection] =
    useState<CrossingTypeId | undefined>();
  const [selectedDropdownLayer, setSelectedDropdownLayer] = useState<string | null>(null);
  const [selectedTabledData, setSelectedTableData] =
    useState<GridInputRowSelectionModel | undefined>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [triggerValue, setTriggerValue] = useState<string | null>(null);
  const [webhookData, setWebhookData] = useState<WebhookType | null>(null);

  const microFencesRef = useRef<GridRowData[]>([]);
  const pageRef = useRef<number>(1);
  const searchStringRef = useRef<string | undefined>();

  const paginateMicrofences = useCallback(async () => {
    const microfences = (
      await axios.get<GridRowData[]>(`${triggersUrl}/${cid}/${pid}/microfences`, authedConfig)
    ).data.filter(m => {
      return searchString ? m.name.includes(searchString) : m.name;
    });

    microFencesRef.current = microfences.sort((a, b) => a.name.localeCompare(b.name));
    setAvailableFeatures(microfences.slice(0, DISPLAY_LIMIT));
    setAvailableFeaturesCount(microfences.length);
  }, [triggersUrl, authedConfig, cid, pid, searchString]);

  const getLayers = useCallback(async () => {
    const layers: Map<string, { layer: AnimatedCluster; name: string }> = await getVectorLayers(
      triggersUrl,
      authedConfig,
      { cid, pid },
      { latitude: 0, longitude: 0, extentInDegrees: initialExtentInDegrees },
    );

    const lyrs = new Map([...layers.entries()].sort((a, b) => a[1].name.localeCompare(b[1].name)));
    setAvailableLayers(lyrs);
  }, [triggersUrl, authedConfig, cid, pid]);

  const paginateGeofences = useCallback(
    async (layerId?: string | null) => {
      if (layerId === MICROFENCE_LAYER_ID) return;
      pageRef.current = layerId ? 1 : pageRef.current;
      const data = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}?layerId=${
            layerId ?? selectedDropdownLayer
          }&perPage=5&fenceType=${
            selectedTriggerOption?.id === 'CROSSING'
              ? 'LINE'
              : selectedTriggerSubType?.id === 'CLEARED_ZONE'
              ? 'MULTIPOLYGON'
              : 'POLYGON'
          }${searchString ? `&searchName=` + encodeURIComponent(searchString) : ''}`,
          authedConfig,
        )
      ).data;
      setSelectedDropdownLayer(layerId ?? selectedDropdownLayer);
      setAvailableFeatures(data.geofences);
      setAvailableFeaturesCount(Number(data.count));
    },
    [
      triggersUrl,
      authedConfig,
      cid,
      pid,
      selectedDropdownLayer,
      selectedTriggerOption,
      selectedTriggerSubType,
      searchString,
    ],
  );

  const clearFormData = useCallback(() => {
    pageRef.current = 1;
    setIsEditing(false);
    setSelectedTriggerType(undefined);
    setSelectedTriggerOption(undefined);
    setSelectedTriggerSubType(undefined);
    setSelectedDropdownLayer(null);
    setSelectedCrossingDirection(undefined);
    setTriggerValue(null);
    setSelectedGridRowData(undefined);
    setSelectedTableData(undefined);
    setSelectedTriggerAction(undefined);
    setEmailData(null);
    setWebhookData(null);
    setEventName(null);
    setEventIds(null);
    setSearchString(undefined);
    searchStringRef.current = undefined;
  }, []);

  useEffect(() => {
    if (!selectedDropdownLayer || !isEditing) return;
    paginateGeofences(selectedDropdownLayer);
  }, [selectedDropdownLayer, paginateGeofences, isEditing]);

  useEffect(() => {
    if (!selectedTriggerOption) return;
    getLayers();
  }, [selectedTriggerOption, getLayers]);

  useEffect(() => {
    if (!clearForm) return;
    clearFormData();
  }, [clearForm, clearFormData]);

  useEffect(() => {
    pageRef.current = 1;
    if (refresh) {
      setSelectedTriggerType(undefined);
    }
    setSelectedTriggerOption(undefined);
    setSelectedTriggerSubType(undefined);
    setSelectedCrossingDirection(undefined);
    setTriggerValue(null);
    setSelectedGridRowData(undefined);
    setSelectedTableData(undefined);
    setSelectedTriggerAction(undefined);
    setEmailData(null);
    setWebhookData(null);
    setEventName(null);
    setSearchString(undefined);
    searchStringRef.current = undefined;
  }, [refresh]);

  useEffect(() => {
    if (selectedTriggerType?.id === 'PROXIMITY') {
      paginateMicrofences();
    }
  }, [selectedTriggerType, paginateMicrofences]);

  const getTriggerTypeIcon = (type: TriggerTypeId) => {
    switch (type) {
      case 'BATTERY':
        return <Battery50 />;
      case 'CUSTOM':
        return <IconSingleCustom />;
      case 'ENVIRONMENTALMONITORING':
        return <IconLeaf />;
      case 'EXTENSOMETER':
        return <IconExtensometer />;
      case 'INACTIVITY':
        return <IconInactivity />;
      case 'LOCATION':
        return <IconLocation />;
      case 'MANDOWN':
        return <IconManDown />;
      case 'PROXIMITY':
        return <IconSingleBeacon />;
      case 'SCHEDULER':
        return <IconSingleCalendar />;
      case 'SPO2':
        return <IconSingleOxygen />;
      case 'TEMPERATURE':
        return <IconSingleTemperature />;
      case 'WEBHOOK':
        return <IconSingleGlobe />;
      case 'WELFARECHECKRESPONSE':
        return <PhoneForwarded />;
      default:
        break;
    }
  };

  const getTriggerOptionIcon = (type: TriggerTypeId, option: TriggerTypeOptionsId) => {
    switch (type) {
      case 'BATTERY':
        return option === 'BELOW' ? (
          <Battery20 style={{ color: '#fff' }} />
        ) : (
          <Battery80 style={{ color: '#fff' }} />
        );
      case 'CUSTOM':
        return option === 'STRING' ? <IconSingleString /> : <IconSingleNumber />;
      case 'LOCATION':
        return option === 'ENTRY' ? (
          <IconEntry />
        ) : option === 'EXIT' ? (
          <IconExit />
        ) : option === 'CROSSING' ? (
          <Crossing />
        ) : (
          <HistoryToggleOff />
        );
      case 'PROXIMITY':
        return option === 'ENTRY' ? (
          <Sensors style={{ color: '#fff' }} />
        ) : (
          <SensorsOff style={{ color: '#fff' }} />
        );
      case 'SCHEDULER':
        return option === 'ENTRY' ? (
          <Sensors style={{ color: '#fff' }} />
        ) : (
          <SensorsOff style={{ color: '#fff' }} />
        );
      case 'SPO2':
        return option === 'BELOW' ? <IconSingleOxygenBelow /> : <IconSingleOxygenAbove />;
      case 'TEMPERATURE':
        return option === 'BELOW' ? <IconSingleTemperatureBelow /> : <IconSingleTemperatureAbove />;
      case 'WEBHOOK':
        return option === 'GET' ? <IconSingleWebhookGet /> : <IconSingleWebhookPost />;
      default:
        break;
    }
  };

  const getTriggerSubTypIcon = (subType: TriggerSubTypeId) => {
    // At the moment this only applies for locaion.
    switch (subType) {
      case 'CLEARED_ZONE':
        return <DashboardOutlined />;
      case 'GEOFENCE':
        return <CheckBoxOutlineBlank />;
      default:
        break;
    }
  };

  const getActionIcon = (type: ActionTypeId) => {
    switch (type) {
      case 'NOTIFICATION':
        return <IconSingleNotif />;
      case 'EMAIL':
        return <IconSingleEmail />;
      case 'WEBHOOK':
        return <IconSingleGlobe />;
      default:
        break;
    }
  };

  const createListeners = (id?: string) => {
    const isSensor =
      selectedTriggerType?.id === 'BATTERY' ||
      selectedTriggerType?.id === 'TEMPERATURE' ||
      selectedTriggerType?.id === 'SPO2';
    return [
      {
        type:
          selectedTriggerType?.id === 'LOCATION' ? 'geofence' : isSensor ? 'sensor' : 'microfence',
        sensorType: isSensor ? 'boundary' : undefined,
        geofenceType:
          selectedTriggerType?.id === 'LOCATION' && selectedGridRowData
            ? selectedGridRowData?.row?.points
              ? selectedGridRowData?.row?.points?.type === 'Polygon'
                ? selectedTriggerSubType?.id === 'CLEARED_ZONE'
                  ? 'multipolygon'
                  : 'polygon'
                : 'line'
              : selectedGridRowData?.row.type
            : undefined,
        entered: isSensor
          ? true
          : selectedTriggerOption &&
            (selectedTriggerOption.id === 'ENTRY' ||
              selectedTriggerOption.id === 'DWELL' ||
              selectedCrossingDirection === 'LEFT')
          ? true
          : false,
        dwellSeconds:
          selectedTriggerOption?.id !== 'DWELL'
            ? undefined
            : isNaN(Number(triggerValue))
            ? 0
            : Number(triggerValue),
        id,
      },
    ];
  };

  const validateEmailAddress = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const createEmail = async (id: string) => {
    await axios.post(
      `${notifierUrl}/${cid}/${pid}/email`,
      {
        text: emailData?.message,
        subject: emailData?.subject,
        recipients: [emailData?.address],
        listeners: createListeners(id),
      },
      authedConfig,
    );
  };

  const updateEmail = async (emailId: string, triggerId: string) => {
    await axios.patch(
      `${notifierUrl}/${cid}/${pid}/email/${emailId}`,
      {
        text: emailData?.message,
        subject: emailData?.subject,
        recipients: [emailData?.address],
        listeners: createListeners(triggerId),
      },
      authedConfig,
    );
  };

  const createWebhook = async (id: string) => {
    await axios.post(
      `${notifierUrl}/${cid}/${pid}/webhook`,
      {
        url: webhookData?.url,
        method: webhookData?.method,
        listeners: createListeners(id),
      },
      authedConfig,
    );
  };

  const updateWebhook = async (webhookId: string, triggerId: string) => {
    await axios.patch(
      `${notifierUrl}/${cid}/${pid}/webhook/${webhookId}`,
      {
        url: webhookData?.url,
        method: webhookData?.method,
        listeners: createListeners(triggerId),
      },
      authedConfig,
    );
  };

  const addDwellTimeToFence = async (layerId: string, id: string, type: GeofenceTypes) => {
    const fence = (
      await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
        authedConfig,
      )
    ).data;

    const geomobyProperties: Record<string, string> = fence.geomobyProperties;
    geomobyProperties['dwellSeconds'] = String(triggerValue);

    await axios.patch(
      `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
      {
        geometry: fence.points,
        properties: {
          ...fence,
          geomobyProperties,
        },
        type: 'Feature',
      },
      authedConfig,
    );
  };

  const handleSubmit = async () => {
    const invalidEmailAddress = emailData ? !validateEmailAddress(emailData?.address ?? '') : false;

    if (!canSave() || invalidEmailAddress) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: invalidEmailAddress
          ? 'Invalid email address.'
          : 'Please complete all the required fields.',
      });
      return;
    }

    try {
      if (
        selectedTriggerType?.id === 'BATTERY' ||
        selectedTriggerType?.id === 'TEMPERATURE' ||
        selectedTriggerType?.id === 'SPO2'
      ) {
        const sensor = {
          sensorType:
            selectedTriggerType?.id === 'TEMPERATURE'
              ? 'TEMPERATURE_CELSIUS'
              : selectedTriggerType?.id === 'SPO2'
              ? 'SPO2_PERCENT'
              : selectedTriggerType?.id === 'BATTERY'
              ? 'BATTERY_PERCENT'
              : undefined,
          name: eventName,
          boundary: Number(triggerValue),
          higherIsEnter: selectedTriggerOption?.id === 'ABOVE',
        };

        if (eventIds) {
          await axios
            .patch(
              `${triggersUrl}/${cid}/${pid}/boundary/${eventIds.listenerId}`,
              sensor,
              authedConfig,
            )
            .then(async result => {
              const { id } = result.data;
              if (eventIds.emailId) {
                await updateEmail(eventIds.emailId, id);
              } else if (eventIds.webhookId) {
                await updateWebhook(eventIds.webhookId, id);
              }
            });
        } else {
          await axios
            .post(`${triggersUrl}/${cid}/${pid}/boundary`, sensor, authedConfig)
            .then(async result => {
              const { id } = result.data;
              if (selectedTriggerAction?.id === 'EMAIL') {
                await createEmail(id);
              } else if (selectedTriggerAction?.id === 'WEBHOOK') {
                await createWebhook(id);
              }
            });
        }
      } else if (selectedGridRowData) {
        const fenceId = selectedGridRowData.id;
        if (selectedTriggerAction?.id === 'EMAIL' && emailData) {
          eventIds?.emailId
            ? await updateEmail(eventIds.emailId, fenceId)
            : await createEmail(fenceId);
        } else if (selectedTriggerAction?.id === 'WEBHOOK' && webhookData) {
          eventIds?.webhookId
            ? await updateWebhook(eventIds.webhookId, fenceId)
            : await createWebhook(fenceId);
        }

        if (selectedTriggerOption?.id === 'DWELL') {
          const { layerId, id, type } = selectedGridRowData.row;
          await addDwellTimeToFence(layerId, id, type);
        }
      }

      setPage(1);
      setEbts([]);
      setRefresh(true);
      setEventIds(null);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (e) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message:
          (e as Error).message ??
          `'Failed ${
            eventIds ? 'updating' : 'creating'
          } ${selectedTriggerAction?.id.toLowerCase()}.`,
      });
    }
  };

  const canSave = () => {
    const crossingHasDirection =
      selectedTriggerOption?.id === 'CROSSING' ? !!selectedCrossingDirection : true;
    return (
      selectedTriggerType &&
      selectedTriggerOption &&
      crossingHasDirection &&
      (triggerValue || selectedGridRowData) &&
      ((emailData?.address && emailData?.subject && emailData?.message) || webhookData?.url) &&
      (eventName ||
        selectedTriggerType?.id === 'LOCATION' ||
        selectedTriggerType?.id === 'PROXIMITY')
    );
  };

  return (
    <>
      <Container>
        {!hideForm && (
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
            }}
          >
            <Header icon={<DirectionsRun />}>{eventIds ? 'Update' : 'Create new'} event</Header>

            {/*Trigger type*/}
            <FormControl
              fullWidth
              style={{
                marginTop: 20,
                marginBottom: 20,
                width: 'calc(100% - 10vw)',
                alignSelf: 'center',
              }}
            >
              <InputLabel id="trigger-type">Event Trigger Type</InputLabel>
              <Select
                fullWidth
                labelId="trigger-type"
                id="trigger-type-dropdown"
                value={selectedTriggerType?.value ?? ''}
                label="Event Trigger Type"
                onChange={e => {
                  const triggerType = TriggerTypes.find(
                    trigger => trigger.value === (e.target.value as TriggerTypeValue),
                  );
                  setSelectedTriggerType(triggerType);
                  pageRef.current = 1;
                  setSelectedTriggerOption(undefined);
                  setSelectedTriggerSubType(undefined);
                  setSelectedCrossingDirection(undefined);
                  setTriggerValue(null);
                  setSelectedGridRowData(undefined);
                  setSelectedTableData(undefined);
                  if (!eventIds) {
                    setSelectedTriggerAction(undefined);
                  }
                  if (!eventIds?.emailId) {
                    setEmailData(null);
                  }
                  if (!eventIds?.webhookId) {
                    setWebhookData(null);
                  }
                  setEventName(null);
                  setIsEditing(false);
                }}
              >
                {TriggerTypes.map(type => (
                  <MenuItem key={type.id} value={type.value} disabled={type.disabled}>
                    <Tooltip title={type.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {getTriggerTypeIcon(type.id)}
                        <span
                          style={{
                            padding: '10px',
                          }}
                        ></span>{' '}
                        {type.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/*Geofence or Cleared Zone*/}
            {selectedTriggerType?.id === 'LOCATION' && (
              <FormControl
                fullWidth
                style={{
                  marginBottom: 20,
                  width: 'calc(100% - 10vw)',
                  alignSelf: 'center',
                }}
              >
                <InputLabel id="location-type">Location Type</InputLabel>
                <Select
                  fullWidth
                  labelId="location-type"
                  id="location-type-dropdown"
                  value={selectedTriggerSubType?.value ?? ''}
                  label="Location Type"
                  onChange={e => {
                    if (!selectedTriggerType) return;
                    const triggerSubType = selectedTriggerType.subTypes?.find(
                      subType => subType.value === (e.target.value as TriggerSubTypeValue),
                    );
                    setSelectedTriggerSubType(triggerSubType);
                    setSelectedTriggerOption(undefined);
                    setAvailableFeatures([]);
                    setSelectedDropdownLayer(null);
                    setTriggerValue(null);
                    setSelectedGridRowData(undefined);
                    setSelectedTableData(undefined);
                    setIsEditing(false);
                  }}
                >
                  {selectedTriggerType.subTypes?.map(subType => (
                    <MenuItem key={subType.id} value={subType.value}>
                      <Tooltip title={subType.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {getTriggerSubTypIcon(subType.id)}
                          <span
                            style={{
                              padding: '10px',
                            }}
                          ></span>{' '}
                          {subType.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/*Trigger option*/}
            {selectedTriggerType && (
              <FormControl
                fullWidth
                style={{
                  marginBottom: 20,
                  width: 'calc(100% - 10vw)',
                  alignSelf: 'center',
                }}
              >
                <InputLabel id="trigger-option">Event Trigger Option</InputLabel>
                <Select
                  fullWidth
                  labelId="trigger-option"
                  id="trigger-option-dropdown"
                  value={selectedTriggerOption?.value ?? ''}
                  label="Event Trigger Option"
                  onChange={e => {
                    const triggerOption = selectedTriggerType.options.find(
                      option => option.value === (e.target.value as TriggerTypeOptionsValue),
                    );
                    setSelectedTriggerOption(triggerOption);
                    if (selectedTriggerType.id === 'LOCATION') {
                      setAvailableFeatures([]);
                      setSelectedDropdownLayer(null);
                      setTriggerValue(null);
                      setSelectedGridRowData(undefined);
                      setSelectedTableData(undefined);
                    }
                    setIsEditing(false);
                  }}
                >
                  {[
                    ...(selectedTriggerType.id === 'LOCATION' &&
                    selectedTriggerSubType?.id === 'CLEARED_ZONE'
                      ? selectedTriggerType.options.filter(t => t.id !== 'CROSSING')
                      : selectedTriggerType.options),
                  ].map(option => (
                    <MenuItem key={option.id} value={option.value} disabled={option.disabled}>
                      <Tooltip title={option.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {getTriggerOptionIcon(selectedTriggerType.id, option.id)}
                          <span
                            style={{
                              padding: '10px',
                            }}
                          ></span>{' '}
                          {option.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/*Event value*/}
            {selectedTriggerType && selectedTriggerOption && (
              <Paper
                variant="outlined"
                style={{
                  width: 'calc(100% - 10vw)',
                  background: '#23272D',
                }}
              >
                <Box padding={2}>
                  <form
                    className={selectedTriggerType.id === 'PROXIMITY' ? 'hidden' : 'trigger-form'}
                    noValidate
                    autoComplete="off"
                    onChange={e => {
                      if (
                        selectedTriggerType.id === 'PROXIMITY' ||
                        (selectedTriggerType.id === 'LOCATION' &&
                          selectedTriggerOption.id !== 'DWELL')
                      )
                        return;
                      setTriggerValue((e.target as HTMLTextAreaElement).value);
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  >
                    {(() => {
                      switch (selectedTriggerType.id) {
                        case 'PROXIMITY':
                          if (
                            selectedTriggerOption.id === 'ENTRY' ||
                            selectedTriggerOption.id === 'EXIT'
                          ) {
                            return (
                              <Stack direction="column" spacing={1}>
                                <GeofenceSearchToolbar
                                  layerId={MICROFENCE_LAYER_ID}
                                  searchType={'Microfences'}
                                  searchString={searchString}
                                  setSearchString={setSearchString}
                                  onSearch={() => {
                                    if (searchString !== searchStringRef.current) {
                                      setTriggerValue(null);
                                      searchStringRef.current = searchString;
                                      paginateMicrofences();
                                    }
                                  }}
                                  onFocus={() => {
                                    setSelectedGridRowData(undefined);
                                  }}
                                ></GeofenceSearchToolbar>

                                {availableFeatures?.length > 0 &&
                                  microFencesRef.current?.length > 0 && (
                                    <div style={{ height: 372, width: '100%' }}>
                                      <DataGrid
                                        style={{
                                          fontFamily: `Open Sans, Montserrat, Arial, sans-serif`,
                                          fontWeight: 300,
                                          fontStyle: 'normal',
                                          fontSize: 16,
                                        }}
                                        rows={availableFeatures}
                                        columns={[
                                          {
                                            field: 'name',
                                            headerName: 'Select Microfence',
                                            width: 990,
                                          },
                                        ]}
                                        rowCount={availableFeaturesCount}
                                        paginationModel={{ pageSize: 5, page: pageRef.current - 1 }}
                                        pageSizeOptions={[5]}
                                        paginationMode="server"
                                        rowSelectionModel={selectedTabledData}
                                        onRowSelectionModelChange={(
                                          selectionModel: GridInputRowSelectionModel | undefined,
                                        ) => {
                                          setSelectedTableData(selectionModel);
                                        }}
                                        onPaginationModelChange={(e, value) => {
                                          if (availableFeatures.length === 0) {
                                            pageRef.current = 1;
                                            paginateMicrofences();
                                          } else {
                                            pageRef.current = e.page + 1;
                                            setAvailableFeatures(
                                              microFencesRef.current.slice(
                                                pageRef.current * DISPLAY_LIMIT - DISPLAY_LIMIT,
                                                pageRef.current * DISPLAY_LIMIT,
                                              ),
                                            );
                                          }
                                        }}
                                        onRowClick={e => {
                                          setSelectedGridRowData(e);
                                          setTriggerValue(null);
                                        }}
                                      />
                                    </div>
                                  )}
                                {availableFeatures.length === 0 ? 'No microfences available' : ''}
                              </Stack>
                            );
                          }
                          break;
                        case 'LOCATION':
                          if (
                            selectedTriggerOption.id === 'ENTRY' ||
                            selectedTriggerOption.id === 'EXIT' ||
                            selectedTriggerOption.id === 'CROSSING' ||
                            selectedTriggerOption.id === 'DWELL'
                          ) {
                            return (
                              <Stack direction="column" spacing={1}>
                                {
                                  <div>
                                    <Box mt={1}>
                                      {selectedTriggerType.id === 'LOCATION' &&
                                        selectedTriggerOption.id === 'CROSSING' && (
                                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                                            <InputLabel id="select-crossing-direction">
                                              Select Crossing Direction
                                            </InputLabel>
                                            <Select
                                              fullWidth
                                              labelId="select-crossing-direction"
                                              id="select-crossing-direction-dropdown"
                                              value={selectedCrossingDirection ?? ''}
                                              label="Select Crossing Direction"
                                              onChange={e => {
                                                setSelectedCrossingDirection(
                                                  e.target.value as CrossingTypeId,
                                                );
                                              }}
                                            >
                                              {Crossings.map(direction => (
                                                <MenuItem
                                                  key={direction.key}
                                                  value={direction.value}
                                                >
                                                  <Tooltip title={direction.label}>
                                                    <Typography
                                                      style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: 'calc(100% - 50px)',
                                                      }}
                                                    >
                                                      {direction.value === 'LEFT' ? (
                                                        <ArrowForward />
                                                      ) : (
                                                        <ArrowBack />
                                                      )}
                                                      <span
                                                        style={{
                                                          padding: '10px',
                                                        }}
                                                      ></span>{' '}
                                                      {direction.label}
                                                    </Typography>
                                                  </Tooltip>
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        )}
                                      <FormControl fullWidth>
                                        <InputLabel id="select-layer">Select layer</InputLabel>
                                        <Select
                                          fullWidth
                                          labelId="select-layer"
                                          id="select-layer-dropdown"
                                          value={
                                            availableLayers && availableLayers.size > 0
                                              ? selectedDropdownLayer ?? ''
                                              : ''
                                          }
                                          onChange={e => {
                                            paginateGeofences(e.target.value as string);
                                          }}
                                          label="Select Layer"
                                        >
                                          {availableLayers &&
                                            Array.from(availableLayers.entries()).map(
                                              ([key, value]) => (
                                                <MenuItem key={key} value={key}>
                                                  {value.name}
                                                </MenuItem>
                                              ),
                                            )}
                                          ;
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </div>
                                }

                                <br />
                                {selectedDropdownLayer && (
                                  <GeofenceSearchToolbar
                                    layerId={selectedDropdownLayer}
                                    searchType={
                                      selectedTriggerSubType?.id === 'CLEARED_ZONE'
                                        ? 'Cleared Zones'
                                        : 'Geofences'
                                    }
                                    searchString={searchString}
                                    setSearchString={setSearchString}
                                    onSearch={() => {
                                      if (searchString !== searchStringRef.current) {
                                        setTriggerValue(null);
                                        searchStringRef.current = searchString;
                                        paginateGeofences(selectedDropdownLayer);
                                      }
                                    }}
                                    onFocus={() => {
                                      setSelectedGridRowData(undefined);
                                    }}
                                  ></GeofenceSearchToolbar>
                                )}

                                <br />
                                {availableFeatures?.length > 0 && (
                                  <div style={{ height: 372, width: '100%' }}>
                                    <DataGrid
                                      style={{
                                        fontFamily: `Open Sans, Montserrat, Arial, sans-serif`,
                                        fontWeight: 300,
                                        fontStyle: 'normal',
                                        fontSize: 16,
                                      }}
                                      rows={availableFeatures}
                                      columns={[
                                        {
                                          field: 'name',
                                          headerName: 'Select Geofence',
                                          width: 990,
                                        },
                                      ]}
                                      rowCount={availableFeaturesCount}
                                      paginationModel={{ pageSize: 5, page: pageRef.current - 1 }}
                                      pageSizeOptions={[5]}
                                      paginationMode="server"
                                      rowSelectionModel={selectedTabledData}
                                      onRowSelectionModelChange={(
                                        selectionModel: GridInputRowSelectionModel | undefined,
                                      ) => {
                                        setSelectedTableData(selectionModel);
                                      }}
                                      onPaginationModelChange={(e, value) => {
                                        pageRef.current = e.page + 1;
                                        paginateGeofences(null);
                                      }}
                                      onRowClick={e => {
                                        setSelectedGridRowData(e);
                                        setTriggerValue(null);
                                      }}
                                    />
                                  </div>
                                )}

                                {selectedTriggerOption.id === 'DWELL' && selectedGridRowData && (
                                  <InputContainer
                                    id="dwell-above"
                                    label="Above dwell threshold (seconds)"
                                    key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                    name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                    value={triggerValue ?? ''}
                                    placeholder="60"
                                    required
                                  />
                                )}

                                {availableFeatures.length === 0 && selectedDropdownLayer
                                  ? 'No geofences available'
                                  : ''}
                              </Stack>
                            );
                          }
                          break;
                        case 'TEMPERATURE':
                          if (selectedTriggerOption.id === 'BELOW')
                            return (
                              <InputContainer
                                id="temperature-below"
                                label="Below body temperature threshold"
                                key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                value={triggerValue ?? ''}
                                placeholder="36.6"
                                required
                              />
                            );
                          if (selectedTriggerOption.id === 'ABOVE')
                            return (
                              <InputContainer
                                id="temperature-above"
                                label="Above body temperature threshold"
                                key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                value={triggerValue ?? ''}
                                placeholder="37.0"
                                required
                              />
                            );
                          break;
                        case 'SPO2':
                          if (selectedTriggerOption.id === 'BELOW')
                            return (
                              <InputContainer
                                id="spo2-below"
                                label="Below oxygenation threshold"
                                key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                value={triggerValue ?? ''}
                                placeholder="95"
                                required
                              />
                            );
                          if (selectedTriggerOption.id === 'ABOVE')
                            return (
                              <InputContainer
                                id="spo2-above"
                                label="Above oxygenation threshold"
                                key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                value={triggerValue ?? ''}
                                placeholder="97"
                                required
                              />
                            );
                          break;
                        case 'BATTERY':
                          if (selectedTriggerOption.id === 'BELOW')
                            return (
                              <InputContainer
                                id="spo2-below"
                                label="Below battery level threshold"
                                key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                value={triggerValue ?? ''}
                                placeholder="95"
                                required
                              />
                            );
                          if (selectedTriggerOption.id === 'ABOVE')
                            return (
                              <InputContainer
                                id="spo2-above"
                                label="Above battery level threshold"
                                key={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                name={selectedTriggerType.id + '_' + selectedTriggerOption.id}
                                value={triggerValue ?? ''}
                                placeholder="97"
                                required
                              />
                            );
                          break;
                        case 'SCHEDULER':
                          if (selectedTriggerOption.id === 'NEW_RULE')
                            return (
                              <Stack direction="column" spacing={1}>
                                <InputContainer
                                  id="start-date-scheduler"
                                  label="Start Date"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_start'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_start'
                                  }
                                  required
                                  dateRange
                                  // valueDate={String(minDate)} // TODO: SCHEDULER. Use date.toIsoString() instead
                                  valueDate={minDate ? String(minDate) : undefined}
                                  onChangeDate={setMinDate}
                                />
                                <InputContainer
                                  id="end-date-scheduler"
                                  label="End Date"
                                  key={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_end'
                                  }
                                  name={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_end'
                                  }
                                  required
                                  dateRange
                                  // valueDate={String(maxDate)} // TODO: SCHEDULER. Use date.toIsoString() instead
                                  valueDate={maxDate ? String(maxDate) : undefined}
                                  onChangeDate={setMaxDate}
                                />
                              </Stack>
                            );
                          break;
                        case 'WEBHOOK':
                          if (selectedTriggerOption.id === 'GET')
                            return (
                              <Stack direction="column" spacing={1}>
                                <InputContainer
                                  id="webhook-trigger-get-url"
                                  label="URL"
                                  key={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_url'
                                  }
                                  name={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_url'
                                  }
                                  required
                                />
                                <InputContainer
                                  id="webhook-trigger-get-response-field"
                                  label="Response field"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_response_field'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_response_field'
                                  }
                                  required
                                />
                                <div>
                                  <FormLabel component="legend">Condition</FormLabel>
                                  <RadioGroup
                                    aria-label="isEqual"
                                    key={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    name={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    defaultValue={false}
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio />}
                                      label="Is not equal to"
                                    />
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label="Is equal to"
                                    />
                                  </RadioGroup>
                                </div>
                                <InputContainer
                                  id="webhook-trigger-get-value"
                                  label="Value"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  required
                                />
                              </Stack>
                            );
                          if (selectedTriggerOption.id === 'POST')
                            return (
                              <Stack direction="column" spacing={1}>
                                <InputContainer
                                  id="webhook-trigger-post-url"
                                  label="URL"
                                  key={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_url'
                                  }
                                  name={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_url'
                                  }
                                  required
                                />
                                <InputContainer
                                  id="webhook-trigger-post-response-field"
                                  label="Response field"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_response_field'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_response_field'
                                  }
                                  required
                                />
                                <div>
                                  <FormLabel component="legend">Condition</FormLabel>
                                  <RadioGroup
                                    aria-label="isEqual"
                                    key={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    name={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    defaultValue={false}
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio />}
                                      label="Is not equal to"
                                    />
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label="Is equal to"
                                    />
                                  </RadioGroup>
                                </div>
                                <InputContainer
                                  id="webhook-trigger-post-value"
                                  label="Value"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  required
                                />
                              </Stack>
                            );
                          break;
                        case 'CUSTOM':
                          if (selectedTriggerOption.id === 'STRING')
                            return (
                              <Stack direction="column" spacing={1}>
                                <InputContainer
                                  id="custom-trigger-string-key"
                                  label="Key"
                                  key={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_key'
                                  }
                                  name={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_key'
                                  }
                                  required
                                />
                                <div>
                                  <FormLabel component="legend">Condition</FormLabel>
                                  <RadioGroup
                                    aria-label="isEqual"
                                    key={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    name={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    value={customIsEqual}
                                    onChange={e => {
                                      e.target.value === 'true'
                                        ? setCustomIsEqual(true)
                                        : setCustomIsEqual(false);
                                    }}
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio />}
                                      label="Is not equal to"
                                    />
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label="Is equal to"
                                    />
                                  </RadioGroup>
                                </div>
                                <InputContainer
                                  id="custom-trigger-string-value"
                                  label="Value"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  required
                                />
                              </Stack>
                            );
                          if (selectedTriggerOption.id === 'NUMBER')
                            return (
                              <Stack direction="column" spacing={1}>
                                <InputContainer
                                  id="custom-trigger-number-key"
                                  label="Key"
                                  key={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_key'
                                  }
                                  name={
                                    selectedTriggerType.id + '_' + selectedTriggerOption.id + '_key'
                                  }
                                  required
                                />
                                <div>
                                  <FormLabel component="legend">Condition</FormLabel>
                                  <RadioGroup
                                    aria-label="isEqual"
                                    key={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    name={
                                      selectedTriggerType.id +
                                      '_' +
                                      selectedTriggerOption.id +
                                      '_isEqual'
                                    }
                                    value={customIsEqual}
                                    onChange={e => {
                                      e.target.value === 'true'
                                        ? setCustomIsEqual(true)
                                        : setCustomIsEqual(false);
                                    }}
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio />}
                                      label="Is not equal to"
                                    />
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label="Is equal to"
                                    />
                                  </RadioGroup>
                                </div>
                                <InputContainer
                                  id="custom-trigger-number-value"
                                  label="Value"
                                  key={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  name={
                                    selectedTriggerType.id +
                                    '_' +
                                    selectedTriggerOption.id +
                                    '_value'
                                  }
                                  required
                                />
                              </Stack>
                            );
                          break;
                        default:
                      }
                    })()}
                  </form>
                </Box>
              </Paper>
            )}

            {/*Action*/}
            {((selectedTriggerType &&
              selectedTriggerOption &&
              (triggerValue ||
                (selectedGridRowData && selectedTriggerOption.id !== 'DWELL') ||
                (selectedGridRowData && selectedTriggerOption.id === 'DWELL' && triggerValue)) &&
              (selectedTriggerOption.id !== 'CROSSING' ||
                (selectedTriggerOption.id === 'CROSSING' && selectedCrossingDirection))) ||
              eventIds) && (
              <FormControl
                fullWidth
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  width: 'calc(100% - 10vw)',
                  alignSelf: 'center',
                }}
              >
                <InputLabel id="trigger-action">Event Action</InputLabel>
                <Select
                  fullWidth
                  labelId="trigger-action"
                  id="trigger-action-dropdown"
                  value={selectedTriggerAction?.value ?? ''}
                  label="Event Action"
                  disabled={!!eventIds}
                  onChange={e => {
                    const action = ActionTypes.find(
                      trigger => trigger.value === (e.target.value as ActionTypeValue),
                    );
                    setSelectedTriggerAction(action);
                  }}
                >
                  {ActionTypes.map(action => (
                    <MenuItem key={action.id} value={action.value} disabled={action.disabled}>
                      <Tooltip title={action.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {getActionIcon(action.id)}
                          <span
                            style={{
                              padding: '10px',
                            }}
                          ></span>{' '}
                          {action.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/*Event value*/}
            {selectedTriggerAction && (
              <Paper
                variant="outlined"
                style={{
                  width: 'calc(100% - 10vw)',
                  background: '#23272D',
                }}
              >
                <Box padding={2}>
                  <form className={'trigger-action-form'} noValidate autoComplete="off">
                    {(() => {
                      switch (selectedTriggerAction.id) {
                        case 'EMAIL':
                          if (webhookData) {
                            setWebhookData(null);
                          }
                          return (
                            <Stack spacing={1}>
                              <InputContainer
                                id="email-address"
                                label="Email address"
                                placeholder="abc@abc.com"
                                key={selectedTriggerAction.id + '_address'}
                                name={selectedTriggerAction.id + '_address'}
                                value={emailData?.address ?? ''}
                                onChange={(e: { target: { value: string } }) => {
                                  setEmailData({
                                    address: e.target.value,
                                    subject: emailData?.subject ?? '',
                                    message: emailData?.message ?? '',
                                  });
                                }}
                                required
                              />
                              <InputContainer
                                id="subject"
                                label="Subject"
                                key={selectedTriggerAction.id + '_subject'}
                                name={selectedTriggerAction.id + '_subject'}
                                value={emailData?.subject ?? ''}
                                onChange={(e: { target: { value: string } }) => {
                                  setEmailData({
                                    address: emailData?.address ?? '',
                                    subject: e.target.value,
                                    message: emailData?.message ?? '',
                                  });
                                }}
                                required
                              />
                              <InputContainer
                                id="body"
                                label="Message"
                                key={selectedTriggerAction.id + '_body'}
                                name={selectedTriggerAction.id + '_body'}
                                value={emailData?.message ?? ''}
                                onChange={(e: { target: { value: string } }) => {
                                  setEmailData({
                                    address: emailData?.address ?? '',
                                    subject: emailData?.subject ?? '',
                                    message: e.target.value,
                                  });
                                }}
                                multiline
                                required
                              />
                            </Stack>
                          );
                        case 'NOTIFICATION':
                          return (
                            <Stack spacing={1}>
                              <InputContainer
                                id="notification-title"
                                label="Notification title"
                                key={selectedTriggerAction.id + '_title'}
                                name={selectedTriggerAction.id + '_title'}
                                value={notificationData?.title ?? ''}
                                onChange={(e: { target: { value: string } }) => {
                                  setNotificationData({
                                    title: e.target.value,
                                    body: notificationData?.body ?? '',
                                  });
                                }}
                                required
                              />
                              <InputContainer
                                id="body"
                                label="Message"
                                key={selectedTriggerAction.id + '_body'}
                                name={selectedTriggerAction.id + '_body'}
                                value={notificationData?.body ?? ''}
                                onChange={(e: { target: { value: string } }) => {
                                  setNotificationData({
                                    title: notificationData?.title ?? '',
                                    body: e.target.value,
                                  });
                                }}
                                multiline
                                required
                              />
                            </Stack>
                          );
                        case 'WEBHOOK':
                          if (emailData) {
                            setEmailData(null);
                          }
                          return (
                            <Stack spacing={1}>
                              <ToggleButtonGroup
                                color="primary"
                                key={selectedTriggerAction.id + '_method'}
                                value={webhookData?.method ?? Method.GET}
                                exclusive
                                onChange={e => {
                                  setWebhookData({
                                    method: (e.target as EventTarget & HTMLInputElement)
                                      .value as Method,
                                    url: webhookData?.url ?? '',
                                  });
                                }}
                                aria-label="Platform"
                              >
                                <ToggleButton value={Method.GET}>{Method.GET}</ToggleButton>
                                <ToggleButton value={Method.POST}>{Method.POST}</ToggleButton>
                              </ToggleButtonGroup>
                              <InputContainer
                                id="webhook-action-url"
                                label="URL"
                                key={selectedTriggerAction.id + '_url'}
                                name={selectedTriggerAction.id + '_url'}
                                value={webhookData?.url ?? ''}
                                onChange={(e: { target: { value: string } }) => {
                                  setWebhookData({
                                    method: webhookData?.method ?? Method.GET,
                                    url: e.target.value,
                                  });
                                }}
                                placeholder={'https://www.example.com.au/'}
                                required
                              />
                            </Stack>
                          );
                        default:
                          break;
                      }
                    })()}
                  </form>
                </Box>
              </Paper>
            )}

            {selectedTriggerAction &&
              !(
                selectedTriggerType?.id === 'LOCATION' || selectedTriggerType?.id === 'PROXIMITY'
              ) && (
                <Paper
                  style={{
                    width: 'calc(100% - 10vw)',
                    background: '#23272D',
                  }}
                >
                  <Box p={2} overflow="auto">
                    <Stack direction="column" spacing={2}>
                      <Typography variant="body1">
                        When the
                        <Typography color="primary" component="span" fontWeight="bold">
                          {' ' + selectedTriggerType?.id} - {selectedTriggerOption?.id}
                        </Typography>{' '}
                        event occurs,
                      </Typography>
                      <Typography>
                        a corresponding
                        <Typography color="primary" component="span" fontWeight="bold">
                          {' '}
                          {' ' + selectedTriggerAction?.id}
                        </Typography>{' '}
                        will be fired.
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Tooltip title={eventName}>
                          <TextField
                            onChange={e => setEventName(e.target.value)}
                            name="event-name"
                            value={eventName ?? ''}
                            placeholder="Name your new event trigger"
                            sx={{
                              '& input': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              },
                            }}
                            autoComplete="title"
                            fullWidth
                            autoFocus
                          ></TextField>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Box>
                </Paper>
              )}

            <Grid
              container
              direction="column"
              style={{
                width: 'calc(100% - 10vw)',
                marginTop: '20px',
                display: 'grid',
                gap: '2%',
                gridTemplateColumns: '49% 49%',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleSubmit}
                disabled={!canSave()}
              >
                {eventIds ? 'Update' : 'Create'}
              </Button>
              <Button variant="outlined" size="large" onClick={() => clearFormData()}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>

      <Grid
        container
        direction="column"
        style={{
          alignContent: 'end',
          paddingRight: 'calc(100% - 72vw)',
          marginBottom: '40px',
        }}
      >
        {!hideForm && (triggerValue || selectedGridRowData) && (
          <Tooltip title={'Hide form'}>
            <Button
              onClick={() => {
                setHideForm(true);
                window.document.body.scrollTop = document.documentElement.scrollTop = 0;
              }}
              startIcon={<ExpandLessIcon />}
              sx={{
                '& span': {
                  margin: '0px',
                },
              }}
            ></Button>
          </Tooltip>
        )}

        {hideForm && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setHideForm(false)}
            style={{
              width: '50%',
              right: '5%',
              marginTop: '3%',
              marginBottom: '3%',
            }}
          >
            Finish {eventIds ? 'updating' : 'creating'} event
          </Button>
        )}
      </Grid>

      <EventsList
        refresh={refresh}
        setRefresh={setRefresh}
        ebts={ebts}
        setEbts={setEbts}
        pageRef={pageRef}
        page={page}
        setPage={setPage}
        setSelectedTriggerType={setSelectedTriggerType}
        setSelectedTriggerOption={setSelectedTriggerOption}
        setSelectedTriggerSubType={setSelectedTriggerSubType}
        setSelectedCrossingDirection={setSelectedCrossingDirection}
        setSelectedDropdownLayer={setSelectedDropdownLayer}
        setTriggerValue={setTriggerValue}
        setSelectedGridRowData={setSelectedGridRowData}
        setSelectedTableData={setSelectedTableData}
        setSelectedTriggerAction={setSelectedTriggerAction}
        setEmailData={setEmailData}
        setWebhookData={setWebhookData}
        setEventName={setEventName}
        setIsEditing={setIsEditing}
        setHideForm={setHideForm}
        setClearForm={setClearForm}
        eventIds={eventIds}
        setEventIds={setEventIds}
      />
    </>
  );
};
export default Events;
