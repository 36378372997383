import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleTemperatureAbove {
  className?: string;
}

const IconSingleTemperatureAbove: React.FC<IconSingleTemperatureAbove> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 355 356" className={className}>
      <path
        d="M274.568 52.1484C274.568 23.3938 251.24 0 222.566 0C193.893 0 170.564 23.3938 170.564 52.1484V231.998C157.291 245.609 149.764 263.936 149.764 282.992C149.764 323.249 182.423 356 222.566 356C262.71 356 295.369 323.249 295.369 282.992C295.369 263.936 287.841 245.608 274.568 231.998V52.1484ZM222.566 335.141C193.893 335.141 170.564 311.747 170.564 282.992C170.564 268.257 176.886 254.121 187.909 244.207C190.109 242.229 191.365 239.406 191.365 236.442V52.1484C191.365 34.8956 205.362 20.8594 222.566 20.8594C239.771 20.8594 253.768 34.8956 253.768 52.1484V236.442C253.768 239.406 255.024 242.229 257.224 244.207C268.247 254.121 274.568 268.257 274.568 282.992C274.568 311.747 251.241 335.141 222.566 335.141Z"
        fill="#F2F2F2"
      />
      <path
        d="M232.97 253.497V135.585C232.97 129.825 228.313 125.156 222.569 125.156C216.826 125.156 212.169 129.825 212.169 135.585V253.497C200.064 257.802 191.368 269.395 191.368 282.992C191.368 300.245 205.365 314.281 222.569 314.281C239.774 314.281 253.771 300.245 253.771 282.992C253.771 269.395 245.075 257.802 232.97 253.497ZM222.569 293.421C216.835 293.421 212.169 288.743 212.169 282.992C212.169 277.241 216.835 272.562 222.569 272.562C228.304 272.562 232.97 277.241 232.97 282.992C232.97 288.743 228.305 293.421 222.569 293.421Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.77 62.5781H326.57C332.314 62.5781 336.971 57.9084 336.971 52.1484C336.971 46.3885 332.314 41.7188 326.57 41.7188H305.77C300.026 41.7188 295.369 46.3885 295.369 52.1484C295.369 57.9084 300.026 62.5781 305.77 62.5781Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.77 104.296H316.17C321.914 104.296 326.57 99.6266 326.57 93.8667C326.57 88.1067 321.914 83.437 316.17 83.437H305.77C300.026 83.437 295.369 88.1067 295.369 93.8667C295.369 99.6266 300.026 104.296 305.77 104.296Z"
        fill="#F2F2F2"
      />
      <path
        d="M326.57 125.156H305.77C300.026 125.156 295.369 129.825 295.369 135.585C295.369 141.345 300.026 146.015 305.77 146.015H326.57C332.314 146.015 336.971 141.345 336.971 135.585C336.971 129.825 332.314 125.156 326.57 125.156Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.77 187.733H316.17C321.914 187.733 326.57 183.063 326.57 177.303C326.57 171.543 321.914 166.874 316.17 166.874H305.77C300.026 166.874 295.369 171.543 295.369 177.303C295.369 183.063 300.026 187.733 305.77 187.733Z"
        fill="#F2F2F2"
      />
      <path
        d="M326.57 208.592H305.77C300.026 208.592 295.369 213.262 295.369 219.022C295.369 224.782 300.026 229.452 305.77 229.452H326.57C332.314 229.452 336.971 224.782 336.971 219.022C336.971 213.262 332.314 208.592 326.57 208.592Z"
        fill="#F2F2F2"
      />
      <path
        d="M66.5609 177.305V77.3281L76.5411 87.3358C78.5713 89.3731 81.2331 90.391 83.8949 90.391C86.5567 90.391 89.2185 89.3731 91.2487 87.3358C95.3104 83.2626 95.3104 76.6593 91.2487 72.5861L63.5143 44.7736C59.4533 40.7005 52.8677 40.7005 48.806 44.7736L21.0717 72.5861C17.01 76.6593 17.01 83.2626 21.0717 87.3358C25.1327 91.4089 31.7182 91.4089 35.7799 87.3358L45.7601 77.3281V177.305C45.7601 183.065 50.4167 187.735 56.1605 187.735C61.9043 187.735 66.5609 183.065 66.5609 177.305Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleTemperatureAbove;
