import { Error, HourglassEmpty } from '@mui/icons-material';
import CallIcon from '@mui/icons-material/Call';
import { Button, LinearProgress, Tooltip } from '@mui/material';
import axios, { AxiosRequestConfig } from 'axios';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';

type WFC_STATE =
  | 'DEFAULT' // Before anything happens -> Shows start button -> Goes to STARTING
  | 'STARTING' // While contacting the server -> Spinner... ?
  | 'START_FAILED' // Server error -> Show error message and "Accept" button -> Return to DEFAULT
  | 'IN_PROGRESS'; // Server success -> We reset
// the asset colours, and wait for each asset to change
// colour according to its response to the welfare check
// prompt. We show a "Check Complete" button for the user
// to end the check.

async function startWelfareCheck(
  requestConfig: AxiosRequestConfig,
  setWfcState: React.Dispatch<React.SetStateAction<WFC_STATE>>,
) {
  setWfcState('STARTING');
  const res = await axios.post(
    '/welfare_check/trigger',
    '', //empty sting indicates empty body, otherwise the header goes to the body of the request
    requestConfig,
  );
  if (res.status === 200) {
    setWfcState('IN_PROGRESS');
  } else {
    setWfcState('START_FAILED');
  }
}

function view_default(
  authedRequestConfig: AxiosRequestConfig,
  setWfcState: React.Dispatch<React.SetStateAction<WFC_STATE>>,
) {
  return (
    <Tooltip title="Feature temporarily disabled for maintanence">
      <div>
        <Button
          fullWidth={true}
          variant="contained"
          color="secondary"
          size="large"
          disabled={true}
          startIcon={<CallIcon />}
          onClick={() => startWelfareCheck(authedRequestConfig, setWfcState)}
        >
          Start Welfare Check
        </Button>
      </div>
    </Tooltip>
  );
}
function view_starting() {
  return (
    <>
      <p>Starting Welfare Check</p>
      <LinearProgress color="secondary" />
    </>
  );
}
function view_start_failed(setWfcState: React.Dispatch<React.SetStateAction<WFC_STATE>>) {
  return (
    <>
      <p color="#ED3154">Welfare check failed to initialise. Contact GeoMoby.</p>
      <Button
        fullWidth={true}
        disabled={false}
        onClick={() => setWfcState('DEFAULT')}
        variant="contained"
        size="large"
        startIcon={<Error />}
      >
        OK
      </Button>
    </>
  );
}
function view_in_progress(
  setWfcState: React.Dispatch<React.SetStateAction<WFC_STATE>>,
  reset: () => void,
) {
  return (
    <>
      <p>Waiting for worker responses...</p>
      <Button
        fullWidth={true}
        //handle waiting for worker responses better
        // disabled={false}
        onClick={() => {
          reset();
          setWfcState('DEFAULT');
        }}
        variant="contained"
        size="large"
        startIcon={<HourglassEmpty />}
      >
        Finish
      </Button>
    </>
  );
}

export const WelfareCheck = ({ allow, reset }: { allow: () => void; reset: () => void }) => {
  const [wfcState, setWfcState] = useState<WFC_STATE>('DEFAULT');

  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  // The appropriate view should be returned based on the state
  switch (wfcState) {
    case 'STARTING':
      allow();
      return view_starting();
    case 'IN_PROGRESS':
      return view_in_progress(setWfcState, reset);
    case 'START_FAILED':
      return view_start_failed(setWfcState);
    default:
      return view_default(authedRequestConfig, setWfcState);
  }
};
