import { Box, Grid, Paper, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { ZIndexes } from '../../../util/ZIndexes';

export const Toolbar = ({
  children,
  top,
  bottom,
  left,
  right,
  width,
  minWidth,
  height,
  flexDirection,
  position,
  zIndex,
}: {
  children: ReactNode[];
  top?: string;
  bottom?: number;
  left?: number;
  right?: number;
  width?: number | string;
  minWidth?: string;
  height?: number;
  flexDirection?: 'row' | 'column';
  position?: 'relative' | 'absolute';
  zIndex?: number;
}) => (
  <Box
    position={position ?? 'absolute'}
    zIndex={zIndex ?? ZIndexes.MAP_TOOLBAR}
    top={top}
    bottom={bottom}
    left={left}
    right={right}
    width={width}
    minWidth={minWidth}
    borderRadius={4}
  >
    <Paper>
      <Box bgcolor="background.default">
        <Stack
          spacing={2}
          alignItems="center"
          padding={2}
          flexDirection={flexDirection}
          justifyContent={'space-between'}
          height={height}
        >
          {children.filter(Boolean)}
        </Stack>
      </Box>
    </Paper>
  </Box>
);
