import { FilterList, Search, Sort } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Header } from '../../Common/Sidebar';
import InputContainer from '../Global/InputContainer';
import {
  ActionTypeValue,
  EBT,
  EBTFilter,
  EnteredTypeValue,
  GeofenceTypeId,
  GeofenceTypeValue,
  ListenerTypeId,
  ListenerTypeValue,
  MethodTypeId,
  OrderByTypeValue,
  OrderTypeValue,
} from './types';
import {
  ActionTypes,
  EnteredTypes,
  GeofenceTypes,
  ListenerTypes,
  OrderByTypes,
  OrderTypes,
} from './values';

export const EBTFilterDialog = ({
  setOpenFilterDialog,
  setEbts,
  ebtFilter,
  setEbtFilter,
  enteredState,
  setEnteredState,
  dwellTimeMinimum,
  setDwellTimeMinimum,
  setRefresh,
  setPage,
}: {
  setOpenFilterDialog: Dispatch<SetStateAction<boolean>>;
  setEbts: Dispatch<SetStateAction<EBT[]>>;
  ebtFilter: EBTFilter | undefined;
  setEbtFilter: Dispatch<SetStateAction<EBTFilter | undefined>>;
  enteredState: EnteredTypeValue | undefined;
  setEnteredState: Dispatch<SetStateAction<EnteredTypeValue | undefined>>;
  dwellTimeMinimum: number | undefined;
  setDwellTimeMinimum: Dispatch<SetStateAction<number | undefined>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  setPage: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <>
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 0px 5px 25px',
        }}
      >
        <Header icon={<FilterList />}>Filters</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Notification Type*/}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="notifier-type-option">Notification Type</InputLabel>
          <Select
            fullWidth
            labelId="notifier-type-action"
            id="notifier-type-dropdown"
            value={ebtFilter?.notifierType?.value ?? ''}
            label="Notification Type"
            onChange={e => {
              const notifierType = ActionTypes.find(
                l => l.value === (e.target.value as ActionTypeValue),
              );
              setEbtFilter({
                ...ebtFilter,
                notifierType,
              });
            }}
          >
            {ActionTypes.map(action => (
              <MenuItem key={action.id} value={action.value} disabled={action.disabled}>
                <Tooltip title={action.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {action.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Event Trigger Type*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="listener-type-option">Event Trigger Type</InputLabel>
          <Select
            fullWidth
            labelId="listener-type-action"
            id="listener-type-dropdown"
            value={ebtFilter?.listenerType?.value ?? ''}
            label="Event Trigger Type"
            onChange={e => {
              const listenerType = ListenerTypes.find(
                l => l.value === (e.target.value as ListenerTypeValue),
              );
              setEbtFilter({
                ...ebtFilter,
                listenerType,
                entered: undefined,
              });
            }}
          >
            {ListenerTypes.map(listener => (
              <MenuItem key={listener.id} value={listener.value}>
                <Tooltip title={listener.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {listener.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Geofence Type*/}
        {ebtFilter?.listenerType?.id === 'geofence' && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputLabel id="geofence-type-option">Geofence Type</InputLabel>
            <Select
              fullWidth
              labelId="geofence-type-action"
              id="geofence-type-dropdown"
              value={ebtFilter?.geofenceType?.value ?? ''}
              label="Geofence Type"
              onChange={e => {
                const geofenceType = GeofenceTypes.find(
                  l => l.value === (e.target.value as GeofenceTypeValue),
                );
                setEbtFilter({
                  ...ebtFilter,
                  geofenceType,
                  entered: undefined,
                });
              }}
            >
              {GeofenceTypes.map(geofenceType => (
                <MenuItem key={geofenceType.id} value={geofenceType.value}>
                  <Tooltip title={geofenceType.value}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {geofenceType.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/*Event Trigger Option*/}
        {!!ebtFilter?.listenerType &&
          (ebtFilter?.listenerType?.id !== 'geofence' ||
            (ebtFilter?.listenerType?.id === 'geofence' && !!ebtFilter?.geofenceType)) && (
            <FormControl
              fullWidth
              style={{
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="entered-option">Event Trigger Option</InputLabel>
              <Select
                fullWidth
                labelId="entered-action"
                id="entered-dropdown"
                value={enteredState ?? ''}
                label="Event Trigger Option"
                onChange={e => {
                  const enteredType = EnteredTypes.find(
                    enter => enter.value === (e.target.value as EnteredTypeValue),
                  );
                  setEnteredState(enteredType?.value);
                  if (enteredType?.value === 'Dwell') {
                    setDwellTimeMinimum(0);
                    return;
                  }

                  setEbtFilter({
                    ...ebtFilter,
                    entered: enteredType?.booleanValue,
                  });
                }}
              >
                {ebtFilter?.geofenceType?.id === 'line' &&
                  EnteredTypes.filter(
                    e =>
                      e.listenerTypeId === ebtFilter?.listenerType?.id &&
                      e.geofenceTypeId === ebtFilter?.geofenceType?.id,
                  ).map(entered => (
                    <MenuItem key={entered.id} value={entered.value}>
                      <Tooltip title={entered.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {entered.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                {ebtFilter?.geofenceType?.id !== 'line' &&
                  EnteredTypes.filter(
                    e => e.listenerTypeId === ebtFilter?.listenerType?.id && !e.geofenceTypeId,
                  ).map(entered => (
                    <MenuItem key={entered.id} value={entered.value}>
                      <Tooltip title={entered.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {entered.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

        {/*Minimum dwellSeconds threshold */}
        {!!ebtFilter?.listenerType &&
          (ebtFilter?.listenerType?.id !== 'geofence' ||
            (ebtFilter?.listenerType?.id === 'geofence' && !!ebtFilter?.geofenceType)) &&
          enteredState === 'Dwell' && (
            <FormControl
              style={{
                marginTop: '-8px',
              }}
            >
              <InputContainer
                id="dwellTimeMinimum"
                label="Minimum dwell time (Seconds)"
                key={'dwellTimeMinimum'}
                name={'dwellTimeMinimum'}
                type={'number'}
                value={String(dwellTimeMinimum ? dwellTimeMinimum : 0)}
                onChange={(e: { target: { value: string } }) => {
                  const dwellSeconds = Number(e.target.value);
                  setDwellTimeMinimum(dwellSeconds < 0 ? 0 : dwellSeconds);
                  setEbtFilter({
                    ...ebtFilter,
                    dwellSeconds,
                  });
                }}
                placeholder="60"
              />
            </FormControl>
          )}

        {/*Method Type*/}
        {(ebtFilter?.notifierType === undefined || ebtFilter?.notifierType?.id === 'WEBHOOK') && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputLabel id="method-type-option">Method Type</InputLabel>
            <Select
              fullWidth
              labelId="method-type-action"
              id="method-type-dropdown"
              value={ebtFilter?.method ?? ''}
              label="Method Type"
              onChange={e => {
                setEbtFilter({
                  ...ebtFilter,
                  method: e.target.value as MethodTypeId,
                });
              }}
            >
              {['GET', 'POST'].map(method => (
                <MenuItem key={method} value={method}>
                  <Tooltip title={method}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {method}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>

      {/*Search*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 25px 0px 25px',
        }}
      >
        <Header icon={<Search />}>Search</Header>
      </div>
      <div
        style={{
          width: '300px',
          alignSelf: 'start',
          margin: '0px 0px 25px 25px',
        }}
      >
        Search Keywords:
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Search By*/}
        {(ebtFilter?.notifierType === undefined || ebtFilter?.notifierType?.id === 'EMAIL') && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="recipient"
              label="Recipient"
              key={'recipient'}
              name={'recipient'}
              value={ebtFilter?.recipient ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setEbtFilter({
                  ...ebtFilter,
                  recipient: e.target.value,
                });
              }}
              placeholder="abc@abc.com"
            />
          </FormControl>
        )}

        {(ebtFilter?.notifierType === undefined || ebtFilter?.notifierType?.id === 'EMAIL') && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="subject"
              label="Subject"
              key={'subject'}
              name={'subject'}
              value={ebtFilter?.subject ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setEbtFilter({
                  ...ebtFilter,
                  subject: e.target.value,
                });
              }}
            />
          </FormControl>
        )}

        {(ebtFilter?.notifierType === undefined || ebtFilter?.notifierType?.id === 'EMAIL') && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="text"
              label="Text"
              key={'text'}
              name={'text'}
              value={ebtFilter?.text ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setEbtFilter({
                  ...ebtFilter,
                  text: e.target.value,
                });
              }}
            />
          </FormControl>
        )}

        {(ebtFilter?.notifierType === undefined || ebtFilter?.notifierType?.id === 'EMAIL') && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="url"
              label="URL"
              key={'url'}
              name={'url'}
              value={ebtFilter?.url ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setEbtFilter({
                  ...ebtFilter,
                  url: e.target.value,
                });
              }}
              placeholder={'https://www.example.com.au/'}
            />
          </FormControl>
        )}
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '0px 0px 5px 10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Sort By*/}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="sort-option">Sort By</InputLabel>
          <Select
            fullWidth
            labelId="sort-action"
            id="sort-dropdown"
            value={ebtFilter?.orderBy?.value ?? ''}
            label="Sort By"
            onChange={e => {
              const orderBy = OrderByTypes.find(
                l => l.value === (e.target.value as OrderByTypeValue),
              );
              setEbtFilter({
                ...ebtFilter,
                orderBy,
              });
            }}
          >
            {(ebtFilter?.notifierType
              ? OrderByTypes.filter(
                  o => o.parentTypeId === ebtFilter?.notifierType?.id || !o.parentTypeId,
                )
              : OrderByTypes
            ).map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Order*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={ebtFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              setEbtFilter({
                ...ebtFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          alignSelf: 'end',
          margin: '25px',
          width: 'fit-content',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            setEbtFilter(undefined);
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => setOpenFilterDialog(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            setEbts([]);
            setPage(1);
            setRefresh(true);
            setOpenFilterDialog(false);
          }}
        >
          Search
        </Button>
      </Grid>
    </>
  );
};
