import { NearMe, Sensors } from '@mui/icons-material';
import { SubHeader } from '../../../../Common/Sidebar';
import { truncationStyle } from '../../AssetRenderer';
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { MicrofenceData, SensedByDevice } from '../../Messages';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../../../store/tools';
import { Dispatch } from 'react';
import { Asset, PortableAssetTool } from '../../types';
import { Option, fromNullable } from 'fp-ts/es6/Option';
import { GridRowData } from '@material-ui/data-grid';
import { StrongFeatureHolder } from '../../../../hooks/geomoby/useLiveMapLoader';
import { Point } from 'ol/geom';
import { SelectedAsset } from '../../LiveMap';
import { MICROFENCE } from '../../BeaconUtils';
import { AssetEntity } from '../../../../util/enums';

export const BeaconLatestLocation = ({
  sensedBeacon,
  microfences,
  activeAssets,
  setSelectedDevice,
  setSelectedGPSTracker,
  setSelectedTool,
  setSelectedBeacon,
  setSelectedMicrofence,
  setSelectedAsset,
}: {
  sensedBeacon: SensedByDevice | undefined;
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
  activeAssets: SelectedAsset[];
  setSelectedDevice: Dispatch<Asset | undefined>;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  setSelectedBeacon: Dispatch<PortableAssetTool | undefined>;
  setSelectedAsset: Dispatch<Option<SelectedAsset>>;
}) => {
  const activeDevice = activeAssets.find(
    active =>
      active.prefix !== MICROFENCE &&
      JSON.stringify(active.id) === JSON.stringify(sensedBeacon?.source?.id),
  );
  const activeMicrofence = activeAssets.find(
    active =>
      active.prefix === MICROFENCE &&
      JSON.stringify(active.id) === JSON.stringify(sensedBeacon?.source?.id),
  );
  const foundMicrofence = microfences.find(
    microfence => JSON.stringify(microfence.data.assetId) === JSON.stringify(activeMicrofence?.id),
  );

  return (
    <Paper
      style={{
        marginTop: '10px',
      }}
    >
      <Box p={2} overflow="auto">
        <SubHeader icon={<Sensors />} timestamp={sensedBeacon?.timestamp}>
          Sensed By
        </SubHeader>

        {sensedBeacon && (
          <div style={{ marginLeft: '1rem' }}>
            <Grid
              container
              direction="row"
              style={{
                display: 'grid',
                gridTemplateColumns: '90% 10%',
                width: '100%',
              }}
            >
              <Tooltip title={sensedBeacon.source?.label}>
                <pre style={truncationStyle}>
                  {foundMicrofence
                    ? foundMicrofence.feature.get('type')
                    : sensedBeacon.source?.id?.gpsTrackerId
                    ? 'tracker'
                    : 'device'}
                  : {sensedBeacon.source?.label}
                </pre>
              </Tooltip>

              <Grid
                item
                style={{
                  marginTop: '-7px',
                  width: 'fit-content',
                }}
              >
                <Tooltip title={'NAVIGATE TO'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (foundMicrofence) {
                        setSelectedMicrofence(foundMicrofence.feature.getProperties());
                        setSelectedAsset(fromNullable(activeMicrofence));
                      } else {
                        if (sensedBeacon.source?.id?.gpsTrackerId) {
                          setSelectedGPSTracker({
                            id: sensedBeacon.source?.id?.gpsTrackerId as string,
                            label: sensedBeacon.source?.label ?? '',
                            type: AssetEntity.GPSTracker,
                          });
                        } else {
                          setSelectedDevice({
                            id: sensedBeacon.source?.id?.deviceId as string,
                            label: sensedBeacon.source?.label ?? '',
                            type: AssetEntity.Device,
                          });
                        }
                        setSelectedAsset(fromNullable(activeDevice));
                      }
                      setSelectedTool(undefined);
                      setSelectedBeacon(undefined);
                    }}
                  >
                    <NearMe />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        )}

        {!((foundMicrofence || activeDevice) && sensedBeacon) && <Typography>UNKNOWN</Typography>}
      </Box>
    </Paper>
  );
};
