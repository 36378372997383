import React, { ReactNode } from 'react';

export interface ApiBase {
  ws: string;
  xhr: string;
}

const initial: ApiBase = {
  ws: process.env.REACT_APP_LTP_WS ?? 'wss://live.geomoby.com',
  xhr: process.env.REACT_APP_LTP_API ?? 'https://live.geomoby.com',
};

export const ApiBaseContext = React.createContext<ApiBase>(initial);

export type Props = {
  children: ReactNode;
  ws: string;
  xhr: string;
};
export const ApiBaseProvider = ({ children, ws, xhr }: Props) => {
  return (
    <ApiBaseContext.Provider
      value={{
        ws: ws,
        xhr: xhr,
      }}
    >
      {children}
    </ApiBaseContext.Provider>
  );
};

export const useApiBase = () => React.useContext(ApiBaseContext);
