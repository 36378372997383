import { useAtomValue } from 'jotai';
import React, { ReactNode } from 'react';
import { BrowserRouter, Navigate, Route, Routes as RouteGroup } from 'react-router-dom';
import { AcceptInvite } from '../../Authn/AcceptInvite';
import { ProjectSelection } from '../../Authn/ProjectSelection';
import SignIn from '../../Authn/SignIn';
import { IS_SIGNED_IN } from '../../store/auth';
import { LIVE_LATEST_EVENTS, LIVE_LATEST_NOW } from '../../store/liveEvents';
import { REPLAY_LATEST_EVENTS, REPLAY_START } from '../../store/replayEvents';
import { SUPER_ADMIN } from '../../store/user';
import { ExpireSessionOn401 } from '../../util/ExpireSessionOn401';
import { ExpireSessionOnInactivity } from '../../util/ExpireSessionOnInactivity';
import { LatestEventsProvider } from '../../util/LatestEventsProvider';
import { LiveEventsProvider } from '../../util/LiveEventsProvider';
import { ReplayEventsProvider } from '../../util/ReplayEventsProvider';
import Events from '../Events/Events';
import { LiveMap } from '../Map/LiveMap';
import { ReplayMap } from '../Map/Replay/ReplayMap';
import { AnalDashboard } from '../Reports/AnalDashboard';
import { SuperAdmin } from '../SuperAdmin/SuperAdmin';
import { NavigationTabs } from './NavigationTabs';
import { Notifications } from '../../Notifications/Notifications';
import { KnownToolsProvider } from '../../util/ToolLabelsProvider';
import { AccountRecovery } from '../../Authn/AccountRecovery';
import { UserAccount } from '../UserAccount/UserAccount';
import Tools from '../Tools/Tools';
import GeofenceEditor from '../Map/GeofenceEditor/GeofenceEditor';
import { ServiceConfigProvider } from '../../util/ServiceConfigProvider';
import { EventHeatMap } from '../HeatMap/EventHeatMap';
import { ProjectConfig } from '../ProjectConfig/ProjectConfig';

const Authed = ({ children, mapType }: { children: ReactNode; mapType?: 'live' | 'replay' }) => (
  <ExpireSessionOnInactivity>
    <ExpireSessionOn401>
      <ServiceConfigProvider>
        <KnownToolsProvider>
          <LiveEventsProvider>
            <NavigationTabs />
            <Notifications map={mapType} />
            {children}
          </LiveEventsProvider>
        </KnownToolsProvider>
      </ServiceConfigProvider>
    </ExpireSessionOn401>
  </ExpireSessionOnInactivity>
);

export const Routes = () => (
  <BrowserRouter>
    <RouteGroup>
      <Route path="/accept-invite" element={<AcceptInvite />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/account-recovery" element={<AccountRecovery />} />
      <Route path="/project-selection" element={<ProjectSelection />} />
      <Route
        path="/my-account"
        element={
          <ExpireSessionOnInactivity>
            <ExpireSessionOn401>
              <NavigationTabs />
              <UserAccount />
            </ExpireSessionOn401>
          </ExpireSessionOnInactivity>
        }
      />
      <Route
        path="/project-config"
        element={
          <ExpireSessionOnInactivity>
            <ExpireSessionOn401>
              <NavigationTabs />
              <Notifications map={undefined} />
              <ProjectConfig />
            </ExpireSessionOn401>
          </ExpireSessionOnInactivity>
        }
      />
      <Route
        path="/super-admin"
        element={
          <ExpireSessionOnInactivity>
            <ExpireSessionOn401>
              <NavigationTabs />
              <SuperAdmin />
            </ExpireSessionOn401>
          </ExpireSessionOnInactivity>
        }
      />
      {[
        {
          path: 'tools-editor',
          element: (
            <LatestEventsProvider
              hoursAgo={2}
              latestNowAtom={LIVE_LATEST_NOW}
              latestEventsAtom={LIVE_LATEST_EVENTS}
            >
              <Tools />
            </LatestEventsProvider>
          ),
        },
        {
          path: 'geofence-editor',
          element: <GeofenceEditor />,
        },
        {
          path: 'events',
          element: <Events />,
        },
        {
          path: 'live-map',
          mapType: 'live' as const,
          element: (
            <LatestEventsProvider
              hoursAgo={2}
              latestNowAtom={LIVE_LATEST_NOW}
              latestEventsAtom={LIVE_LATEST_EVENTS}
            >
              <LiveMap />
            </LatestEventsProvider>
          ),
        },
        {
          path: 'replay-map',
          mapType: 'replay' as const,
          element: (
            <ReplayEventsProvider>
              <LatestEventsProvider
                hoursAgo={0} // Map should be empty prior to starting replay
                latestNowAtom={REPLAY_START}
                latestEventsAtom={REPLAY_LATEST_EVENTS}
              >
                <ReplayMap />
              </LatestEventsProvider>
            </ReplayEventsProvider>
          ),
        },
        {
          path: 'event-heat-map',
          element: <EventHeatMap />,
        },
        {
          path: 'contact-tracing',
          element: <></>,
        },
        {
          path: 'productivity-reports',
          element: <AnalDashboard />,
        },
        {
          path: 'health-safety-reports',
          element: <AnalDashboard />,
        },
      ].map(({ path, element, mapType }) => (
        <Route
          key={path}
          path={`/${path}`}
          element={<Authed mapType={mapType}>{element}</Authed>}
        />
      ))}
      <Route
        path="*"
        element={
          useAtomValue(IS_SIGNED_IN) ? (
            <Navigate to="/project-selection" />
          ) : (
            <Navigate to="/sign-in" />
          )
        }
      />
    </RouteGroup>
  </BrowserRouter>
);
