import { addDays, addHours, setMinutes, setSeconds } from 'date-fns';
import { atom } from 'jotai';
import { CMContainer } from '../Components/Map/Messages';

export const REPLAY_LATEST_EVENTS = atom<CMContainer[]>([]);

const DEFAULT_REPLAY_START = setMinutes(addHours(new Date(), -2), 0);
export const REPLAY_START = atom(DEFAULT_REPLAY_START);
export const REPLAY_END = atom(get => setSeconds(addDays(get(REPLAY_START), 1), 0));
export const REPLAY_NOW = atom(DEFAULT_REPLAY_START);
export const REPLAY_PLAYING = atom(false);
export const REPLAY_SECONDS_PER_SECOND = atom(8);

export const REPLAY_MESSAGES = atom<CMContainer[]>([]);
export const NEW_REPLAY_MESSAGES = atom<CMContainer[]>([]);
