import {
  Box,
  Button,
  debounce,
  Grid,
  Input,
  OutlinedInput,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Feature } from 'ol';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import axios from 'axios';
import { transform } from 'ol/proj';
import { getCoordinateDifference } from '../../../hooks/geomoby/MapAnimation';
import { Clear, Search } from '@mui/icons-material';
import { ZIndexes } from '../../../util/ZIndexes';
import { Toolbar } from './Toolbar';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import { Fill, Style } from 'ol/style';
import { Geometry, Point } from 'ol/geom';

export const MAX_SUGGESTION_SIZE = 5;

export type GeocodeData = {
  geometry: {
    coordinates: number[];
  };
  properties: {
    country_a: string;
    label: string;
    name: string;
    street?: string;
  };
};

export type LocationSearchData = { coords: number[] } & (
  | {
      address: string;
      isStreetAddress: boolean;
      isLastKnownLocation?: never;
    }
  | {
      isLastKnownLocation: boolean;
      address?: never;
      isStreetAddress?: never;
    }
);

export type LocationDisplayType = {
  label: string;
  coordinates: number[];
};

export interface AssetLocation {
  lat: number;
  lon: number;
  timestamp: string;
}

export type Location = 'LOCATION' | 'ASSET';
export type SearchType = { key: number; value: string; label: string };

export const searchTypes: SearchType[] = [
  { key: 1, value: 'LOCATION', label: 'Location' },
  { key: 2, value: 'DEVICE', label: 'Device' },
  { key: 3, value: 'BEACON', label: 'Beacon' },
  { key: 4, value: 'GPSTRACKER', label: 'GPS Tracker' },
];

export const LocationSearch = ({
  isGeofenceEditor,
  setLocationSearchData,
  currentCenter,
  setLocationDisplay,
}: {
  isGeofenceEditor: boolean;
  setLocationSearchData: Dispatch<SetStateAction<LocationSearchData | undefined>>;
  currentCenter: number[] | undefined;
  setLocationDisplay: Dispatch<SetStateAction<LocationDisplayType | undefined>>;
}) => {
  const [addressHover, setAddressHover] = useState<string>('');
  const [coordinates, setCoordinates] = useState<number[]>([]);
  const [coordinatesWithInRange, setCoordinatesWithInRange] = useState<boolean>(true);
  const [disableAutoComplete, setDisableAutoComplete] = useState<boolean>(false);
  const [disableProximitySearch, setDisableProximitySearch] = useState<boolean>(false);
  const [disableSearchBar, setDisableSearchBar] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsIndex, setSuggestionsIndex] = useState<number>(-1);
  const [showPreviousSearches, setShowPreviousSearches] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [unknownLocation, setUnknownLocation] = useState<boolean>(false);

  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(TRIGGERS_URL);

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const addressInputRef = useRef<HTMLFormElement>(null);
  const animatingRef = useRef<boolean>(false);
  const coordsRef = useRef<number[]>([]);
  const countryCode = useRef<string>('AUS');
  const previousSearchesRef = useRef<string[]>([]);
  const suggestionsIndexRef = useRef<number>(-1);

  const dropDownNavigation = useCallback(
    e => {
      if (e.key === 'ArrowUp' && suggestionsIndexRef.current > -1) {
        suggestionsIndexRef.current--;
        setSuggestionsIndex(suggestionsIndexRef.current);
        setAddressHover('');
      } else if (
        e.key === 'ArrowDown' &&
        suggestionsIndexRef.current <
          Math.min(
            (suggestions.length > 0
              ? suggestions.length
              : new Set(previousSearchesRef.current).size) - 1,
            MAX_SUGGESTION_SIZE - 1,
          )
      ) {
        suggestionsIndexRef.current++;
        setSuggestionsIndex(suggestionsIndexRef.current);
        setAddressHover('');
      }
    },
    [suggestions],
  );

  useEffect(() => {
    disableSearchBar
      ? document.removeEventListener('keydown', dropDownNavigation, true)
      : document.addEventListener('keydown', dropDownNavigation, true);
    return () => document.removeEventListener('keydown', dropDownNavigation, true);
  }, [disableSearchBar, dropDownNavigation]);

  const refresh = () => {
    setUnknownLocation(false);
    setShowSuggestions(false);
    setShowPreviousSearches(false);
  };

  const getCoordinates = (): [number, number] | undefined => {
    if (!addressInputRef.current?.value) return undefined;

    const coords = addressInputRef.current?.value.split(',');
    if (coords.length !== 2) {
      return undefined;
    }

    const lat = Number(coords[0].trim());
    const lon = Number(coords[1].trim());
    if (
      addressInputRef.current?.value.split(',').length === 2 &&
      !Number.isNaN(lat) &&
      !Number.isNaN(lon)
    ) {
      return [lon, lat];
    }
    return undefined;
  };

  const searchCoordinates = async (coords: [number, number]) => {
    setShowSuggestions(false);

    if (coords[1] >= -90 && coords[1] <= 90 && coords[0] >= -180 && coords[0] <= 180) {
      coordsRef.current = transform(coords, 'EPSG:4326', 'EPSG:3857');
      setCoordinates(coordsRef.current);
      try {
        await axios
          .get<GeocodeData[]>(
            `${httpBaseUrl}/${cid}/${pid}/geocode/reverse?lon=${coords[0]}&lat=${coords[1]}`,
            authedConfig,
          )
          .then(r => {
            const label = r.data.length > 0 ? r.data[0]?.properties?.label : 'UNKNOWN LOCATION';
            setLocationSearchData({
              coords: coordsRef.current,
              isLastKnownLocation: false,
            });
            setLocationDisplay({
              label,
              coordinates: coords.reverse(),
            });
          });
      } catch (error: unknown) {
        console.error(error);
        setDisableProximitySearch(true);
      }
    } else {
      setCoordinatesWithInRange(false);
    }
    setShowSuggestions(false);
  };

  const searchAddress = () => {
    axios
      .get<GeocodeData[]>(
        `${httpBaseUrl}/${cid}/${pid}/geocode/search?address=${addressInputRef.current?.value}`,
        authedConfig,
      )
      .then(r => {
        previousSearchesRef.current.unshift(addressInputRef.current?.value);
        previousSearchesRef.current = previousSearchesRef.current.slice(0, MAX_SUGGESTION_SIZE);
        const found = r.data.findIndex(
          l =>
            l.properties.name?.toLowerCase() === addressInputRef.current?.value?.toLowerCase() ||
            l.properties.label?.toLowerCase() === addressInputRef.current?.value?.toLowerCase(),
        );
        if (found !== -1) {
          const data = r.data[found];
          if (data.properties) {
            countryCode.current = data.properties.country_a ?? countryCode.current;
          }
          const label = data?.properties?.label;
          coordsRef.current = transform(data.geometry?.coordinates, 'EPSG:4326', 'EPSG:3857');
          setCoordinates(coordsRef.current);

          setLocationDisplay({
            label,
            coordinates: data.geometry?.coordinates.reverse() ?? 'UNKNOWN COORDINATES',
          });

          setLocationSearchData({
            coords: coordsRef.current,
            address: label,
            isStreetAddress: !!data.properties.street,
          });
          setShowSuggestions(false);
        } else {
          setUnknownLocation(true);
        }
        setSuggestions([]);
        suggestionsIndexRef.current = -1;
        setSuggestionsIndex(-1);
      })
      .catch((error: unknown) => {
        console.error(error);
        setDisableSearchBar(true);
        changeSearch();
      });
  };

  const search = (): void => {
    refresh();
    if (!addressInputRef.current?.value && !disableSearchBar) {
      return;
    }
    coordsRef.current = [];
    setCoordinatesWithInRange(true);

    const coords = getCoordinates();
    coords ? searchCoordinates(coords) : searchAddress();
  };

  const getSuggestions = debounce(async () => {
    setShowSuggestions(false);
    if (!addressInputRef.current?.value) {
      setShowPreviousSearches(true);
      return;
    }

    setSuggestions([]);
    setShowPreviousSearches(false);

    if (currentCenter?.length !== 2) return;
    const convertedCoors = transform(currentCenter, 'EPSG:3857', 'EPSG:4326');
    if (!disableProximitySearch) {
      try {
        await axios
          .get<GeocodeData[]>(
            `${httpBaseUrl}/${cid}/${pid}/geocode/reverse?lon=${convertedCoors[0]}&lat=${convertedCoors[1]}`,
            authedConfig,
          )
          .then(r => {
            if (r.data?.length > 0 && r.data[0]?.properties) {
              countryCode.current = r.data[0].properties.country_a ?? countryCode.current;
            }
          });
      } catch (error: unknown) {
        console.error(error);
        setDisableProximitySearch(true);
      }
    }

    // This end-point specifically asks for a 'street' address. So we want to remove any number or number letter combination from the beginning eg. 1, 1A, 1/5.
    let address = addressInputRef.current.value;
    const index = address.indexOf(' ');
    const firstWord = address.slice(0, index);
    if (firstWord && /\d/.test(firstWord) && index > -1) {
      address = address.slice(index + 1);
    }

    if (!address || disableAutoComplete) {
      return;
    }

    await axios
      .get<GeocodeData[]>(
        `${httpBaseUrl}/${cid}/${pid}/geocode/autocomplete?address=${address}&lon=${
          convertedCoors[1]
        }&lat=${convertedCoors[0]}${'&countryCode=' + countryCode.current ?? 'AUS'}`,
        authedConfig,
      )
      .then(r => {
        if (r.data?.length > 0) {
          setSuggestions(Array.from(new Set(r.data.map(location => location.properties.label))));
          setShowSuggestions(true);
        }
      })
      .catch((error: unknown) => {
        console.error(error);
        setDisableAutoComplete(true);
      });
  }, 500);

  const changeSearch = (newAddress?: string): void => {
    suggestionsIndexRef.current = -1;
    setSuggestionsIndex(-1);
    setAddressHover('');
    setLocationDisplay(undefined);
    setLocationSearchData(undefined);

    if (addressInputRef.current?.value !== undefined) {
      addressInputRef.current.value = newAddress ?? '';
      search();
    }
    refresh();
  };

  return (
    <>
      {
        <Toolbar
          top={isGeofenceEditor ? '1%' : '25%'}
          left={isGeofenceEditor ? 8 : -17}
          width={isGeofenceEditor ? '500px' : '90%'}
          minWidth={isGeofenceEditor ? '500px' : '0px'}
          height={80}
          flexDirection={'column'}
          position={isGeofenceEditor ? 'absolute' : 'relative'}
          zIndex={3}
        >
          <Grid
            container
            direction={'row'}
            justifyContent={'left'}
            style={{
              display: 'grid',
              gridTemplateColumns: isGeofenceEditor ? '250px 100px' : '90% 10%',
            }}
          >
            <Grid item>
              <OutlinedInput
                inputRef={addressInputRef}
                placeholder={`${
                  isGeofenceEditor ? 'Search' : ''
                } Street Address or GPS Coordinates {lat,lng}`}
                disabled={disableSearchBar}
                style={{
                  width: isGeofenceEditor ? '370px' : '100%',
                  minWidth: isGeofenceEditor ? '370px' : '160px',
                  fontSize: '13px',
                }}
                onChange={getSuggestions}
                onFocus={() => {
                  setShowSuggestions(!!addressInputRef.current?.value);
                  setShowPreviousSearches(!addressInputRef.current?.value);
                }}
                onBlur={() => {
                  if (!addressHover) {
                    refresh();
                  }
                }}
                onKeyPress={event => {
                  if (!disableSearchBar && event.key === 'Enter') {
                    refresh();
                    if (suggestionsIndexRef.current > -1) {
                      if (suggestions.length > 0) {
                        changeSearch(suggestions[suggestionsIndexRef.current]);
                      } else if (new Set(previousSearchesRef.current).size > 0) {
                        changeSearch(
                          Array.from(new Set(previousSearchesRef.current))[
                            suggestionsIndexRef.current
                          ],
                        );
                      }
                      search();
                    } else {
                      changeSearch(addressInputRef.current?.value);
                    }
                  }
                }}
              />
            </Grid>
            <Grid
              container
              justifyContent="end"
              style={{
                display: 'grid',
                gridTemplateColumns: '40px 40px',
                width: isGeofenceEditor ? '200px' : '10%',
                minWidth: isGeofenceEditor ? '200px' : '80px',
              }}
            >
              <Button
                size="small"
                disabled={disableSearchBar}
                onClick={search}
                style={{
                  background: '#23272D',
                  color: '#4CB8C4',
                  width: 'fit-content',
                }}
              >
                <Search />
              </Button>
              <Button
                size="small"
                disabled={disableSearchBar}
                style={{
                  background: '#23272D',
                  color: '#4CB8C4',
                  width: 'fit-content',
                }}
                onClick={() => {
                  changeSearch();
                }}
              >
                <Clear />
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction={'column'}
            justifyContent="left"
            style={{
              alignSelf: 'start',
              width: 'calc(100% + 32px)',
              margin: '12px 0px 0px -16px',
            }}
          >
            {(disableSearchBar ||
              !coordinatesWithInRange ||
              !((showSuggestions || showPreviousSearches) && !disableSearchBar)) && (
              <Box bgcolor="background.default">
                <Grid
                  paddingY={0.25}
                  paddingX={2}
                  color={'#4CB8C4'}
                  sx={{
                    '& .MuiTypography-root': {
                      whiteSpace: disableSearchBar ? 'pre' : 'nowrap',
                      overflow: 'hidden',
                      textOverflow: disableSearchBar ? 'initial' : 'ellipsis',
                    },
                  }}
                >
                  {disableSearchBar
                    ? 'Search limit breached.\nSearch bar disabled temporarily.'
                    : !coordinatesWithInRange
                    ? 'Coordinates not within range'
                    : addressInputRef.current?.value && unknownLocation
                    ? `Can't find ${addressInputRef.current?.value}`
                    : ''}
                </Grid>
              </Box>
            )}
            {(showSuggestions || showPreviousSearches) && !disableSearchBar && (
              <Box
                bgcolor="background.default"
                style={{
                  maxWidth: '500px',
                }}
              >
                {Array.from(
                  new Set(
                    showSuggestions
                      ? suggestions
                      : showPreviousSearches
                      ? previousSearchesRef.current
                      : [],
                  ),
                )
                  .slice(0, MAX_SUGGESTION_SIZE)
                  .map((address, index) => {
                    return (
                      <Tooltip title={address} key={address}>
                        <Grid
                          key={address}
                          paddingTop={0}
                          paddingBottom={0.5}
                          paddingX={2}
                          color={'#4CB8C4'}
                          style={{ cursor: 'pointer' }}
                          sx={{
                            '& .MuiTypography-root': {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          onMouseEnter={() => {
                            suggestionsIndexRef.current = index;
                            setSuggestionsIndex(index);
                            setAddressHover(address);
                          }}
                          onMouseLeave={() => {
                            setAddressHover('');
                          }}
                          onClick={() => {
                            changeSearch(address);
                          }}
                        >
                          <Typography
                            key={address}
                            style={{
                              background:
                                addressHover === address ||
                                (index === suggestionsIndex && suggestionsIndexRef.current > -1)
                                  ? '#363b45'
                                  : '#23272D',
                            }}
                          >
                            {address}
                          </Typography>
                        </Grid>
                      </Tooltip>
                    );
                  })}
              </Box>
            )}
          </Grid>
        </Toolbar>
      }
    </>
  );
};
