import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleEmail {
  className?: string;
}

const IconSingleEmail: React.FC<IconSingleEmail> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 497 349" className={className}>
      <path d="M42.2412 0.147461L248.496 144.61L454.751 0.147461H42.2412Z" fill="#F2F2F2" />
      <path
        d="M260.925 194.428C253.47 199.41 241.045 199.41 233.59 194.428L0 30.0405V323.946C0 336.4 12.425 348.853 24.85 348.853H472.15C484.575 348.853 497 336.4 497 323.946V30.0405L260.925 194.428Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleEmail;
