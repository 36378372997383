import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
  LIVE_NOTIFICATIONS,
  Notification,
  NOTIFICATIONS_GROUPED_BY_DAY,
  REPLAY_NOTIFICATIONS,
  REPLAY_NOTIFICATIONS_GROUPED_BY_DAY,
  SEEN_NOTIFICATIONS,
  SEEN_REPLAY_NOTIFICATIONS,
} from '../store/notifications';
import { dayLabelTitleOrFormat } from '../util/dayLabel';
import { useNotificationAction } from './useNotificationAction';
import { History, PlaylistRemove } from '@mui/icons-material';

const NotificationNode = ({
  datetime,
  label,
  seen,
  interaction,
  map,
  replayed,
  notificationId,
  setSeen,
}: Notification & { map?: 'live' | 'replay'; setSeen: (id: string) => void }) => {
  const [action, tooltip] = useNotificationAction(interaction, map);

  return (
    <Tooltip title={tooltip ?? ''}>
      <Alert
        severity={replayed ? 'warning' : 'error'}
        icon={replayed ? <History fontSize="inherit" /> : undefined}
        variant={seen ? 'outlined' : 'filled'}
        style={{ cursor: action ? 'pointer' : 'auto' }}
        onClick={() => {
          if (action) {
            action();
          }
          if (!seen) {
            setSeen(notificationId);
          }
        }}
        onClose={
          seen
            ? undefined
            : e => {
                setSeen(notificationId);
                e.stopPropagation();
              }
        }
        slotProps={{
          closeButton: { title: 'Mark read' },
        }}
      >
        {format(datetime, 'h:mmaaa')}, {label}
      </Alert>
    </Tooltip>
  );
};

export const NotificationPanel = ({ map }: { map?: 'live' | 'replay' }) => {
  const notificationsAtom = map === 'replay' ? REPLAY_NOTIFICATIONS : LIVE_NOTIFICATIONS;
  const groupedByDayAtom =
    map === 'replay' ? REPLAY_NOTIFICATIONS_GROUPED_BY_DAY : NOTIFICATIONS_GROUPED_BY_DAY;
  const notifications = useAtomValue(groupedByDayAtom);
  const liveNotifications = useAtomValue(notificationsAtom);
  const setSeen = useSetAtom(SEEN_NOTIFICATIONS);
  const setReplaySeen = useSetAtom(SEEN_REPLAY_NOTIFICATIONS);

  const setAllSeen = useCallback(() => {
    setSeen(seens => ({
      ...seens,
      ...Object.fromEntries(liveNotifications.map(({ notificationId }) => [notificationId, true])),
    }));
    if (map === 'replay') {
      setReplaySeen(seens => ({
        ...seens,
        ...Object.fromEntries(
          liveNotifications.map(({ notificationId }) => [notificationId, true]),
        ),
      }));
    }
  }, [liveNotifications, setSeen, setReplaySeen, map]);

  const setOneSeen = useCallback(
    (notificationId: string) => {
      setSeen(seens => ({
        ...seens,
        [notificationId]: true,
      }));
      if (map === 'replay') {
        setReplaySeen(seens => ({
          ...seens,
          [notificationId]: true,
        }));
      }
    },
    [setSeen, setReplaySeen, map],
  );

  const unseenCount = notifications.flatMap(n => n).filter(n => !n.seen).length;

  return (
    <Card>
      <CardHeader title="Notifications" />
      <CardContent sx={{ maxHeight: '50vh', overflowY: 'auto', paddingTop: 0 }}>
        <Stack padding={0} spacing={2} width={400}>
          {notifications.length === 0 && (
            <Stack>
              <Typography>No notifications.</Typography>
            </Stack>
          )}
          {notifications.map(groupedNotifications => {
            const day = groupedNotifications[0].datetime;
            return (
              <Stack key={day.getTime()} spacing={1}>
                <Typography>{dayLabelTitleOrFormat(day, 'LLLL do, RRRR')}</Typography>
                {groupedNotifications.map(props => (
                  <NotificationNode
                    {...props}
                    key={`${props.datetime}${props.label}`}
                    map={map}
                    setSeen={setOneSeen}
                  />
                ))}
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
      {notifications.length > 0 && (
        <CardActions>
          <Button
            size="small"
            color="primary"
            endIcon={<PlaylistRemove />}
            disabled={unseenCount === 0}
            onClick={setAllSeen}
          >
            Mark all read
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
