import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleOxygen {
  className?: string;
}

const IconSingleOxygen: React.FC<IconSingleOxygen> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 484 353" className={className}>
      <path
        d="M35.6777 147.193C42.6337 118.357 60.3859 93.9651 85.6645 78.5124C89.136 76.3907 90.2299 71.8559 88.1077 68.3843C85.9858 64.9133 81.4516 63.8191 77.9796 65.9412C49.3431 83.4466 29.2333 111.075 21.3542 143.738C13.4752 176.401 18.777 210.159 36.283 238.795C38.4043 242.266 42.9394 243.361 46.4111 241.238C49.8826 239.116 50.9764 234.582 48.8542 231.11C33.4009 205.832 28.7216 176.029 35.6777 147.193V147.193Z"
        fill="#F2F2F2"
      />
      <path
        d="M66.3398 154.591C71.3189 133.95 84.0258 116.492 102.121 105.431C105.592 103.309 106.686 98.7744 104.564 95.3028C102.442 91.8313 97.9071 90.7379 94.4357 92.8597C72.9832 105.973 57.9184 126.669 52.0164 151.136C46.1144 175.603 50.0863 200.892 63.2009 222.344C65.3262 225.82 69.8622 226.906 73.329 224.787C76.8001 222.665 77.8939 218.13 75.7722 214.659C64.7102 196.565 61.3607 175.232 66.3398 154.591V154.591Z"
        fill="#F2F2F2"
      />
      <path
        d="M121.03 122.237C118.909 118.766 114.374 117.672 110.902 119.794C96.6403 128.512 86.625 142.271 82.7013 158.537C78.7776 174.803 81.4185 191.615 90.1371 205.876C92.2624 209.352 96.7984 210.439 100.265 208.319C103.737 206.197 104.83 201.662 102.708 198.191C96.0423 187.288 94.0239 174.432 97.0246 161.993C100.025 149.553 107.683 139.031 118.587 132.367C122.058 130.244 123.152 125.709 121.03 122.237V122.237Z"
        fill="#F2F2F2"
      />
      <path
        d="M265.545 63.3458C187.848 63.3458 124.631 126.563 124.631 204.246C124.631 281.922 187.848 345.176 265.545 345.176C343.263 345.176 406.474 281.952 406.474 204.246C406.474 126.55 343.235 63.3458 265.545 63.3458ZM265.545 330.079C196.164 330.079 139.728 273.667 139.728 204.248C139.728 134.867 196.162 78.4454 265.545 78.4454C334.918 78.4454 391.375 134.867 391.375 204.248C391.377 273.665 334.903 330.079 265.545 330.079ZM434.031 332.998H483.87V352.725H406.225V352.695C406.225 342.315 408.849 333.349 414.083 325.946C419.346 318.514 429.239 309.669 443.793 299.466C450.308 294.926 454.851 290.795 457.52 287.023C460.189 283.246 461.516 279.03 461.516 274.398C461.516 269.357 460.042 265.273 457.153 262.19C454.232 259.124 450.383 257.587 445.564 257.587C440.462 257.587 436.454 259.508 433.622 263.367C430.761 267.201 429.434 272.48 429.624 279.055H407.684C407.345 266.757 410.53 256.939 417.43 249.684C424.27 242.46 433.707 238.833 445.741 238.833C456.931 238.833 466.119 242.196 473.226 248.829C480.333 255.464 483.842 264.076 483.842 274.603C483.842 283.214 481.822 290.411 477.796 296.071C473.757 301.761 466.194 308.132 455.118 315.239C444.843 321.757 437.838 327.624 434.076 332.903L434.031 332.998ZM328.46 49.501C328.46 39.0987 336.926 30.6271 347.334 30.6271C357.741 30.6271 366.205 39.0987 366.205 49.501C366.205 59.9033 357.741 68.3723 347.334 68.3723C336.926 68.3723 328.46 59.9033 328.46 49.501ZM388.855 30.6122C388.855 13.9435 402.391 0.415527 419.052 0.415527C435.713 0.415527 449.246 13.9435 449.246 30.6122C449.246 47.2808 435.71 60.8238 419.052 60.8238C402.391 60.8238 388.855 47.2958 388.855 30.6122ZM444.219 113.656C444.219 126.174 434.076 136.304 421.574 136.304C409.069 136.304 398.926 126.174 398.926 113.656C398.926 101.151 409.069 91.008 421.574 91.008C434.076 91.008 444.219 101.151 444.219 113.656Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleOxygen;
