import { useAtomValue } from 'jotai';
import { HIGHLIGHT_ASSET, HIGHLIGHT_FENCE, HIGHLIGHT_MICROFENCE, MAP_STATE } from '../store/map';
import { Notification } from '../store/notifications';
import { useNavigate } from 'react-router-dom';

export const useNotificationAction = (
  interaction: Notification['interaction'],
  mapType: 'live' | 'replay' | undefined,
) => {
  const findFence = useAtomValue(HIGHLIGHT_FENCE);
  const findMicrofence = useAtomValue(HIGHLIGHT_MICROFENCE);
  const findAsset = useAtomValue(HIGHLIGHT_ASSET);
  const navigate = useNavigate();

  const mapstate = useAtomValue(MAP_STATE);

  const navigateToLiveMap = () => navigate('/live-map?noti=foo');
  const onMap = mapType ? '' : ' on live map';

  return interaction && interaction.type === 'find-fence'
    ? ([
        mapType ? () => findFence(interaction.fenceId) : navigateToLiveMap,
        `Click to highlight GeoFence with ID "${interaction.fenceId}"${onMap}`,
      ] as const)
    : interaction && interaction.type === 'find-microfence'
    ? ([
        mapType ? () => findMicrofence(interaction.microfenceId) : navigateToLiveMap,
        `Click to highlight MicroFence "${interaction.microfenceName}"${onMap}`,
      ] as const)
    : interaction && interaction.type === 'find-asset'
    ? ([
        mapType ? () => findAsset(interaction.assetId) : navigateToLiveMap,
        `Click to highlight asset with ID "${interaction.assetId}"${onMap}`,
      ] as const)
    : interaction && interaction.type === 'find-fence-event'
    ? ([
        mapType ? () => findAsset(interaction.assetId) : navigateToLiveMap,
        `Click to highlight last fence event for asset with ID "${interaction.assetId}"${onMap}`,
      ] as const)
    : [undefined, undefined];
};
