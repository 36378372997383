export const toUnixTs = (date: Date) => Math.floor(date.getTime() / 1000);

export const MICROFENCE_ACTIVE_TIME_LIMIT_MINUTES = 10;
export const microfenceIsActive = (lastSensedIso8601: string | undefined, now: Date) => {
  if (!lastSensedIso8601) return false;

  return (
    now.getTime() - new Date(lastSensedIso8601).getTime() <
    MICROFENCE_ACTIVE_TIME_LIMIT_MINUTES * 60 * 1000
  );
};
