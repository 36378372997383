import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleOxygenBelow {
  className?: string;
}

const IconSingleOxygenBelow: React.FC<IconSingleOxygenBelow> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 484 353" className={className}>
      <path
        d="M44.0483 191.514C46.9862 154.678 64.0736 121.208 92.1638 97.2677C96.0214 93.9806 96.4834 88.1879 93.1956 84.3302C89.9084 80.4732 84.1163 80.0106 80.2581 83.2984C48.4367 110.418 29.0794 148.332 25.7517 190.055C22.4241 231.777 35.5274 272.28 62.6479 304.101C65.9345 307.957 71.7277 308.421 75.5854 305.132C79.4431 301.845 79.905 296.052 76.6173 292.195C52.6768 264.105 41.1105 228.349 44.0483 191.514Z"
        fill="#F2F2F2"
      />
      <path
        d="M82.3739 194.955C84.0163 168.556 95.8276 144.388 115.633 126.904C119.433 123.55 119.793 117.75 116.439 113.951C113.085 110.151 107.285 109.791 103.485 113.145C80.0048 133.873 66.0015 162.523 64.0547 193.815C62.1078 225.108 72.4521 255.272 93.1822 278.752C96.5414 282.556 102.341 282.908 106.136 279.558C109.935 276.203 110.296 270.404 106.942 266.604C89.4562 246.8 80.7315 221.354 82.3739 194.955Z"
        fill="#F2F2F2"
      />
      <path
        d="M265.545 63.3458C187.848 63.3458 124.631 126.563 124.631 204.246C124.631 281.922 187.848 345.176 265.545 345.176C343.263 345.176 406.474 281.952 406.474 204.246C406.474 126.55 343.235 63.3458 265.545 63.3458ZM265.545 330.079C196.164 330.079 139.728 273.667 139.728 204.248C139.728 134.867 196.162 78.4454 265.545 78.4454C334.918 78.4454 391.375 134.867 391.375 204.248C391.377 273.665 334.903 330.079 265.545 330.079ZM434.031 332.998H483.87V352.725H406.225V352.695C406.225 342.315 408.849 333.349 414.083 325.946C419.346 318.514 429.239 309.669 443.793 299.466C450.308 294.926 454.851 290.795 457.52 287.023C460.189 283.246 461.516 279.03 461.516 274.398C461.516 269.357 460.042 265.273 457.153 262.19C454.232 259.124 450.383 257.587 445.564 257.587C440.462 257.587 436.454 259.508 433.622 263.367C430.761 267.201 429.434 272.48 429.624 279.055H407.684C407.345 266.757 410.53 256.939 417.43 249.684C424.27 242.46 433.707 238.833 445.741 238.833C456.931 238.833 466.119 242.196 473.226 248.829C480.333 255.464 483.842 264.076 483.842 274.603C483.842 283.214 481.822 290.411 477.796 296.071C473.757 301.761 466.194 308.132 455.118 315.239C444.843 321.757 437.838 327.624 434.076 332.903L434.031 332.998ZM328.46 49.501C328.46 39.0987 336.926 30.6271 347.334 30.6271C357.741 30.6271 366.205 39.0987 366.205 49.501C366.205 59.9033 357.741 68.3723 347.334 68.3723C336.926 68.3723 328.46 59.9033 328.46 49.501ZM388.855 30.6122C388.855 13.9435 402.391 0.415527 419.052 0.415527C435.713 0.415527 449.246 13.9435 449.246 30.6122C449.246 47.2808 435.71 60.8238 419.052 60.8238C402.391 60.8238 388.855 47.2958 388.855 30.6122ZM444.219 113.656C444.219 126.174 434.076 136.304 421.574 136.304C409.069 136.304 398.926 126.174 398.926 113.656C398.926 101.151 409.069 91.008 421.574 91.008C434.076 91.008 444.219 101.151 444.219 113.656Z"
        fill="#F2F2F2"
      />
      <path
        d="M226.869 227.949L218.364 236.516V150.929C218.364 145.998 214.395 142 209.5 142C204.605 142 200.637 145.998 200.637 150.929V236.516L192.131 227.949C188.67 224.462 183.058 224.462 179.596 227.949C176.135 231.436 176.135 237.089 179.596 240.576L203.233 264.385C204.963 266.129 207.232 267 209.5 267C211.769 267 214.037 266.129 215.767 264.385L239.404 240.575C242.865 237.088 242.865 231.435 239.404 227.948C235.943 224.461 230.33 224.461 226.869 227.949Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleOxygenBelow;
