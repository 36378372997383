import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleTemperature {
  className?: string;
}

const IconSingleTemperature: React.FC<IconSingleTemperature> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 356 356" className={className}>
      <path
        d="M275.362 52.1484C275.362 23.3938 252.034 0 223.36 0C194.687 0 171.358 23.3938 171.358 52.1484V231.998C158.085 245.609 150.558 263.936 150.558 282.992C150.558 323.249 183.217 356 223.36 356C263.504 356 296.163 323.249 296.163 282.992C296.163 263.936 288.635 245.608 275.362 231.998V52.1484ZM223.36 335.141C194.687 335.141 171.358 311.747 171.358 282.992C171.358 268.257 177.68 254.121 188.703 244.207C190.903 242.229 192.159 239.406 192.159 236.442V52.1484C192.159 34.8956 206.156 20.8594 223.36 20.8594C240.565 20.8594 254.562 34.8956 254.562 52.1484V236.442C254.562 239.406 255.818 242.229 258.018 244.207C269.041 254.121 275.362 268.257 275.362 282.992C275.362 311.747 252.035 335.141 223.36 335.141Z"
        fill="#F2F2F2"
      />
      <path
        d="M233.763 253.497V135.585C233.763 129.825 229.106 125.156 223.362 125.156C217.619 125.156 212.962 129.825 212.962 135.585V253.497C200.857 257.802 192.161 269.395 192.161 282.992C192.161 300.245 206.158 314.281 223.362 314.281C240.567 314.281 254.563 300.245 254.563 282.992C254.563 269.395 245.868 257.802 233.763 253.497ZM223.362 293.421C217.628 293.421 212.962 288.743 212.962 282.992C212.962 277.241 217.628 272.562 223.362 272.562C229.097 272.562 233.763 277.241 233.763 282.992C233.763 288.743 229.098 293.421 223.362 293.421Z"
        fill="#F2F2F2"
      />
      <path
        d="M306.563 62.5781H327.364C333.108 62.5781 337.764 57.9084 337.764 52.1484C337.764 46.3885 333.108 41.7188 327.364 41.7188H306.563C300.819 41.7188 296.163 46.3885 296.163 52.1484C296.163 57.9084 300.819 62.5781 306.563 62.5781Z"
        fill="#F2F2F2"
      />
      <path
        d="M306.563 104.296H316.963C322.707 104.296 327.364 99.6266 327.364 93.8667C327.364 88.1067 322.707 83.437 316.963 83.437H306.563C300.819 83.437 296.163 88.1067 296.163 93.8667C296.163 99.6266 300.819 104.296 306.563 104.296Z"
        fill="#F2F2F2"
      />
      <path
        d="M327.364 125.156H306.563C300.819 125.156 296.163 129.825 296.163 135.585C296.163 141.345 300.819 146.015 306.563 146.015H327.364C333.108 146.015 337.764 141.345 337.764 135.585C337.764 129.825 333.108 125.156 327.364 125.156Z"
        fill="#F2F2F2"
      />
      <path
        d="M306.563 187.733H316.963C322.707 187.733 327.364 183.063 327.364 177.303C327.364 171.543 322.707 166.874 316.963 166.874H306.563C300.819 166.874 296.163 171.543 296.163 177.303C296.163 183.063 300.819 187.733 306.563 187.733Z"
        fill="#F2F2F2"
      />
      <path
        d="M327.364 208.592H306.563C300.819 208.592 296.163 213.262 296.163 219.022C296.163 224.782 300.819 229.452 306.563 229.452H327.364C333.108 229.452 337.764 224.782 337.764 219.022C337.764 213.262 333.108 208.592 327.364 208.592Z"
        fill="#F2F2F2"
      />
      <path
        d="M67.3544 177.305V77.3281L77.3346 87.3358C79.3647 89.3731 82.0265 90.391 84.6883 90.391C87.3502 90.391 90.012 89.3731 92.0421 87.3358C96.1038 83.2626 96.1038 76.6593 92.0421 72.5861L64.3077 44.7736C60.2467 40.7005 53.6612 40.7005 49.5995 44.7736L21.8651 72.5861C17.8034 76.6593 17.8034 83.2626 21.8651 87.3358C25.9261 91.4089 32.5117 91.4089 36.5734 87.3358L46.5536 77.3281V177.305C46.5536 183.065 51.2102 187.735 56.954 187.735C62.6978 187.735 67.3544 183.065 67.3544 177.305Z"
        fill="#F2F2F2"
      />
      <path
        d="M118.934 225.555L108.953 235.562V135.585C108.953 129.825 104.297 125.156 98.5531 125.156C92.8093 125.156 88.1527 129.825 88.1527 135.585V235.562L78.1725 225.555C74.1115 221.482 67.5259 221.482 63.4642 225.555C59.4025 229.628 59.4025 236.231 63.4642 240.304L91.1986 268.117C93.2295 270.153 95.8913 271.171 98.5531 271.171C101.215 271.171 103.877 270.153 105.907 268.116L133.641 240.304C137.703 236.231 137.703 229.627 133.641 225.554C129.58 221.481 122.995 221.481 118.934 225.555V225.555Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleTemperature;
