/* eslint-disable @typescript-eslint/no-empty-interface */
import { SvgIcon } from '@mui/material';
import React from 'react';

interface IconInactivityProps {}

const IconInactivity: React.FC<IconInactivityProps> = () => {
  return (
    <SvgIcon viewBox="0 0 366 365">
      <svg width="370px" height="370px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="var(--ci-primary-color, currentColor)"
          d="M216,16A112.127,112.127,0,0,0,104,128v79.68a80.239,80.239,0,0,0,9.768,38.308l27.455,50.334L68.4,343.656A79.727,79.727,0,0,0,32,410.732V496H312V464H64V410.732a47.838,47.838,0,0,1,21.84-40.246L183.5,307.007l-41.64-76.341A48.149,48.149,0,0,1,136,207.68V128a80,80,0,0,1,160,0v79.68a48.143,48.143,0,0,1-5.861,22.985L248.5,307.007,312,348.283V310.117l-21.223-13.8,27.454-50.334A80.226,80.226,0,0,0,328,207.68V128A112.127,112.127,0,0,0,216,16Z"
          className="ci-primary"
        />
        <polygon
          fill="var(--ci-primary-color, currentColor)"
          points="483.314 355.314 460.686 332.686 412 381.372 363.314 332.686 340.686 355.314 389.372 404 340.686 452.686 363.314 475.314 412 426.628 460.686 475.314 483.314 452.686 434.628 404 483.314 355.314"
          className="ci-primary"
        />
      </svg>
    </SvgIcon>
  );
};
export default IconInactivity;
