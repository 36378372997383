import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleTemperatureBelow {
  className?: string;
}

const IconSingleTemperatureBelow: React.FC<IconSingleTemperatureBelow> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 355 356" className={className}>
      <path
        d="M274.569 52.1484C274.569 23.3938 251.241 0 222.567 0C193.894 0 170.565 23.3938 170.565 52.1484V231.998C157.292 245.609 149.765 263.936 149.765 282.992C149.765 323.249 182.424 356 222.567 356C262.711 356 295.37 323.249 295.37 282.992C295.37 263.936 287.842 245.608 274.569 231.998V52.1484ZM222.567 335.141C193.894 335.141 170.565 311.747 170.565 282.992C170.565 268.257 176.887 254.121 187.91 244.207C190.11 242.229 191.366 239.406 191.366 236.442V52.1484C191.366 34.8956 205.363 20.8594 222.567 20.8594C239.772 20.8594 253.769 34.8956 253.769 52.1484V236.442C253.769 239.406 255.025 242.229 257.225 244.207C268.248 254.121 274.569 268.257 274.569 282.992C274.569 311.747 251.242 335.141 222.567 335.141Z"
        fill="#F2F2F2"
      />
      <path
        d="M232.97 253.497V135.585C232.97 129.825 228.313 125.156 222.569 125.156C216.826 125.156 212.169 129.825 212.169 135.585V253.497C200.064 257.802 191.368 269.395 191.368 282.992C191.368 300.245 205.365 314.281 222.569 314.281C239.774 314.281 253.771 300.245 253.771 282.992C253.771 269.395 245.075 257.802 232.97 253.497ZM222.569 293.421C216.835 293.421 212.169 288.743 212.169 282.992C212.169 277.241 216.835 272.562 222.569 272.562C228.304 272.562 232.97 277.241 232.97 282.992C232.97 288.743 228.305 293.421 222.569 293.421Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.771 62.5781H326.571C332.315 62.5781 336.972 57.9084 336.972 52.1484C336.972 46.3885 332.315 41.7188 326.571 41.7188H305.771C300.027 41.7188 295.37 46.3885 295.37 52.1484C295.37 57.9084 300.027 62.5781 305.771 62.5781Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.771 104.296H316.171C321.915 104.296 326.571 99.6266 326.571 93.8667C326.571 88.1067 321.915 83.437 316.171 83.437H305.771C300.027 83.437 295.37 88.1067 295.37 93.8667C295.37 99.6266 300.027 104.296 305.771 104.296Z"
        fill="#F2F2F2"
      />
      <path
        d="M326.571 125.156H305.771C300.027 125.156 295.37 129.825 295.37 135.585C295.37 141.345 300.027 146.015 305.771 146.015H326.571C332.315 146.015 336.972 141.345 336.972 135.585C336.972 129.825 332.315 125.156 326.571 125.156Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.771 187.733H316.171C321.915 187.733 326.571 183.063 326.571 177.303C326.571 171.543 321.915 166.874 316.171 166.874H305.771C300.027 166.874 295.37 171.543 295.37 177.303C295.37 183.063 300.027 187.733 305.771 187.733Z"
        fill="#F2F2F2"
      />
      <path
        d="M326.571 208.592H305.771C300.027 208.592 295.37 213.262 295.37 219.022C295.37 224.782 300.027 229.452 305.771 229.452H326.571C332.315 229.452 336.972 224.782 336.972 219.022C336.972 213.262 332.315 208.592 326.571 208.592Z"
        fill="#F2F2F2"
      />
      <path
        d="M118.141 225.555L108.161 235.562V135.585C108.161 129.825 103.504 125.156 97.7601 125.156C92.0163 125.156 87.3597 129.825 87.3597 135.585V235.562L77.3795 225.555C73.3185 221.482 66.733 221.482 62.6713 225.555C58.6096 229.628 58.6096 236.231 62.6713 240.304L90.4057 268.117C92.4365 270.153 95.0983 271.171 97.7601 271.171C100.422 271.171 103.084 270.153 105.114 268.116L132.848 240.304C136.91 236.231 136.91 229.627 132.848 225.554C128.787 221.481 122.202 221.481 118.141 225.555Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleTemperatureBelow;
