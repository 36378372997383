import { Dispatch } from 'react';
import { GeofenceFilter, LayerFilter, MicrofenceFilter, SearchType } from '../../types';
import { GridRowData } from '@material-ui/data-grid';
import { GeofenceFilterComponent } from '../../Filter/GeofenceFilterComponent';
import { MicrofenceFilterComponent } from '../../Filter/MicrofenceFilterComponent';

export const GeofenceEditorFilter = ({
  searchType,
  layerFilter,
  setLayerFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  selectedMicrofence,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  setRefreshSearch,
}: {
  searchType: SearchType | undefined;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  geofenceFilter: GeofenceFilter | undefined;
  setGeofenceFilter: Dispatch<GeofenceFilter | undefined>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  selectedMicrofence: GridRowData | undefined;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  setRefreshSearch: Dispatch<boolean>;
}) => {
  return (
    <div
      style={{
        margin: '20px',
      }}
    >
      {searchType?.id === 'GEOFENCES' && (
        <GeofenceFilterComponent
          layerFilter={layerFilter}
          setLayerFilter={setLayerFilter}
          geofenceFilter={geofenceFilter}
          setGeofenceFilter={setGeofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></GeofenceFilterComponent>
      )}

      {searchType?.id === 'MICROFENCES' && (
        <MicrofenceFilterComponent
          microfenceFilter={microfenceFilter}
          setMicrofenceFilter={setMicrofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></MicrofenceFilterComponent>
      )}
    </div>
  );
};
