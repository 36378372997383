import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleCustom {
  className?: string;
}

const IconSingleCustom: React.FC<IconSingleCustom> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 357 356" className={className}>
      <path
        d="M46.9422 63.6176C48.0547 63.8967 49.0728 64.4737 49.8842 65.2843L140.527 155.929L156.929 139.529L66.2844 48.884C65.4714 48.0719 64.8969 47.0505 64.6161 45.9347L58.9112 23.1932L21.0927 1.57227L2.57324 20.0909L24.2007 57.9354L46.9422 63.6176Z"
        fill="#F2F2F2"
      />
      <path
        d="M127.545 270.381L271.382 126.542C272.957 124.964 275.243 124.332 277.405 124.87C296.343 129.724 316.458 125.518 331.871 113.493C347.287 101.467 356.253 82.9735 356.15 63.4246C356.15 62.234 356.115 61.0344 356.038 59.8242L322.583 93.2749C320.888 94.9718 318.374 95.5667 316.095 94.8066L278.045 82.1214C276.15 81.494 274.664 80.0079 274.032 78.115L261.35 40.0637C260.589 37.7883 261.182 35.2735 262.881 33.5751L296.335 0.12195C276.19 -1.13134 256.659 7.33245 243.792 22.883C230.926 38.4369 226.276 59.2074 231.281 78.7595C231.823 80.9243 231.191 83.2095 229.614 84.7859L85.7798 228.615C84.2018 230.18 81.9182 230.815 79.7551 230.285C74.7476 228.999 69.5977 228.333 64.425 228.312C37.1122 228.132 12.708 245.351 3.7152 271.146C-5.27269 296.94 3.14471 325.597 24.6558 342.432C46.1668 359.267 76.0066 360.554 98.8849 345.629C121.765 330.704 132.613 302.877 125.871 276.404C125.334 274.243 125.968 271.954 127.545 270.381ZM79.8617 317.099H48.9932L33.769 291.733L48.9932 266.363H79.8617L95.0827 291.733L79.8617 317.099ZM104.337 242.855L243.856 103.334L252.822 112.301L113.303 251.822L104.337 242.855Z"
        fill="#F2F2F2"
      />
      <path
        d="M304.149 352.691C318.275 358.002 334.201 354.558 344.872 343.887C355.545 333.213 358.99 317.285 353.676 303.163L304.149 352.691Z"
        fill="#F2F2F2"
      />
      <path
        d="M344.874 289.425L273.645 218.168C265.788 224.772 254.187 224.27 246.925 217.017C239.662 209.766 239.15 198.168 245.743 190.303L235.656 180.203L181.205 234.656L191.353 244.804C199.21 238.2 210.814 238.7 218.071 245.95C225.333 253.203 225.848 264.804 219.255 272.669L290.423 343.875C291.199 344.651 292.019 345.371 292.847 346.071L347.071 291.846C346.373 291.017 345.65 290.201 344.874 289.425ZM307.273 315.24L243.856 251.822L252.822 242.855L316.241 306.275L307.273 315.24Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleCustom;
