/* eslint-disable @typescript-eslint/no-empty-interface */
import { SvgIcon } from '@mui/material';
import React from 'react';

interface IconExitProps {}

const IconExit: React.FC<IconExitProps> = () => {
  return (
    <SvgIcon viewBox="0 0 356 359">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200 21V3 500"
        fill="#F2F2F2"
        stroke="#F2F2F2"
        strokeWidth={40}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M310.75 200.71C320.829 200.71 329 192.557 329 182.5C329 172.443 320.829 164.29 310.75 164.29H128.25C118.17 164.29 110 172.443 110 182.5C110 192.557 118.17 200.71 128.25 200.71H310.75Z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.595 140.747C272.468 133.635 272.468 122.105 279.595 114.994C286.722 107.882 298.277 107.882 305.404 114.994L360.154 169.624C367.281 176.735 367.281 188.265 360.154 195.376L305.404 250.006C298.277 257.118 286.722 257.118 279.595 250.006C272.468 242.895 272.468 231.365 279.595 224.254L321.44 182.5L279.595 140.747Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconExit;
