import axios from 'axios';
import { Atom, useAtomValue, useSetAtom, WritableAtom } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { CMContainer } from '../Components/Map/Messages';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { PERSISTOR_URL } from '../store/url';
import { CID, PID } from '../store/user';

// Use at the top of the "logged in" app tree; to manage latest events lifecycle
export const LatestEventsProvider = ({
  children,
  hoursAgo,
  latestEventsAtom,
  latestNowAtom,
}: {
  children: ReactNode;
  hoursAgo: number;
  latestEventsAtom: WritableAtom<CMContainer[], CMContainer[]>;
  latestNowAtom: WritableAtom<Date, Date> /* Replay */ | Atom<null> /* Live */;
}) => {
  const setLatestEvents = useSetAtom(latestEventsAtom);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const nowUnixTs = Math.floor((useAtomValue(latestNowAtom) ?? new Date()).getTime() / 1000);
  const fromUnixTs = nowUnixTs - 60 * 60 * hoursAgo;

  useEffect(() => {
    const abortController = new AbortController();
    setLatestEvents([]);
    if (!isNaN(fromUnixTs) && !isNaN(nowUnixTs) && fromUnixTs !== nowUnixTs) {
      (async () => {
        const events = (
          await axios.get<CMContainer[]>(
            `${persistorUrl}/${cid}/${pid}/latest/${fromUnixTs}/${nowUnixTs}`,
            {
              ...authedRequestConfig,
              signal: abortController.signal,
            },
          )
        ).data;
        setLatestEvents(events);
      })();
    }
    return () => {
      abortController.abort();
    };
  }, [setLatestEvents, authedRequestConfig, cid, pid, fromUnixTs, nowUnixTs, persistorUrl]);

  return <>{children}</>;
};
