import OverlayPositioning from 'ol/OverlayPositioning';
import { Overlay } from 'ol';

const OVERLAY_ELEMENT = document.getElementById('ol-popup') as HTMLElement;
if (!OVERLAY_ELEMENT) throw new Error('Expected to find localised overlay element in the DOM');

const OVERLAY_CLOSER_ELEMENT = document.getElementById('ol-popup-closer') as HTMLElement;
if (!OVERLAY_CLOSER_ELEMENT)
  throw new Error('Expected to find localised closer element in the DOM');

export const OVERLAY_CONTENT_ELEMENT = document.getElementById('ol-popup-content') as HTMLElement;
if (!OVERLAY_CONTENT_ELEMENT)
  throw new Error('Expected to find localised overlay content element in the DOM');

export const OVERLAY = new Overlay({
  element: OVERLAY_ELEMENT,
  offset: [-100, 0],
  positioning: OverlayPositioning.CENTER_RIGHT,
});

OVERLAY_CLOSER_ELEMENT.onclick = () => {
  OVERLAY.setPosition(undefined);
  OVERLAY_CLOSER_ELEMENT.blur();
};
