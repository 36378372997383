import { compareDesc, subDays } from 'date-fns';
import { atom } from 'jotai';
import { NotificationUpdate } from '../hooks/geomoby/LiveMapActions';
import { groupByDay } from '../util/groupByDay';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { uniqBy } from 'lodash';

export type RawNotification = Omit<NotificationUpdate['payload'], 'timestamp'> & {
  datetime: Date;
};

export enum SaveResult {
  NONE,
  SUCCESS,
  FAIL,
}

export type SaveAction = '' | 'Save' | 'Delete';

export type SaveNotification = {
  id: SaveResult;
  action: SaveAction;
  message?: string;
};

export const LIVE_NOTIFICATIONS = atom<RawNotification[]>([
  // {
  //   notificationId: '1',
  //   id: {'foo': 'bar'},
  //   assetId: 'foo',
  //   datetime: new Date(),
  //   label: 'Person visited Crib GeoFence after shift hours',
  //   interaction: { assetId: 'test-device', type: 'find-asset' },
  // },
  // {
  //   notificationId: '2',
  //   id: {'foo': 'bar'},
  //   assetId: 'bar',
  //   datetime: new Date(),
  //   label: 'Person has not visited a GeoFence in 30 minutes',
  // },
  // {
  //   notificationId: '3',
  //   id: {'foo': 'baz'},
  //   assetId: 'baz',
  //   datetime: subDays(new Date(), 1),
  //   label: 'Person has been in Crib GeoFence for 30 minutes',
  //   interaction: { fenceId: 'Perth', type: 'find-fence' },
  // },
  // {
  //   notificationId: '4',
  //   id: {'foo': 'fqux'},
  //   assetId: 'fqux',
  //   datetime: subDays(new Date(), 1),
  //   label: 'Too many persons in Lock Box GeoFence',
  // },
  // {
  //   notificationId: '5',
  //   id: {'foo': 'foo'},
  //   assetId: 'foo',
  //   datetime: subDays(new Date(), 3),
  //   label: 'Person has not visited Lock Box after shift start',
  // },
]);

export const SAVE_NOTIFICATION = atom<SaveNotification>({
  id: SaveResult.NONE,
  action: '',
  message: '',
});

export const REPLAY_NOTIFICATIONS = atom<RawNotification[]>([]);

export type Notification = RawNotification & { seen: boolean; replayed: boolean };

export const makeNotificationId = ({
  type,
  iso8601,
  primaryId,
  otherIds,
}: {
  type: 'SensedTriggered' | 'CoordinatesTriggered';
  iso8601: string;
  primaryId: string;
  otherIds?: string[];
}) => `${type}_${iso8601}_${primaryId}${(otherIds ?? []).map(id => `_${id}`)}`;

export const SEEN_NOTIFICATIONS = atomWithStorage<{ [id: string]: boolean | undefined }>(
  '@geomoby/notifications',
  {},
  createJSONStorage(() => localStorage),
);
export const SEEN_REPLAY_NOTIFICATIONS = atomWithStorage<{ [id: string]: boolean | undefined }>(
  '@geomoby/notifications',
  {},
  createJSONStorage(() => sessionStorage),
);

export const NOTIFICATIONS = atom<Notification[]>(get =>
  [
    ...get(LIVE_NOTIFICATIONS).map(notification => ({
      ...notification,
      seen: !!get(SEEN_NOTIFICATIONS)[notification.notificationId],
      replayed: false,
    })),
    ...get(REPLAY_NOTIFICATIONS).map(notification => ({
      ...notification,
      seen: !!get(SEEN_REPLAY_NOTIFICATIONS)[notification.notificationId],
      replayed: true,
    })),
  ].sort(({ datetime: a }, { datetime: b }) => compareDesc(a, b)),
);

export const LATEST_UNSEEN_REPLAY_NOTIFICATION = atom<Notification | undefined>(get =>
  get(NOTIFICATIONS).filter(({ replayed }) => replayed)[0]?.seen
    ? undefined
    : get(NOTIFICATIONS).filter(({ replayed }) => replayed)[0],
);

export const LATEST_UNSEEN_NOTIFICATION = atom<Notification | undefined>(get =>
  get(NOTIFICATIONS)[0]?.seen ? undefined : get(NOTIFICATIONS)[0],
);

export const NOTIFICATIONS_GROUPED_BY_DAY = atom(get =>
  get(NOTIFICATIONS)
    .filter(n => !n.replayed)
    .reduce<Notification[][]>(groupByDay, []),
);

export const REPLAY_NOTIFICATIONS_GROUPED_BY_DAY = atom(get => {
  const allNotifications = get(NOTIFICATIONS);
  const replayNotifications = allNotifications.filter(n => n.replayed);
  const liveNotifications = allNotifications.filter(n => !n.replayed);
  // Want all replay notifications, and live notifications that have not been replayed
  const wantedNotifications = uniqBy(
    [...replayNotifications, ...liveNotifications],
    'notificationId',
  ).sort(({ datetime: a }, { datetime: b }) => compareDesc(a, b));
  return wantedNotifications.reduce<Notification[][]>(groupByDay, []);
});
