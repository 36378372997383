/* eslint-disable @typescript-eslint/no-explicit-any, no-var */
import { Box } from '@mui/material';
import { map, option } from 'fp-ts/es6';
import { ordString } from 'fp-ts/es6/Ord';
import { pipe } from 'fp-ts/es6/pipeable';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { msToHM } from '../Global/TimeFormattingFunctions';

interface Point {
  x: number;
  y: number;
}
interface PlaceAndTime {
  place: Point;
  time: string;
}
interface AssetMovement {
  assetId: string;
  from: PlaceAndTime;
  to: PlaceAndTime;
  distance_m: number;
  vel_mps: number;
}

export interface ToGraph {
  assetId: string;
  distance_m: number;
  time_s: number;
}

function amsToGraph(ams: AssetMovement[], minMps: number) {
  const res = new Map<string, ToGraph>();
  ams.forEach(am => {
    const { distance_m, vel_mps, assetId } = am;
    if (vel_mps < minMps) return;
    const tosave = pipe(
      option.fromNullable(res.get(assetId)),
      option.getOrElse(() => {
        return {
          assetId: assetId,
          distance_m: 0,
          time_s: 0,
        };
      }),
    );
    tosave.distance_m += distance_m;
    if (vel_mps !== 0) {
      tosave.time_s += distance_m / vel_mps;
    }
    res.set(assetId, tosave);
  });
  return map
    .toArray(ordString)(res)
    .map(v => v[1]);
}

export const ChartTravelTime = ({ tgs }: { tgs: ToGraph[] }) => {
  const metersName = 'Travel distance';
  const timeName = 'Travel time';

  const tooltipFormater = (value: string | number | Array<string | number>, name: string) => {
    const numValue = typeof value === 'number' ? value : 0;
    const stringValue =
      name === metersName ? (numValue / 1000).toFixed(1) + 'km' : msToHM(numValue * 1000);
    return <>{stringValue}</>;
  };

  const tickFormatterDate = (value: any) => {
    const numValue = typeof value === 'number' ? value : 0;
    return msToHM(numValue * 1000);
  };
  const tickFormatterKm = (value: any) => {
    const numValue = typeof value === 'number' ? value : 0;
    return (numValue / 1000).toFixed(0) + 'km';
  };
  return (
    <Box fontSize={13}>
      <ResponsiveContainer height={300}>
        <BarChart data={tgs} barSize={30}>
          <CartesianGrid
            stroke="grey"
            vertical={false}
            opacity={0.1}
            strokeWidth={1}
            strokeDasharray="6 6"
          />

          <XAxis dataKey="assetId" axisLine={false} stroke="#A6ACBE" />
          <YAxis
            yAxisId="left"
            orientation="left"
            axisLine={false}
            stroke="#A6ACBE"
            tickLine={false}
            tickFormatter={tickFormatterDate}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            stroke="#A6ACBE"
            tickLine={false}
            tickFormatter={tickFormatterKm}
          />
          <Tooltip
            labelStyle={{ color: '#23272D' }}
            labelFormatter={label => `Device ID: ${label}`}
            formatter={tooltipFormater}
            cursor={{ fill: 'none' }}
            isAnimationActive={true}
          />
          <Legend verticalAlign={'top'} align={'center'} layout={'horizontal'} />
          <Bar
            yAxisId="right"
            legendType="circle"
            dataKey="distance_m"
            fill="#4CB8C4"
            radius={[5, 5, 5, 5]}
            name={metersName}
          />
          <Bar
            yAxisId="left"
            legendType="circle"
            dataKey="time_s"
            fill="#4F4F4F"
            radius={[5, 5, 5, 5]}
            name={timeName}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
