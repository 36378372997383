import { Delete, FormatListBulleted } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useMobile } from '../../util/useMobile';
import InputContainer from '../Global/InputContainer';
import { Properties } from './Properties';

export const ProjectConfig = () => {
  return (
    <>
      <Container component="div">
        <Stack p={2} spacing={4} marginTop={2}>
          <Properties />
        </Stack>
      </Container>
    </>
  );
};
