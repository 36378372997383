export const msToHM = (ms: number): string => {
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor(ms / 1000 / 3600);
  return hours + 'h ' + minutes + 'm';
};

export const msToHMNumber = (ms: number): number => {
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor(ms / 1000 / 3600);
  return hours + minutes / 100;
};

//Test to write
export const durationInMinutes = (from: Date, to: Date): number => {
  const interval = from.getTime() - to.getTime();
  return interval / 1000 / 60;
};

export default {
  msToHM,
  msToHMNumber,
  durationInMinutes,
};
