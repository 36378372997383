import { Info, QueryBuilder, Timelapse } from '@mui/icons-material';
import { DateRangePicker } from '@mui/lab';
import { Box, Button, Grid, Paper, Stack, TextField, Typography, useTheme } from '@mui/material';
import { subWeeks } from 'date-fns';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CenteredProgress, Header, Item, Sidebar } from '../../Common/Sidebar';
import { SidebarAndMap } from '../Map/SidebarAndMap/SidebarAndMap';
import { useGeofences } from '../useGeofenceTimes';
import { ChartTravelTimeInFence, FenceVisit } from './ChartTimeInFence';
import { ChartTravelTime, ToGraph } from './ChartTravelTime';
import { useGeofenceDistances } from './useGeofenceDistances';
import { useGeofenceTimes } from './useGeofenceTimes';

export function AnalDashboard() {
  const [uiRange, setUiRange] = useState<[Date | null, Date | null]>([
    subWeeks(new Date(), 1),
    new Date(),
  ]);
  const [from, to] = useMemo(() => uiRange, [uiRange]);

  const {
    load: loadGeofences,
    loading: loadingGeofences,
    error: errorGeofences,
    data: dataGeofences,
  } = useGeofences();
  const {
    load: loadDistances,
    loading: loadingDistances,
    error: errorDistances,
    data: dataDistances,
  } = useGeofenceDistances(from ?? undefined, to ?? undefined);
  const {
    load: loadTimes,
    loading: loadingTimes,
    error: errorTimes,
    data: dataTimes,
  } = useGeofenceTimes(from ?? undefined, to ?? undefined);

  useEffect(() => {
    loadGeofences();
  }, [loadGeofences]);
  const travelTime = useMemo(
    (): ToGraph[] | undefined =>
      dataTimes &&
      dataDistances
        ?.filter(({ creatorIdentifiers }) => !!creatorIdentifiers.deviceId)
        .map(({ creatorId, creatorLabel, distanceMetres }) => ({
          time_s:
            dataTimes
              .filter(i => i.creatorId === creatorId)
              .reduce((acc, value) => acc + durationToMilliseconds(value.duration), 0) / 1000 ?? 0,
          assetId: creatorLabel ?? JSON.stringify(creatorId),
          distance_m: distanceMetres,
        })),
    [dataDistances, dataTimes],
  );
  const findLabelAndLayer = useCallback(
    (id: string, type: string) => {
      let fenceLabel: string | undefined;
      const layer = dataGeofences?.find(layer => {
        if (type === 'polygon')
          fenceLabel = layer.polygons.find(polygon => polygon.id === id)?.name;
        if (type === 'line') fenceLabel = layer.lines.find(line => line.id === id)?.name;
        if (fenceLabel) return true;
      });

      return { lid: layer?.name ?? '<unknown>', fid: fenceLabel ?? '<deleted>' };
    },
    [dataGeofences],
  );
  const fenceVisits = useMemo(
    (): FenceVisit[] | undefined =>
      dataGeofences &&
      dataTimes?.map(({ creatorId, creatorLabel, geofenceId, geofenceType, duration }) => ({
        aid: creatorLabel ?? JSON.stringify(creatorId),
        milliseconds: durationToMilliseconds(duration),
        lfid: findLabelAndLayer(geofenceId, geofenceType),
      })),
    [dataTimes, findLabelAndLayer, dataGeofences],
  );

  return (
    <SidebarAndMap
      sidebar={
        <Sidebar>
          <Item>
            <Header icon={<QueryBuilder />}>Productivity Report</Header>
            <DateRangePicker
              value={uiRange}
              onChange={value => setUiRange(value)}
              disableFuture
              renderInput={(startProps, endProps) => (
                <Stack direction="row" spacing={2}>
                  <TextField {...startProps} label="Start date" />
                  <TextField {...endProps} label="End date" />
                </Stack>
              )}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                loadTimes();
                loadDistances();
              }}
            >
              Generate report
            </Button>
          </Item>
        </Sidebar>
      }
      map={
        <>
          <Paper style={{ margin: '16px', width: '50rem' }}>
            <Grid
              container
              direction="row"
              alignItems="left"
              spacing={1}
              style={{ padding: '8px 16px' }}
              marginTop={0}
            >
              <Info />
              <Typography fontSize={14} style={{ margin: '3px 7px' }}>
                To access your ESG report (Cultural Heritage Protection), please contact your
                GeoMoby account manager
              </Typography>
            </Grid>
          </Paper>
          <Grid container spacing={4} p={2}>
            <Card title={<Header icon={<Timelapse />}>Aggregated travel time</Header>}>
              {loadingDistances || loadingTimes ? (
                <CenteredProgress />
              ) : errorDistances || errorTimes ? (
                <Typography color="error">Failed to loading geofence reports.</Typography>
              ) : !travelTime ? (
                <Typography variant="caption">Click "generate report" to view data...</Typography>
              ) : (
                <ChartTravelTime tgs={travelTime} />
              )}
            </Card>
            <Card title={<Header icon={<QueryBuilder />}>Asset GeoFence report</Header>}>
              {loadingTimes ? (
                <CenteredProgress />
              ) : errorTimes ? (
                <Typography color="error">Failed to loading geofence reports.</Typography>
              ) : !fenceVisits ? (
                <Typography variant="caption">Click "generate report" to view data...</Typography>
              ) : (
                <ChartTravelTimeInFence fenceVisits={fenceVisits} />
              )}
            </Card>
          </Grid>
        </>
      }
    />
  );
}

const Card = ({ title, children }: { title: ReactNode; children: ReactNode }) => (
  <Grid item xs={12}>
    <Box p={1}>{title}</Box>
    <Paper elevation={8} style={{ backgroundColor: useTheme().palette.background.default }}>
      <Box p={2} overflow="auto">
        {children}
      </Box>
    </Paper>
  </Grid>
);

const durationToMilliseconds = ({
  seconds,
  minutes,
  hours,
}: {
  seconds: number;
  minutes: number;
  hours: number;
}) => 1000 * ((seconds ?? 0) + (minutes ?? 0) * 60 + (hours ?? 0) * 60 * 60);
