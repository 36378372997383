/* eslint-disable react/jsx-key */
import React, { Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { Box, IconButton, Fab, Grid, Tooltip, Typography } from '@mui/material';
import {
  ControlCamera,
  LinearScale,
  RadioButtonUnchecked,
  CheckBoxOutlineBlank,
  DashboardOutlined,
  ShowChart,
  BluetoothAudio,
  Router,
  PanTool,
  CropRotate,
  Edit,
  Straighten,
  Smartphone,
} from '@mui/icons-material';
import { some, none, isSome, toNullable, isNone, Option, fromNullable } from 'fp-ts/es6/Option';
import VectorLayer from 'ol/layer/Vector';
import { MICROFENCE_LAYER_ID } from '../BeaconUtils';
import { createPenSubTools, MEASUREMENT_TOOLS } from './createPenSubTools';
import { DrawType, EditType, GeofenceTypes, MicrofenceTypes } from '../MapDefaults';

type Tools = [JSX.Element, Option<DrawType>, Option<EditType>, string][];

const OUTDOOR_TOOLS: Tools = [
  [<RadioButtonUnchecked />, some('CIRCLE' as const), none, `Draw circular GeoFence`],
  [<CheckBoxOutlineBlank />, some('POLYGON' as const), none, `Draw polygonal GeoFence`],
  [<DashboardOutlined />, some('MULTIPOLYGON' as const), none, `Draw multipolygonal GeoFence`],
  [<ShowChart />, some('TRIPWIRE' as const), none, `Draw tripwire GeoFence`],
  [<Straighten />, some('MEASURE' as const), none, `Measure Distance`],
  [<Edit />, none, some('SHAPE_CHANGE' as const), `Edit`],
  [<CropRotate />, none, some('SCALE_ROT' as const), `Scale and Rotate`],
];

const INDOOR_TOOLS: Tools = [
  [<Router />, some('MICROFENCE_GATEWAY' as const), none, `Place Gateway MicroFence`],
  [<BluetoothAudio />, some('MICROFENCE_BEACON' as const), none, `Place Beacon MicroFence`],
  [<Smartphone />, some('MICROFENCE_DEVICE' as const), none, `Place Device MicroFence`],
];

export const createPenTools = (props: {
  mapType: 'INDOOR' | 'OUTDOOR';
  selectedLayer: Option<string>;
  geofenceType: GeofenceTypes | MicrofenceTypes | undefined;
  geofenceTooBig: boolean;
  editing: boolean;
  isLoading: boolean;
  setEditing: () => void;
  unsetEditing: () => void;
  drawType: Option<DrawType>;
  setDrawType: Dispatch<SetStateAction<Option<DrawType>>>;
  editType: Option<EditType>;
  setEditType: Dispatch<SetStateAction<Option<EditType>>>;
}): ReactNode[] => {
  const isMicrofenceLayer = toNullable(props.selectedLayer) === MICROFENCE_LAYER_ID;
  const tools = props.mapType === 'INDOOR' || isMicrofenceLayer ? INDOOR_TOOLS : OUTDOOR_TOOLS;

  return [
    <Typography variant="caption" key="tools">
      Tools
    </Typography>,
    <Grid item key="none">
      <Fab
        color={toNullable(props.drawType) === null ? 'secondary' : undefined}
        size="small"
        onClick={() => {
          props.setDrawType(none);
          if (props.editing) {
            props.unsetEditing();
          }
        }}
      >
        <PanTool fontSize="small" />
      </Fab>
    </Grid>,
    ...tools.map(([icon, drawType, editType, tooltip], index) => {
      const disabledDrawType =
        isNone(drawType) ||
        (toNullable<unknown>(drawType) && !toNullable(props.selectedLayer)) ||
        props.isLoading;
      const disabledEditType =
        toNullable(props.drawType) === 'MEASURE' ||
        isNone(editType) ||
        (toNullable<unknown>(editType) && !toNullable(props.selectedLayer)) ||
        props.isLoading ||
        isNone(props.drawType);

      const isDisableToolsWhenPolygon =
        props.geofenceType === 'multipolygon' &&
        (toNullable(drawType) === 'CIRCLE' ||
          toNullable(drawType) === 'POLYGON' ||
          toNullable(drawType) === 'TRIPWIRE');
      const isDisableToolsWhenMultipolygon =
        (props.geofenceType === 'polygon' || props.geofenceType === 'line') &&
        toNullable(drawType) === 'MULTIPOLYGON';
      const isDisabled =
        Boolean(isSome(drawType) ? disabledDrawType : disabledEditType) ||
        isDisableToolsWhenPolygon ||
        isDisableToolsWhenMultipolygon;

      const selectedDrawType =
        isSome(drawType) && toNullable(props.drawType) === toNullable<unknown>(drawType);
      const selectedEditType =
        isSome(editType) && toNullable(props.editType) === toNullable<unknown>(editType);
      return (
        <Grid item key={index}>
          <Tooltip title={isDisabled ? '' : tooltip} arrow>
            <Fab
              color={
                isSome(drawType) && selectedDrawType
                  ? 'secondary'
                  : isNone(drawType) && selectedEditType
                  ? 'primary'
                  : undefined
              }
              size="small"
              onClick={() => {
                if (isSome(editType) && isSome<unknown>(editType)) {
                  props.setEditType(editType);
                  if (!props.editing) {
                    props.setEditing();
                  }
                } else if (isSome(drawType) && isSome<unknown>(drawType) && isNone(editType)) {
                  props.setEditType(
                    drawType.value === 'MEASURE' ? none : fromNullable('SHAPE_CHANGE'),
                  );
                  props.setDrawType(drawType);
                  if (!props.editing) {
                    props.setEditing();
                  }
                } else if ((isNone(drawType) || isNone(drawType)) && props.editing) {
                  props.unsetEditing();
                }
              }}
              disabled={
                isDisabled || (props.geofenceTooBig && toNullable(editType) === 'SCALE_ROT')
              }
            >
              {icon}
            </Fab>
          </Tooltip>
        </Grid>
      );
    }),
  ];
};
