import axios, { AxiosError } from 'axios';
import { none, Option, toUndefined, some, isSome, isNone } from 'fp-ts/es6/Option';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { uniqBy } from 'lodash';
import { Feature } from 'ol';
import GeoJSON, { GeoJSONLineString, GeoJSONPolygon, GeoJSONMultiPolygon } from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { abort, features } from 'process';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Bounds,
  initialLatitude,
  initialLongitude,
  initialExtentInDegrees,
} from '../../Components/Map/MapDefaults';
import {
  FenceType,
  GlobalProjectId,
  LayerFenceData,
  MicrofenceData,
  PointData,
} from '../../Components/Map/Messages';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import {
  microfenceClusterFromJson,
  layerFromJson,
  LayerResult,
  pointClusterFromJson,
  multipolygonsFromJson,
} from './LiveMapOlFunctions';
import { containsExtent, Extent } from 'ol/extent';
import { transform } from 'ol/proj';
import { LAYER_GROUPS } from '../../store/map';
import { MICROFENCE_LAYER_ID, POINT } from '../../Components/Map/BeaconUtils';
import { MultiPolygon } from 'ol/geom';
import { FenceZone } from '../../Components/Map/MapDefaults';

export interface LiveMapShoppingList {
  gpid: GlobalProjectId;
  toMercator: boolean;
}

export interface StrongFeatureHolder<G extends Geometry, D> {
  feature: Feature<G>;
  data: D;
}

export interface LiveMapShoppingResult {
  points: StrongFeatureHolder<Point, PointData>[];
  layers: Map<string, { name: string; layer: StrongFeatureHolder<Polygon, LayerFenceData>[] }>;
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
}

export type MultiPolygonLayer = {
  id: string;
  name: string;
  multipolygons: {
    id: string;
    name: string;
    points: GeoJSONMultiPolygon;
  }[];
};

export function useLiveMapLoader(noMercator?: boolean) {
  const storeLayerGroups = useSetAtom(LAYER_GROUPS);
  const [layerData, setLayerData] =
    useState<
      Option<Map<string, { name: string; layer: StrongFeatureHolder<Polygon, LayerFenceData>[] }>>
    >(none);
  const [microfencesData, setMicrofencesData] =
    useState<undefined | StrongFeatureHolder<Point, MicrofenceData>[]>(undefined);

  const [pointsData, setPointsData] =
    useState<undefined | StrongFeatureHolder<Point, PointData>[]>(undefined);

  const [result, setResult] = useState<Option<LiveMapShoppingResult>>(none);
  const [bounds, setBounds] = useState<Bounds>({
    latitude: initialLatitude,
    longitude: initialLongitude,
    extentInDegrees: initialExtentInDegrees,
  });
  const [activeRequests, setActiveRequests] = useState<number>(0);
  const abortController = useRef<AbortController | undefined>(undefined);
  const isLoading = useMemo(() => activeRequests > 0, [activeRequests]);
  const knownExtents = useRef<Extent[]>([]);

  let toMercator: boolean;
  if (noMercator) {
    toMercator = false;
  } else {
    toMercator = true;
  }

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  useEffect(() => {
    const fetchData = async () => {
      const [layers, microfences, simplifiedFences] = await Promise.all([
        axios.get<
          {
            id: string;
            name: string;
            polygons: {
              id: string;
              name: string;
              points: GeoJSONPolygon;
              zone?: FenceZone;
            }[];
            multipolygons: {
              id: string;
              name: string;
              points: GeoJSONMultiPolygon;
              // points: GeoJSONMultiPolygon;
              zone?: FenceZone;
            }[];
            lines: { id: string; name: string; points: GeoJSONLineString }[];
          }[]
        >(
          `${triggersUrl}/${cid}/${pid}/geofences/withinExtent?latitude=${bounds.latitude}&longitude=${bounds.longitude}&extentInDegrees=${bounds.extentInDegrees}`,
          authedRequestConfig,
        ),
        axios.get<MicrofenceData[]>(
          `${triggersUrl}/${cid}/${pid}/microfences`,
          authedRequestConfig,
        ),
        axios.get<
          {
            fenceId: string;
            name: string;
            fenceType: FenceType;
            layerId: string;
            point: { type: string; coordinates: string[] };
          }[]
        >(`${triggersUrl}/${cid}/${pid}/geofences/simplified`, authedRequestConfig),
      ]);
      const lrs: LayerResult[] = layers.data.map(({ id, name, polygons, multipolygons, lines }) =>
        layerFromJson(
          id,
          name,
          [
            ...polygons,
            ...multipolygons.map(m => {
              return {
                ...m,
                zone: FenceZone.cleared,
              };
            }),
            ...lines,
          ],
          true,
        ),
      );

      const m = new Map<
        string,
        { name: string; layer: StrongFeatureHolder<Polygon, LayerFenceData>[] }
      >(lrs.map(({ lid, name, layer }) => [lid, { layer, name }]));
      setLayerData(some(m));

      const { microfenceCluster } = microfenceClusterFromJson(
        MICROFENCE_LAYER_ID,
        microfences.data,
        true,
      );
      setMicrofencesData(microfenceCluster);

      const { pointCluster } = pointClusterFromJson(POINT, simplifiedFences.data);
      setPointsData(pointCluster);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNone(layerData)) return;

    const loadMoreFeatures = async () => {
      const { latitude, longitude, extentInDegrees } = bounds;
      const [xMax, yMax] = transform(
        [longitude + extentInDegrees, latitude + extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const [xMin, yMin] = transform(
        [longitude - extentInDegrees, latitude - extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const extent: [number, number, number, number] = [xMax, yMax, xMin, yMin];
      // already got features for this extent
      if (knownExtents.current.some(ext => containsExtent(ext, extent))) return;

      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();
      setActiveRequests(n => n + 1);
      const newLayers = await axios
        .get<
          {
            id: string;
            name: string;
            polygons: { id: string; name: string; points: GeoJSONPolygon }[];
            multipolygons: {
              id: string;
              name: string;
              points: GeoJSONMultiPolygon /*points: GeoJSONMultiPolygon*/;
            }[];
            lines: { id: string; name: string; points: GeoJSONLineString }[];
          }[]
        >(
          `${triggersUrl}/${cid}/${pid}/geofences/withinExtent?latitude=${latitude}&longitude=${longitude}&extentInDegrees=${bounds.extentInDegrees}`,
          { ...authedRequestConfig, signal: abortController.current.signal },
        )
        .catch((e: AxiosError) => {
          if (e.message === 'cancelled') {
            console.warn(e);
          } else {
            console.error(e);
          }
          setActiveRequests(n => n - 1);
        });
      if (!newLayers) {
        return;
      }

      knownExtents.current = [...knownExtents.current, extent];

      const newLrs: LayerResult[] = newLayers.data.map(
        ({ id, name, polygons, multipolygons, lines }) =>
          layerFromJson(
            id,
            name,
            [
              ...polygons,
              ...multipolygons.map(m => {
                return {
                  ...m,
                  zone: FenceZone.cleared,
                };
              }),
              ...lines,
            ],
            true,
          ),
      );

      setLayerData(layerData => {
        if (isNone(layerData)) return layerData;

        return some(
          new Map(
            Array.from(layerData.value.entries()).map(([key, lyr]) => {
              const newLyr = newLrs.find(nl => nl.lid === key);
              if (!newLyr) {
                return [key, lyr];
              }
              const updatedLayer = uniqBy(
                [...lyr.layer, ...newLyr.layer],
                ({ data }) => data.fenceId,
              );
              return [key, { name: lyr.name, layer: updatedLayer }];
            }),
          ),
        );
      });
      setActiveRequests(n => n - 1);
    };
    loadMoreFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  useEffect(() => {
    const ld = layerData;

    if (isSome(ld) && microfencesData !== undefined && pointsData !== undefined) {
      const lmsr: LiveMapShoppingResult = {
        points: pointsData,
        layers: ld.value,
        microfences: microfencesData,
      };
      setResult(some(lmsr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerData, microfencesData, pointsData]);

  useEffect(() => {
    storeLayerGroups(toUndefined(result));
  }, [storeLayerGroups, result]);

  return { result, bounds, setBounds, loading: isLoading };
}
