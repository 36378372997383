import React, { forwardRef, ReactNode, RefObject } from 'react';
import { ZIndexes } from '../../../util/ZIndexes';
import { useColumnMenus } from '../SidebarAndMap/SidebarAndMap';

export const MapContainer = forwardRef<HTMLDivElement, { id: string; children: ReactNode }>(
  ({ id, children }, ref) => {
    const columnView = useColumnMenus();
    return (
      <div
        id={id}
        ref={ref}
        style={{
          position: 'relative',
          height: columnView ? '70vh' : '100%',
          zIndex: ZIndexes.MAP,
        }}
      >
        {children}
      </div>
    );
  },
);
