import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleWebhookPostProps {
  className?: string;
}

const IconSingleWebhookPost: React.FC<IconSingleWebhookPostProps> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 353 353" className={className}>
      <path
        d="M46.9125 306.089C42.8737 302.049 36.3246 302.048 32.2851 306.088L3.02962 335.343C-1.00987 339.382 -1.00987 345.931 3.02962 349.971C7.06843 354.01 13.6175 354.01 17.657 349.971L46.9125 320.716C50.9514 316.678 50.9514 310.128 46.9125 306.089Z"
        fill="#F2F2F2"
      />
      <path
        d="M105.422 306.088C101.383 302.049 94.8344 302.048 90.7942 306.088L61.5394 335.343C57.4999 339.382 57.4999 345.931 61.5394 349.97C65.5782 354.01 72.1273 354.01 76.1668 349.97L105.422 320.715C109.461 316.677 109.461 310.127 105.422 306.088Z"
        fill="#F2F2F2"
      />
      <path
        d="M46.9125 247.578C42.8737 243.538 36.3246 243.538 32.2851 247.578L3.02962 276.833C-1.00987 280.872 -1.00987 287.421 3.02962 291.46C7.06843 295.5 13.6175 295.5 17.657 291.46L46.9125 262.205C50.9514 258.167 50.9514 251.617 46.9125 247.578Z"
        fill="#F2F2F2"
      />
      <path
        d="M343.927 96.839L256.161 9.07385C244.057 -3.03155 224.369 -3.01638 212.278 9.07385L51.3775 169.976C39.3203 182.032 39.199 201.68 51.3775 213.858L139.142 301.623C151.268 313.749 170.897 313.752 183.024 301.623L343.927 140.721C356.005 128.643 356.043 108.955 343.927 96.839ZM220.577 30.0304L204.125 152.942C203.507 157.553 199.856 161.204 195.245 161.821L72.3341 178.274L220.577 30.0304ZM156.167 288.784C155.296 288.313 154.489 287.715 153.769 286.996L66.6764 199.902L167.543 186.399L156.167 288.784ZM178.277 277.116L188.67 183.571L197.989 182.324C211.823 180.472 222.777 169.518 224.628 155.686L225.875 146.366L319.42 135.972L178.277 277.116ZM228.703 125.24L242.205 24.3728L329.3 111.466C330.026 112.192 330.616 113.003 331.08 113.864L228.703 125.24Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleWebhookPost;
