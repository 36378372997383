import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleGlobe {
  className?: string;
}

const IconSingleGlobe: React.FC<IconSingleGlobe> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 150 149" className={className}>
      <path
        d="M42.7824 41.6527C39.6278 50.6538 37.8379 60.1435 37.4346 70.0705H70.6515V41.6527H42.7824Z"
        fill="#F2F2F2"
      />
      <path
        d="M57.2165 14.9757C52.8851 20.684 49.2598 26.6775 46.3379 32.9325H70.6501V0.493042C66.9157 3.78058 62.0481 8.60785 57.2165 14.9757Z"
        fill="#F2F2F2"
      />
      <path
        d="M8.0948 41.6527C3.77013 50.4532 1.22751 60.0753 0.653809 70.0705H28.7112C29.0873 60.2668 30.7355 50.746 33.6281 41.6527H8.0948Z"
        fill="#F2F2F2"
      />
      <path
        d="M37.4404 78.7916C37.8905 89.4781 39.9624 98.9617 42.8603 107.209H70.6498V78.7916H37.4404Z"
        fill="#F2F2F2"
      />
      <path
        d="M46.4058 115.931C49.8207 123.279 53.7626 129.371 57.4251 134.164C62.21 140.425 66.9801 145.154 70.6489 148.383V115.931H46.4058V115.931Z"
        fill="#F2F2F2"
      />
      <path
        d="M107.021 41.6527H79.3706V70.0705H112.441C111.991 59.3838 109.919 49.9001 107.021 41.6527Z"
        fill="#F2F2F2"
      />
      <path
        d="M28.7112 78.7916H0.653809C1.22751 88.7869 3.77013 98.4089 8.0948 107.209H33.6281C30.7355 98.1164 29.0873 88.5953 28.7112 78.7916Z"
        fill="#F2F2F2"
      />
      <path
        d="M92.4557 14.6995C87.7315 8.51738 83.0209 3.82824 79.3706 0.602509V32.9326H103.475C100.06 25.5842 96.1182 19.4923 92.4557 14.6995Z"
        fill="#F2F2F2"
      />
      <path
        d="M22.3206 21.8096C18.8749 25.2553 15.8117 28.9804 13.146 32.9319H36.8343V32.9322C40.3285 24.6116 44.9143 16.7113 50.4981 9.40407C52.4812 6.80892 54.4629 4.45579 56.3808 2.33713C43.605 5.60319 31.8889 12.2415 22.3206 21.8096Z"
        fill="#F2F2F2"
      />
      <path
        d="M50.4981 139.458C44.914 132.151 40.3285 124.25 36.8343 115.93H13.146C15.8117 119.881 18.8749 123.607 22.3206 127.052C31.8892 136.621 43.6053 143.259 56.3808 146.524C54.4629 144.406 52.4812 142.053 50.4981 139.458Z"
        fill="#F2F2F2"
      />
      <path
        d="M113.05 115.931C109.556 124.251 104.97 132.152 99.3861 139.459C97.403 142.054 95.4213 144.407 93.5034 146.526C106.279 143.26 117.995 136.621 127.564 127.053C131.009 123.608 134.073 119.883 136.738 115.931H113.05Z"
        fill="#F2F2F2"
      />
      <path
        d="M79.3706 115.931V148.248C83.087 144.963 87.8937 140.176 92.6656 133.888C96.9969 128.179 100.622 122.186 103.544 115.931H79.3706Z"
        fill="#F2F2F2"
      />
      <path
        d="M127.564 21.8096C117.995 12.2412 106.279 5.60292 93.5034 2.33743C95.4216 4.4558 97.403 6.80864 99.3861 9.40408C104.97 16.7113 109.556 24.6117 113.05 32.9322H136.738C134.073 28.9804 131.009 25.255 127.564 21.8096Z"
        fill="#F2F2F2"
      />
      <path
        d="M141.789 41.6527H116.256C119.148 50.746 120.797 60.2671 121.173 70.0705H149.23C148.656 60.0753 146.114 50.4532 141.789 41.6527Z"
        fill="#F2F2F2"
      />
      <path
        d="M79.3706 78.7916V107.209H107.101C110.256 98.2084 112.046 88.7187 112.449 78.7916H79.3706Z"
        fill="#F2F2F2"
      />
      <path
        d="M121.173 78.7916C120.797 88.5953 119.148 98.1161 116.256 107.209H141.789C146.114 98.4089 148.656 88.7869 149.23 78.7916H121.173Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleGlobe;
