/* eslint-disable @typescript-eslint/no-empty-interface */
import { SvgIcon } from '@mui/material';
import React from 'react';

interface IconManDownProps {}

const IconManDown: React.FC<IconManDownProps> = () => {
  return (
    <SvgIcon viewBox="0 0 366 365">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        width="400px"
        height="400px"
        viewBox="0 0 99.368 99.368"
      >
        <g>
          <g>
            <circle cx="29.484" cy="41.129" r="4.605" />
            <path
              d="M34.354,42.376c0.368,1.551,1.617,3.211,3.513,3.225l10.164,0.075c1.257,0.009,2.245-0.697,2.869-1.681l15.351,0.038
							c0.002,0,0.002,0,0.008,0c1.342,0,2.433-1.088,2.438-2.432c0.002-1.347-1.086-2.441-2.433-2.444l-13.444-0.033
							c0.176,0.254,0.312,0.543,0.371,0.864c0.236,1.232-0.575,2.426-1.806,2.663c-0.192,0.036-4.711,0.894-9.124,0.894
							c-1.425,0-2.695-0.091-3.796-0.268c0.003-0.021,0.008-0.045,0.01-0.068c0.005-0.138,0.021-0.274,0.037-0.41
							c1.187,0.199,2.497,0.276,3.816,0.276c4.354,0,8.782-0.837,9.03-0.884c0.971-0.186,1.605-1.123,1.421-2.092
							c-0.186-0.97-1.122-1.605-2.091-1.42c-0.074,0.014-6.87,1.295-11.317,0.636c0.033-0.161,0.063-0.323,0.085-0.487
							c0.802,0.113,1.733,0.171,2.783,0.171c3.542,0,7.332-0.644,8.139-0.79c-0.575-0.585-1.341-0.978-2.284-0.985l-10.165-0.075
							c-1.885-0.014-3.162,1.633-3.552,3.168c0,0-0.149,0.484-0.143,1c0.008,0.494,0.119,1.045,0.119,1.045L34.354,42.376z"
            />
            <circle cx="13.649" cy="30.632" r="5.212" />
            <circle cx="87.286" cy="31.553" r="5.212" />
            <path
              d="M97.618,44.643c-5.427-0.742-7.83-3.399-9.038-4.991c-1.117-1.591-1.6-2.251-2.547-2.688c0,0-0.502-0.275-1.075-0.385
							c-0.549-0.104-1.187-0.108-1.187-0.108h-0.017c-0.271,0.008-6.182,0.04-11.557,7.013c-0.588,0.782-1.196,1.591-1.911,2.481
							c-0.354,0.444-0.49,0.992-0.427,1.516c-0.41,0.266-0.93,0.453-1.504,0.577v-0.397c0-0.94-0.762-1.703-1.701-1.703H27.221
							c-0.94,0-1.702,0.762-1.702,1.703v0.468c-0.876-0.141-1.681-0.397-2.197-0.843c-0.396-0.341-0.58-0.762-0.58-1.328
							c0-0.575-0.465-1.042-1.041-1.042c-0.575,0-1.04,0.467-1.04,1.042c0,0.25,0.025,0.49,0.065,0.724
							c-2.136-0.399-3.364-0.912-3.996-1.642c-0.77-0.89-1.531-5.619-1.638-5.819c-0.314-1.428-1.481-2.588-2.694-3.176
							c0,0-0.502-0.276-1.075-0.385c-0.549-0.105-1.185-0.108-1.185-0.108l-0.016-0.001c-1.805,0.053-3.931,1.059-4.38,3.155
							l-2.409,11.25c-0.24,1.115,0.071,2.144,0.691,2.979L3.5,60.918L0.174,69.52c-0.512,1.326,0.147,2.818,1.473,3.33
							c0.307,0.119,0.62,0.176,0.929,0.176c1.033,0,2.008-0.627,2.403-1.647l3.469-8.97c0.096-0.244,0.151-0.5,0.169-0.76l0.304-4.647
							l3.061,4.837l2.377,8.911c0.308,1.151,1.349,1.913,2.486,1.913c0.221,0,0.443-0.027,0.667-0.087
							c1.374-0.366,2.189-1.778,1.823-3.151l-2.477-9.293c-0.068-0.252-0.173-0.492-0.313-0.714l-4.167-6.585
							c0.132-0.266,0.24-0.554,0.309-0.871l0.93-4.347c1.444,1.75,3.85,2.746,8.198,3.34c0.094,0.013,0.187,0.019,0.277,0.019
							c0.764,0,1.448-0.435,1.791-1.093c0.539,0.171,1.106,0.291,1.673,0.366c0.161,0.772,0.845,1.353,1.665,1.353h0.142v2.695h3.535
							l9.539,3.758l-0.002,0.006l5.043,1.987l-4.813,1.892l0.006,0.011l-13.446,5.297h-1.033v1.134h0.319v0.619
							c-0.587,0.309-0.992,0.918-0.992,1.627c0,1.018,0.826,1.844,1.844,1.844c1.019,0,1.845-0.826,1.845-1.844
							c0-0.709-0.407-1.318-0.994-1.627v-0.619h36.883v0.619c-0.589,0.309-0.994,0.918-0.994,1.627c0,1.018,0.825,1.844,1.845,1.844
							c1.019,0,1.844-0.826,1.844-1.844c0-0.709-0.404-1.318-0.992-1.627v-0.619h0.889v-1.134h-1.033L54.61,62.498v-0.002l-6.216-2.449
							l6.656-2.617l-0.005-0.011l7.936-3.125h3.533V51.6h0.144c0.843,0,1.537-0.615,1.672-1.418c1.023-0.176,2.031-0.494,2.832-1.054
							c0.229,0.085,0.467,0.132,0.705,0.132c0.594,0,1.182-0.259,1.58-0.757c0.754-0.94,1.411-1.813,1.99-2.582
							c1.342-1.786,2.279-3.027,3.425-3.874l-1.894,8.833c-0.238,1.116,0.073,2.143,0.693,2.979l-0.522,7.984l-3.328,8.602
							c-0.512,1.326,0.146,2.818,1.473,3.33c0.308,0.119,0.62,0.175,0.929,0.175c1.033,0,2.009-0.625,2.403-1.647l3.47-8.97
							c0.096-0.243,0.15-0.5,0.168-0.761l0.306-4.646l3.061,4.836l2.376,8.912c0.307,1.152,1.35,1.913,2.486,1.913
							c0.221,0,0.444-0.028,0.665-0.087c1.376-0.367,2.19-1.777,1.825-3.152l-2.479-9.293c-0.064-0.252-0.171-0.492-0.31-0.713
							l-4.17-6.586c0.134-0.266,0.243-0.554,0.311-0.871l1.667-7.782c1.803,1.804,3.818,2.836,9.08,3.555
							c0.093,0.013,0.188,0.019,0.275,0.019c0.996,0,1.863-0.735,2.004-1.75C99.497,45.815,98.725,44.794,97.618,44.643z M54.217,63.487
							l0.004-0.009l9.551,3.763H30.104l10.952-4.313l0,0l5.879-2.311L54.217,63.487z M54.659,56.434L54.659,56.434l-7.724,3.035
							l-6.109-2.408l-0.002,0.006l-7.049-2.775h19.606h3.324H60.1L54.659,56.434z M65.378,53.156h-8.672h-3.324H28.498v-1.561h24.662
							h4.177h8.042V53.156z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
export default IconManDown;
