import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleWebhookGetProps {
  className?: string;
}

const IconSingleWebhookGet: React.FC<IconSingleWebhookGetProps> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 366 360" className={className}>
      <path
        d="M237.606 115.816C235.427 122.034 234.191 128.588 233.912 135.445H256.856V115.816H237.606Z"
        fill="#F2F2F2"
      />
      <path
        d="M247.576 97.3902C244.584 101.333 242.08 105.473 240.062 109.793H256.854V87.3867C254.275 89.6575 250.913 92.9918 247.576 97.3902Z"
        fill="#F2F2F2"
      />
      <path
        d="M213.646 115.816C210.659 121.895 208.903 128.541 208.507 135.445H227.887C228.146 128.674 229.285 122.097 231.283 115.816H213.646Z"
        fill="#F2F2F2"
      />
      <path
        d="M233.916 141.469C234.227 148.85 235.658 155.401 237.66 161.097H256.854V141.469H233.916Z"
        fill="#F2F2F2"
      />
      <path
        d="M240.108 167.122C242.467 172.198 245.19 176.405 247.72 179.716C251.025 184.041 254.319 187.307 256.854 189.537V167.122H240.108V167.122Z"
        fill="#F2F2F2"
      />
      <path
        d="M281.977 115.816H262.878V135.445H285.721C285.41 128.064 283.978 121.513 281.977 115.816Z"
        fill="#F2F2F2"
      />
      <path
        d="M227.887 141.469H208.507C208.903 148.373 210.659 155.019 213.646 161.097H231.283C229.285 154.817 228.146 148.24 227.887 141.469Z"
        fill="#F2F2F2"
      />
      <path
        d="M271.916 97.1995C268.653 92.9294 265.399 89.6905 262.878 87.4624V109.793H279.527C277.169 104.718 274.446 100.51 271.916 97.1995Z"
        fill="#F2F2F2"
      />
      <path
        d="M223.473 102.111C221.093 104.491 218.977 107.064 217.136 109.793H233.498V109.793C235.911 104.046 239.079 98.5892 242.936 93.5419C244.305 91.7494 245.674 90.124 246.999 88.6606C238.174 90.9166 230.082 95.5018 223.473 102.111Z"
        fill="#F2F2F2"
      />
      <path
        d="M242.936 183.372C239.078 178.325 235.911 172.868 233.498 167.121H217.136C218.977 169.85 221.093 172.424 223.473 174.803C230.082 181.413 238.175 185.998 246.999 188.253C245.674 186.79 244.305 185.165 242.936 183.372Z"
        fill="#F2F2F2"
      />
      <path
        d="M286.141 167.122C283.727 172.869 280.56 178.326 276.703 183.373C275.333 185.166 273.964 186.791 272.64 188.254C281.464 185.999 289.557 181.413 296.166 174.804C298.546 172.424 300.661 169.851 302.503 167.122H286.141V167.122Z"
        fill="#F2F2F2"
      />
      <path
        d="M262.878 167.122V189.444C265.445 187.175 268.765 183.869 272.061 179.525C275.053 175.582 277.557 171.443 279.575 167.122H262.878Z"
        fill="#F2F2F2"
      />
      <path
        d="M296.166 102.11C289.556 95.5014 281.464 90.9162 272.64 88.6606C273.965 90.1238 275.333 91.749 276.703 93.5417C280.56 98.5889 283.727 104.046 286.141 109.793H302.503C300.661 107.063 298.545 104.49 296.166 102.11Z"
        fill="#F2F2F2"
      />
      <path
        d="M305.992 115.816H288.355C290.353 122.097 291.492 128.674 291.752 135.445H311.131C310.735 128.541 308.979 121.895 305.992 115.816Z"
        fill="#F2F2F2"
      />
      <path
        d="M262.878 141.469V161.097H282.032C284.211 154.88 285.447 148.326 285.726 141.469H262.878Z"
        fill="#F2F2F2"
      />
      <path
        d="M291.751 141.469C291.491 148.24 290.352 154.817 288.354 161.097H305.991C308.978 155.019 310.734 148.373 311.13 141.469H291.751Z"
        fill="#F2F2F2"
      />
      <path
        d="M135.992 51.8228C134.591 55.8195 133.796 60.0333 133.617 64.4412H148.367V51.8228H135.992Z"
        fill="#F2F2F2"
      />
      <path
        d="M142.401 39.9772C140.478 42.5119 138.868 45.1732 137.57 47.9506H148.366V33.5464C146.708 35.0062 144.546 37.1496 142.401 39.9772Z"
        fill="#F2F2F2"
      />
      <path
        d="M120.589 51.8228C118.669 55.7305 117.54 60.003 117.285 64.4412H129.744C129.911 60.0881 130.642 55.8605 131.927 51.8228H120.589Z"
        fill="#F2F2F2"
      />
      <path
        d="M133.619 68.3135C133.819 73.0586 134.739 77.2697 136.026 80.9319H148.365V68.3135H133.619Z"
        fill="#F2F2F2"
      />
      <path
        d="M137.601 84.8047C139.117 88.0675 140.867 90.7725 142.494 92.9008C144.618 95.6809 146.736 97.7807 148.365 99.2144V84.8048H137.601V84.8047Z"
        fill="#F2F2F2"
      />
      <path
        d="M164.516 51.8228H152.238V64.4412H166.923C166.723 59.6959 165.803 55.4849 164.516 51.8228Z"
        fill="#F2F2F2"
      />
      <path
        d="M129.744 68.3135H117.285C117.54 72.7517 118.669 77.0242 120.589 80.9319H131.927C130.642 76.8943 129.911 72.6666 129.744 68.3135Z"
        fill="#F2F2F2"
      />
      <path
        d="M158.049 39.8543C155.951 37.1092 153.859 35.0271 152.238 33.5947V47.9504H162.941C161.425 44.6874 159.675 41.9824 158.049 39.8543Z"
        fill="#F2F2F2"
      />
      <path
        d="M126.906 43.0117C125.376 44.5417 124.016 46.1957 122.832 47.9503H133.35V47.9505C134.902 44.2559 136.938 40.7478 139.418 37.5032C140.298 36.3509 141.178 35.306 142.03 34.3652C136.357 35.8155 131.155 38.7631 126.906 43.0117Z"
        fill="#F2F2F2"
      />
      <path
        d="M139.418 95.251C136.938 92.0064 134.902 88.4983 133.35 84.8037H122.832C124.016 86.5583 125.376 88.2125 126.906 89.7424C131.155 93.9911 136.357 96.9387 142.03 98.3887C141.178 97.4482 140.298 96.4035 139.418 95.251Z"
        fill="#F2F2F2"
      />
      <path
        d="M167.193 84.8047C165.641 88.4992 163.605 92.0072 161.126 95.2519C160.245 96.4043 159.365 97.4491 158.514 98.3897C164.187 96.9396 169.389 93.9919 173.638 89.7434C175.168 88.2134 176.528 86.5593 177.711 84.8047H167.193V84.8047Z"
        fill="#F2F2F2"
      />
      <path
        d="M152.238 84.8047V99.1546C153.889 97.696 156.023 95.5705 158.142 92.7781C160.065 90.2433 161.675 87.5821 162.972 84.8047H152.238Z"
        fill="#F2F2F2"
      />
      <path
        d="M173.638 43.0115C169.389 38.7629 164.186 35.8152 158.514 34.3652C159.365 35.3059 160.245 36.3506 161.126 37.5031C163.605 40.7477 165.641 44.2557 167.193 47.9503H177.711C176.528 46.1956 175.167 44.5414 173.638 43.0115Z"
        fill="#F2F2F2"
      />
      <path
        d="M179.954 51.8228H168.616C169.9 55.8605 170.633 60.0882 170.799 64.4412H183.258C183.003 60.003 181.874 55.7305 179.954 51.8228Z"
        fill="#F2F2F2"
      />
      <path
        d="M152.238 68.3135V80.9319H164.552C165.953 76.9351 166.747 72.7214 166.926 68.3135H152.238Z"
        fill="#F2F2F2"
      />
      <path
        d="M170.8 68.3135C170.633 72.6666 169.901 76.8942 168.616 80.9319H179.954C181.874 77.0242 183.003 72.7517 183.258 68.3135H170.8Z"
        fill="#F2F2F2"
      />
      <path
        d="M360.331 201.578C348.935 193.846 335.494 196.037 323.596 202.907C311.699 209.777 243.382 268.066 243.382 268.066L176.14 268.076C173.018 268.076 169.624 264.671 169.624 261.561C169.624 257.895 172.819 255.045 176.14 255.045H217.285C231.681 255.045 247.806 245.667 247.806 228.976C247.806 211.255 231.681 202.907 217.285 202.907C192.306 202.907 187.438 203.005 187.438 203.005C177.43 203.005 167.283 202.403 159.462 198.216C149.03 192.907 137.017 189.887 124.194 189.887C102.66 189.887 83.3857 198.472 70.1797 212.01L0 281.098L78.1901 359.288L104.253 333.227H236.228C249.364 333.227 262.043 328.408 271.861 319.685L361.222 219.441C366.681 214.592 366.373 205.678 360.331 201.578ZM262.675 310.407C255.311 316.724 245.948 320.203 236.229 320.203L97.951 320.211L78.1901 340.858L18.5025 281.17L79.5087 221.109C90.786 209.549 107.072 202.918 124.194 202.918C134.515 202.918 144.667 205.308 153.313 209.705C163.707 215.27 175.858 216.037 187.438 216.037C187.438 216.037 192.432 215.938 217.285 215.938C217.46 215.938 234.774 216.087 234.774 228.976C234.774 237.87 224.338 242.013 217.285 242.013H175.057C165.979 242.013 156.38 248.601 156.38 262.43C156.38 272.728 164.709 281.106 175.06 281.106L248.009 281.095C248.009 281.095 324.764 217.111 330.956 213.716C337.148 210.322 344.693 208.089 351.081 211.232L262.675 310.407Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleWebhookGet;
