/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Grid, Paper, Stack, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Copyright from '../Components/Global/Copyright';
import { AUTH_NULLABLE, IS_SIGNED_IN, TIMED_OUT } from '../store/auth';
import { PasskeyInfo } from './PasskeyInfo';
import { useAcceptInviteCallback } from './useAcceptInvite';
import { usePasskeyRegister } from './usePasskeyRegister';

export function AcceptInvite() {
  const isSignedIn = useAtomValue(IS_SIGNED_IN);
  const setAuth = useSetAtom(AUTH_NULLABLE);
  const setTimedOut = useSetAtom(TIMED_OUT);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const email = query.get('email') ?? 'n/a';
  const secret = query.get('secret') ?? 'n/a';
  const [mismatch, setMismatch] = useState(false);
  const [tooShort, setTooShort] = useState(false);
  const isButtonEnabled = password === password2 && password.length > 7;

  const navigate = useNavigate();

  const {
    execute: accept,
    loading: acceptLoading,
    error: acceptError,
    data: jwts,
  } = useAcceptInviteCallback({ password, secret, email });
  const {
    execute: registerPasskey,
    loading: registerPasskeyLoading,
    error: registerPasskeyError,
    data: passkeyVerified,
  } = usePasskeyRegister(jwts);

  const handleConfirmPasswordKeypress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => e.code === 'Enter' && isButtonEnabled && accept(),
    [isButtonEnabled, accept],
  );

  const setSignedIn = useCallback(() => {
    setAuth(jwts ?? null);
    setTimedOut(false);
  }, [setAuth, setTimedOut, jwts]);

  useEffect(() => {
    if (passkeyVerified) {
      setSignedIn();
    }
  }, [passkeyVerified, setSignedIn]);

  useEffect(() => {
    if (isSignedIn) {
      navigate('/project-selection');
    }
  }, [isSignedIn, navigate]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(90.26deg, #4CB8C4 0.14%, #3CD3AD 99.83%)',
      }}
    >
      <Grid container direction="column" justifyContent="center" height="100%">
        <Container component="main" maxWidth="xs">
          <Stack direction="column" spacing={2}>
            <img src="./GeoMobyW.png" alt="Logo Geomoby" width="100%" />
            <Paper elevation={5}>
              <Box p={2}>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{ marginBottom: jwts ? 1 : undefined }}
                >
                  {jwts ? 'Enable passwordless login?' : 'Enter account password'}
                </Typography>
                {!jwts && (
                  <form className={'sign-in-form'} noValidate autoComplete="off">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={tooShort}
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      margin="normal"
                      onChange={e => {
                        setPassword(e.target.value);
                        if (e.target.value.length > 7) setTooShort(false);
                      }}
                      onBlur={() => {
                        if (password.length <= 7) setTooShort(true);
                        if (password2.length && password !== password2) setMismatch(true);
                      }}
                    />
                    {tooShort && (
                      <Typography variant="caption" color="red">
                        Password must have length greater than 7
                      </Typography>
                    )}

                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={mismatch}
                      id="password-confirm"
                      name="password-confirm"
                      type="password"
                      label="Confirm password"
                      placeholder="Confirm password"
                      margin="normal"
                      onChange={e => {
                        setPassword2(e.target.value);
                        if (password === e.target.value) setMismatch(false);
                      }}
                      onKeyPress={handleConfirmPasswordKeypress}
                      onBlur={() => {
                        if (password !== password2) setMismatch(true);
                      }}
                    />
                    {mismatch && (
                      <Typography variant="caption" color="red">
                        Confirmation password does not match
                      </Typography>
                    )}

                    <LoadingButton
                      style={{ marginTop: 10 }}
                      variant="contained"
                      loading={acceptLoading}
                      size="large"
                      fullWidth
                      color="secondary"
                      onClick={accept}
                      disabled={!isButtonEnabled}
                    >
                      Accept Invitation
                    </LoadingButton>
                    {acceptError && (
                      <Typography variant="caption" color="red">
                        {(acceptError as Error).message}
                      </Typography>
                    )}
                  </form>
                )}
                {jwts && (
                  <>
                    <PasskeyInfo />
                    <LoadingButton
                      style={{ marginTop: 10 }}
                      variant="contained"
                      loading={registerPasskeyLoading}
                      size="large"
                      fullWidth
                      color="secondary"
                      onClick={registerPasskey}
                      //disabled={}
                    >
                      Create passkey
                    </LoadingButton>
                    {registerPasskeyError && (
                      <Typography variant="caption" color="red" component={'p'}>
                        {(registerPasskeyError as Error).message}
                      </Typography>
                    )}
                    <Button variant="text" onClick={setSignedIn}>
                      Skip
                    </Button>
                  </>
                )}
              </Box>
            </Paper>
          </Stack>
          <Box mt={6}>
            <Copyright />
          </Box>
        </Container>
      </Grid>
    </Box>
  );
}
