import { compareAsc, startOfDay } from 'date-fns';

export const groupByDay = <T extends { datetime: Date }>(acc: T[][], cur: T) => {
  if (acc.length === 0) return [[cur]];

  const thisDay = startOfDay(cur.datetime);
  const lastDay = startOfDay(acc[acc.length - 1][0].datetime);

  if (compareAsc(lastDay, thisDay) === 0)
    return [...acc.slice(0, -1), [...acc[acc.length - 1], cur]];

  return [...acc, [cur]];
};
