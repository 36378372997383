/* eslint-disable react/jsx-key */
import React, { Dispatch, SetStateAction } from 'react';
import { toNullable, Option } from 'fp-ts/es6/Option';
import { PenToolbar } from '../Toolbar/PenToolbar';
import { createPenTools } from '../ControlPanels/createPenTools';
import { createPenSubTools } from '../ControlPanels/createPenSubTools';
import {
  DrawType,
  EditType,
  GeofenceTypes,
  MeasurementType,
  MicrofenceTypes,
} from '../MapDefaults';

export const ToolPanel = (props: {
  mapType: 'INDOOR' | 'OUTDOOR';
  selectedLayer: Option<string>;
  geofenceType: GeofenceTypes | MicrofenceTypes | undefined;
  geofenceTooBig: boolean;
  editing: boolean;
  isLoading: boolean;
  setEditing: () => void;
  unsetEditing: () => void;
  drawType: Option<DrawType>;
  setDrawType: Dispatch<SetStateAction<Option<DrawType>>>;
  editType: Option<EditType>;
  setEditType: Dispatch<SetStateAction<Option<EditType>>>;
  measurementType: MeasurementType | undefined;
  setMeasurementType: Dispatch<SetStateAction<MeasurementType | undefined>>;
}) => {
  return (
    <>
      <PenToolbar>{createPenTools(props)}</PenToolbar>

      {toNullable(props.drawType) === 'MEASURE' && (
        <PenToolbar marginLeft={'73px'}>{createPenSubTools(props)}</PenToolbar>
      )}
    </>
  );
};
