import { useAtomValue } from 'jotai';
import React from 'react';
import { MapApiKey } from '../../../../Authn/useServcieConfig';
import { MAP_API_KEYS } from '../../../../store/map';
import { MapSourceType, MAP_SOURCE_TYPES, apiKeyNameByMapSource } from '../../MapDefaults';
import { ChangeLayer } from './ChangeLayer';

export const ChangeMapSourceType = ({
  current,
  setType,
}: {
  current: MapSourceType;
  setType: (layer: MapSourceType) => void;
}) => {
  const keys = useAtomValue(MAP_API_KEYS);
  const availableLayers = Object.entries(apiKeyNameByMapSource)
    .filter(
      (source): source is [MapSourceType, MapApiKey['provider']] => !source[1] || !!keys[source[1]],
    )
    .map(([source]) => source);

  return (
    <ChangeLayer layer={current} setLayer={layer => setType(layer)} layers={availableLayers} />
  );
};
