import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleNumber {
  className?: string;
}

const IconSingleNumber: React.FC<IconSingleNumber> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 320 356" className={className}>
      <path
        d="M128.731 266.25H88.7932V17.7489C88.7932 7.93355 80.8564 0 71.0411 0C61.2257 0 53.2921 7.93355 53.2921 17.7489V266.25H13.3546C8.06343 266.25 3.27275 269.393 1.14392 274.239C-0.988156 279.085 -0.0098692 284.746 3.59127 288.634L61.2809 350.756C63.8193 353.457 67.3327 355.001 71.0411 355.001C74.7527 355.001 78.2661 353.457 80.8044 350.756L138.491 288.634C142.095 284.746 143.054 279.101 140.941 274.239C138.829 269.373 134.019 266.25 128.731 266.25Z"
        fill="#F2F2F2"
      />
      <path
        d="M288.481 213H261.856C244.724 213 230.791 226.933 230.791 244.061V270.686C230.791 287.818 244.724 301.751 261.856 301.751H292.917V323.936C292.917 326.387 290.928 328.376 288.481 328.376H244.104C236.755 328.376 230.791 334.34 230.791 341.689C230.791 349.037 236.755 355.001 244.104 355.001H288.481C305.609 355.001 319.542 341.068 319.542 323.936V244.061C319.542 226.933 305.609 213 288.481 213V213ZM292.917 275.126H261.856C259.405 275.126 257.416 273.137 257.416 270.686V244.061C257.416 241.614 259.405 239.625 261.856 239.625H288.481C290.928 239.625 292.917 241.614 292.917 244.061V275.126Z"
        fill="#F2F2F2"
      />
      <path
        d="M244.104 115.376C236.755 115.376 230.791 121.34 230.791 128.689C230.791 136.037 236.755 142.001 244.104 142.001H306.23C313.578 142.001 319.542 136.037 319.542 128.689C319.542 121.34 313.578 115.376 306.23 115.376H288.481V13.3125C288.481 5.96397 282.517 0 275.168 0H244.104C236.755 0 230.791 5.96397 230.791 13.3125C230.791 20.661 236.755 26.625 244.104 26.625H261.856V115.376H244.104Z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleNumber;
