import { AxiosRequestConfig } from 'axios';
import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { hardRedirectToLoginPage } from '../util/hardRedirectToLoginPage';
import { LTP_API } from './url';

export const AUTH_NULLABLE = atomWithStorage<{ accessJwt: string; refreshJwt: string } | null>(
  '@geomoby/authorization',
  null,
  createJSONStorage(() => sessionStorage),
);

export const AUTH = atom(get => {
  const auth = get(AUTH_NULLABLE);
  if (!auth)
    return hardRedirectToLoginPage(
      'If the page is requesting this data, the user should be logged in.',
    );
  return auth;
});

export const ACCESS_JWT_TOKEN = atom(get => get(AUTH).accessJwt);
export const REFRESH_JWT_TOKEN = atom(get => get(AUTH).refreshJwt);

export const AUTHED_REQUEST_CONFIG = atom<AxiosRequestConfig>(get => ({
  baseURL: get(LTP_API),
  headers: { Authorization: `Bearer ${get(ACCESS_JWT_TOKEN)}` },
}));

export const IS_SIGNED_IN = atom(get => !!get(AUTH_NULLABLE));
export const TIMED_OUT = atom(false);
