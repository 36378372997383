export enum ZIndexes {
  MAP = 0,
  MAP_BANNER = 1,
  MAP_TOOLBAR = 2,
  NOTIFICATION_TOAST = 3,
}

export enum FenceZIndexes {
  FENCE_LOWEST_Z_INDEX = 1,
  FENCE_MID_Z_INDEX = 2,
  FENCE_HIGHEST_Z_INDEX = 3,
  LINE_Z_INDEX = 4,
  MARKER_FENCE_Z_INDEX = 7,
}
