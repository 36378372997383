import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAllInvites } from '../../Authn/useAllInvites';
import { useAllUsers } from '../../Authn/useAllUsers';
import { useCreateProject } from '../../Authn/useCreateProject';
import { AccessItem, ClientProjectAccessItem, useInviteUser } from '../../Authn/useInviteUser';
import { useMakeAppInvite } from '../../Authn/useMakeAppInvite';
import { CID_PID_PAIRS, UserAccess } from '../../store/user';

const apps = [
  { label: 'Zero Harm', value: 'zeroharm' },
  { label: 'Protect App', value: 'protect' },
];

export const SuperAdmin = () => {
  const { execute: refreshUsers, data: users } = useAllUsers();
  const { execute: refreshInvites, data: userInvites } = useAllInvites();

  const [newlyCreated, setNewlyCreated] = useState<UserAccess[]>([]);
  const clientProjects = useAtomValue(CID_PID_PAIRS);
  const clientInputColour = useCallback(
    (client: string) =>
      !client
        ? undefined
        : [...clientProjects, ...newlyCreated].find(({ clientId }) => clientId === client)
        ? 'success'
        : 'warning',
    [clientProjects, newlyCreated],
  );
  const projectInputColour = useCallback(
    (client: string, project: string) =>
      !client || !project
        ? undefined
        : [...clientProjects, ...newlyCreated].find(
            ({ clientId, projectId }) => clientId === client && projectId === project,
          )
        ? 'success'
        : 'warning',
    [clientProjects, newlyCreated],
  );

  const [email, setEmail] = useState('');
  const [emailClientId, setEmailClientId] = useState('');
  const [emailProjectId, setEmailProjectId] = useState('');

  const [appInviteAppName, setAppInviteAppName] = useState('');
  const [appInviteClientId, setAppInviteClientId] = useState('');
  const [appInviteProjectId, setAppInviteProjectId] = useState('');

  const [newClientId, setNewClientId] = useState('');
  const [newProjectId, setNewProjectId] = useState('');

  const emailAccess = useMemo<AccessItem[]>(
    () => [
      {
        type: 'client-project',
        clientId: emailClientId,
        projectId: emailProjectId,
      },
    ],
    [emailClientId, emailProjectId],
  );

  const newClientProjectAccess = useMemo<ClientProjectAccessItem>(
    () => ({
      type: 'client-project',
      clientId: newClientId,
      projectId: newProjectId,
    }),
    [newClientId, newProjectId],
  );

  const appInviteAccess = useMemo<ClientProjectAccessItem>(
    () => ({
      type: 'client-project',
      clientId: appInviteClientId,
      projectId: appInviteProjectId,
    }),
    [appInviteClientId, appInviteProjectId],
  );

  const {
    execute: createUser,
    data: emailInviteData,
    error: emailInviteError,
  } = useInviteUser(emailAccess, email);

  const {
    execute: createProject,
    data: createProjectData,
    error: createProjectError,
  } = useCreateProject(newClientProjectAccess);

  const {
    execute: createAppInvite,
    data: appInviteData,
    error: appInviteError,
  } = useMakeAppInvite(appInviteAccess, appInviteAppName ?? '');

  useEffect(() => {
    refreshUsers();
    refreshInvites();
  }, [refreshUsers, refreshInvites]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers, emailInviteData]);

  useEffect(() => {
    if (createProjectData && createProjectData.length > 1) {
      setNewlyCreated(prev =>
        uniqBy(
          [...prev, ...createProjectData.flatMap(x => (x.type === 'superadmin' ? [] : x))],
          ({ clientId, projectId }) => `${clientId}/${projectId}`,
        ),
      );
    }
  }, [createProjectData, setNewlyCreated]);

  return (
    <>
      <Stack p={2} spacing={4} marginTop={2}>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Create new project</Typography>
            <Stack direction="row" spacing={2}>
              <TextField label="Client ID" onChange={e => setNewClientId(e.target.value)} />
              <TextField label="Project ID" onChange={e => setNewProjectId(e.target.value)} />
            </Stack>
            <Button onClick={createProject} disabled={!newClientId || !newProjectId}>
              Create project
            </Button>
            {createProjectData && createProjectData.length === 1 && (
              <Typography variant="caption">Project already exists</Typography>
            )}
            {createProjectData && createProjectData.length > 1 && (
              <Typography variant="caption" color="greenyellow">
                Project created!
              </Typography>
            )}
            {createProjectError && (
              <Typography variant="caption" color="red">
                Error creating project
              </Typography>
            )}
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Create app invite</Typography>
            <Stack direction="row" spacing={2}>
              <FormControl sx={{ minWidth: 226 }}>
                <InputLabel id="app-invite-app-select-label">App</InputLabel>
                <Select
                  labelId="app-invite-app-select-label"
                  id="app-invite-app-select"
                  value={appInviteAppName}
                  label="App"
                  onChange={e => setAppInviteAppName(e.target.value)}
                >
                  {apps.map(({ label, value }, i) => (
                    <MenuItem key={'app' + i} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Client ID"
                onChange={e => setAppInviteClientId(e.target.value)}
                color={clientInputColour(appInviteClientId)}
              />
              <TextField
                label="Project ID"
                onChange={e => setAppInviteProjectId(e.target.value)}
                color={projectInputColour(appInviteClientId, appInviteProjectId)}
              />
            </Stack>
            <Button
              onClick={createAppInvite}
              disabled={!appInviteAppName || !appInviteClientId || !appInviteProjectId}
            >
              Create app invite
            </Button>
            {appInviteData && (
              <>
                <Typography variant="subtitle2" color="greenyellow">
                  Success! Save this{' '}
                  <strong>
                    {apps.find(app => app.value === appInviteData.app)?.label ?? appInviteData.app}
                  </strong>{' '}
                  invite link, it can not be viewed again:
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {appInviteData.inviteUrl}
                </Typography>
              </>
            )}
            {appInviteError && (
              <Typography variant="caption" color="red">
                Error generating invite
              </Typography>
            )}
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Send email invite</Typography>
            <Stack direction="row" spacing={2}>
              <TextField label="Email" onChange={e => setEmail(e.target.value)} />
              <TextField
                label="Client ID"
                onChange={e => setEmailClientId(e.target.value)}
                color={clientInputColour(emailClientId)}
              />
              <TextField
                label="Project ID"
                onChange={e => setEmailProjectId(e.target.value)}
                color={projectInputColour(emailClientId, emailProjectId)}
              />
            </Stack>
            <Button onClick={createUser} disabled={!email || !emailClientId || !emailProjectId}>
              Send email invite
            </Button>
            {emailInviteData && (
              <Typography variant="caption" color="greenyellow">
                Sent!
              </Typography>
            )}
            {emailInviteError && (
              <Typography variant="caption" color="red">
                Ruh roh!
              </Typography>
            )}
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Active email invites</Typography>
            {userInvites?.map(invite => (
              <Paper elevation={6} key={invite.id}>
                <Box p={2}>
                  <Typography component="pre">ID: {invite.id}</Typography>
                  <Typography component="pre">
                    Access:{' '}
                    {invite.access
                      .map(access =>
                        access.type === 'superadmin'
                          ? 'STAFF'
                          : `${access.clientId}/${access.projectId}`,
                      )
                      .join(',')}
                  </Typography>
                  <Typography component="pre">
                    Created at: {new Date(invite.createdAt).toString()}
                  </Typography>
                </Box>
              </Paper>
            ))}
            <Button onClick={refreshInvites}>Refresh invites</Button>
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} spacing={2}>
            <Typography variant="h6">Users</Typography>
            {users?.map(user => (
              <Paper elevation={6} key={user.id}>
                <Box p={2}>
                  <Typography component="pre">ID: {user.id}</Typography>
                  <Typography component="pre">Label: {user.label}</Typography>
                  <Typography component="pre">
                    Access:{' '}
                    {user.access
                      .map(access =>
                        access.type === 'superadmin'
                          ? 'STAFF'
                          : `${access.clientId}/${access.projectId}`,
                      )
                      .join(',')}
                  </Typography>
                  <Typography component="pre">
                    Created at: {new Date(user.createdAt).toString()}
                  </Typography>
                </Box>
              </Paper>
            ))}
            <Button onClick={refreshUsers}>Refresh users</Button>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};
