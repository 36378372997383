import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { CMContainer } from '../Components/Map/Messages';
import {
  NEW_REPLAY_MESSAGES,
  REPLAY_END,
  REPLAY_MESSAGES,
  REPLAY_NOW,
  REPLAY_PLAYING,
  REPLAY_SECONDS_PER_SECOND,
} from '../store/replayEvents';
import { PERSISTOR_URL } from '../store/url';
import { CID, PID } from '../store/user';
import { useAuthedStream, useStreamMessages } from '../util/stream';
import { toUnixTs } from './time';

export const ReplayEventsProvider = ({ children }: { children: ReactNode }) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const streamerUrl = useAtomValue(PERSISTOR_URL);
  const [playing, setPlaying] = useAtom(REPLAY_PLAYING);
  const end = toUnixTs(useAtomValue(REPLAY_END));
  const [now, setNow] = useAtom(REPLAY_NOW);
  const secondsPerSecond = useAtomValue(REPLAY_SECONDS_PER_SECOND);

  // Stream URL is wrapped as a ref because if you ever want to use the stream URL,
  // it should always be starting "now" (wherever we are in the replay), NOT the start date.
  // But because "now" is always changing, we can't maintain its state as a literal.
  const streamUrlRef = useRef(
    `${streamerUrl}/${cid}/${pid}/public/replay/${toUnixTs(
      now,
    )}/${end}?secondsPerSecond=${secondsPerSecond}`,
  );

  useEffect(() => {
    const newStreamUrl = streamUrlRef.current.replace(
      /secondsPerSecond=.*/,
      `secondsPerSecond=${secondsPerSecond}`,
    );

    // Set replay start time to "now"
    if (!playing || newStreamUrl !== streamUrlRef.current)
      streamUrlRef.current = newStreamUrl.replace(/replay\/\w+/, `replay/${toUnixTs(now)}`);
  }, [playing, cid, pid, now, end, secondsPerSecond, streamerUrl]);

  const stream = useAuthedStream(
    `${streamerUrl}/${cid}/${pid}/replay`,
    streamUrlRef.current,
    playing,
  );

  const setMessages = useSetAtom(REPLAY_MESSAGES);
  const setNewMessages = useSetAtom(NEW_REPLAY_MESSAGES);
  const consumeEvent = useCallback(
    (message: { toUnixTs: number; events: CMContainer[] }) => {
      if (message.toUnixTs * 1000 > new Date().getTime()) {
        setPlaying(false);
      }
      setNow(new Date(message.toUnixTs * 1000));
      setNewMessages(messages => {
        if (message.events.length === 0) return messages;
        return [...messages, ...message.events];
      });
      return setMessages(messages => [...messages, ...message.events]);
    },
    [setMessages, setNewMessages, setNow, setPlaying],
  );

  useStreamMessages<{ toUnixTs: number; events: CMContainer[] }>(stream, consumeEvent);

  return <>{children}</>;
};
