/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

import { GridRowData } from '@material-ui/data-grid';
import {
  Battery20,
  Battery80,
  Clear,
  Delete,
  DirectionsRun,
  Edit,
  FilterList,
  HistoryToggleOff,
  MoreVert,
  Sensors,
  SensorsOff,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridInputRowSelectionModel } from '@mui/x-data-grid';
import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { keyBy } from 'lodash';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { NOTIFIER_URL, TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import Crossing from '../../Style/icons/Crossing';
import IconEntry from '../../Style/icons/IconEntry';
import IconExit from '../../Style/icons/IconExit';
import IconSingleOxygenAbove from '../../Style/icons/IconSingleOxygenAbove';
import IconSingleOxygenBelow from '../../Style/icons/IconSingleOxygenBelow';
import IconSingleTemperatureAbove from '../../Style/icons/IconSingleTemperatureAbove';
import IconSingleTemperatureBelow from '../../Style/icons/IconSingleTemperatureBelow';
import { useMobile } from '../../util/useMobile';
import { MICROFENCE_LAYER_ID } from '../Map/BeaconUtils';
import { FenceZone, GeofenceTypes } from '../Map/MapDefaults';
import { EBTFilterDialog } from './EBTFilterDialog';
import {
  ActionType,
  CrossingTypeId,
  EBT,
  EBTFilter,
  EmailType,
  EnteredTypeValue,
  EventIds,
  Geofence,
  GeofenceTypeId,
  Method,
  TriggerOption,
  TriggerSubType,
  TriggerType,
  WebhookType,
} from './types';
import { useAllBoundaries } from './useAllBoundaries';
import { TriggerTypes } from './values';

export const getEventTitle = (
  ebt: EBT,
  higherIsEnter: boolean,
  geofenceType: GeofenceTypeId,
): string => {
  switch (ebt.listenerType) {
    case 'sensor':
      return (higherIsEnter && String(ebt.entered) === 'true') ||
        (!higherIsEnter && String(ebt.entered) === 'false')
        ? 'Above'
        : 'Below';
    case 'geofence':
      if (ebt.dwellSeconds && String(ebt.entered) === 'true') {
        return 'Dwell';
      }
      return String(ebt.entered) === 'true'
        ? geofenceType === 'polygon' || geofenceType === 'multipolygon'
          ? 'Entry'
          : 'Left-to-right'
        : geofenceType === 'polygon' || geofenceType === 'multipolygon'
        ? 'Exit'
        : 'Right-to-left';
    case 'microfence':
      return String(ebt.entered) === 'true' ? 'Entry' : 'Exit';
    default:
      return 'Unknown Event';
  }
};

export const getTypeTitle = (
  listenerType: 'sensor' | 'geofence' | 'microfence',
  boundaryType: number,
  isGeofence: boolean,
  isMicrofence: boolean,
): string => {
  switch (listenerType) {
    case 'sensor':
      return boundaryType === 0
        ? 'Battery'
        : boundaryType === 1
        ? 'Temperature'
        : boundaryType === 2
        ? 'Spo2'
        : 'Unknown';
    case 'geofence':
      return isGeofence ? 'Location' : 'Unknown';
    case 'microfence':
      return isMicrofence ? 'Proximity' : 'Unknown';
    default:
      return 'Unknown Event';
  }
};

interface EventsListProps {
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  ebts: EBT[];
  setEbts: Dispatch<SetStateAction<EBT[]>>;
  pageRef: MutableRefObject<number>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSelectedTriggerType: Dispatch<SetStateAction<TriggerType | undefined>>;
  setSelectedTriggerOption: Dispatch<SetStateAction<TriggerOption | undefined>>;
  setSelectedTriggerSubType: Dispatch<SetStateAction<TriggerSubType | undefined>>;
  setSelectedCrossingDirection: Dispatch<SetStateAction<CrossingTypeId | undefined>>;
  setSelectedDropdownLayer: Dispatch<SetStateAction<string | null>>;
  setTriggerValue: Dispatch<SetStateAction<string | null>>;
  setSelectedGridRowData: Dispatch<SetStateAction<GridRowData | undefined>>;
  setSelectedTableData: Dispatch<SetStateAction<GridInputRowSelectionModel | undefined>>;
  setSelectedTriggerAction: Dispatch<SetStateAction<ActionType | undefined>>;
  setEmailData: Dispatch<SetStateAction<EmailType | null>>;
  setWebhookData: Dispatch<SetStateAction<WebhookType | null>>;
  setEventName: Dispatch<SetStateAction<string | null>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  setHideForm: Dispatch<SetStateAction<boolean>>;
  setClearForm: Dispatch<SetStateAction<boolean>>;
  eventIds: EventIds | null;
  setEventIds: Dispatch<SetStateAction<EventIds | null>>;
}

const EventsList: FC<EventsListProps> = ({
  refresh,
  setRefresh,
  ebts,
  setEbts,
  pageRef,
  page,
  setPage,
  setSelectedTriggerType,
  setSelectedTriggerOption,
  setSelectedTriggerSubType,
  setSelectedCrossingDirection,
  setSelectedDropdownLayer,
  setTriggerValue,
  setSelectedGridRowData,
  setSelectedTableData,
  setSelectedTriggerAction,
  setEmailData,
  setWebhookData,
  setEventName,
  setIsEditing,
  setHideForm,
  setClearForm,
  eventIds,
  setEventIds,
}) => {
  const notifierUrl = useAtomValue(NOTIFIER_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isMobile = useMobile();

  const { execute: refreshBoundaries, data: boundaries } = useAllBoundaries();

  const [ebtCount, setEbtCount] = useState<number>(0);
  const [dwellTimeMinimum, setDwellTimeMinimum] = useState<number | undefined>();
  const [ebtFilter, setEbtFilter] = useState<EBTFilter | undefined>();
  const [enteredState, setEnteredState] = useState<EnteredTypeValue>();
  const [expandRow, setExpandRow] = useState<string | undefined>();
  const [deleting, setDeleting] = useState<{ id: string; type: 'email' | 'webhook' } | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [geofences, setGeofences] = useState<Geofence[]>([]);
  const [microfences, setMicrofences] = useState<{ id: string; name: string }[]>([]);
  const [multipolygons, setMultipolygons] = useState<Geofence[]>([]);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);

  const geofenceIds = useRef<string>('');
  const microfenceIds = useRef<string>('');
  const multipolygonIds = useRef<string>('');

  const boundariesObj = useMemo(() => keyBy(boundaries, 'id'), [boundaries]);
  const geofencesObj = useMemo(() => keyBy(geofences, 'id'), [geofences]);
  const microfencesObj = useMemo(() => keyBy(microfences, 'id'), [microfences]);
  const multipolygonObj = useMemo(() => keyBy(multipolygons, 'id'), [multipolygons]);

  const paginateEBTs = useCallback(async () => {
    let filters = `?perPage=50`;
    Object.entries(ebtFilter ?? {}).map(([key, value]) => {
      if (key !== undefined && value !== undefined) {
        filters = filters.concat(
          `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
        );
      }
    });

    const { searches, count } = (
      await axios.get<{ searches: EBT[]; count: number }>(
        `${notifierUrl}/${cid}/${pid}/search/paginate/${deleting ? 1 : page}${filters}`,
        authedConfig,
      )
    ).data;
    const newList = [...(deleting ? [] : ebts), ...searches];
    setEbts(newList);
    setPage(prevPage => (count >= newList.length ? prevPage + 1 : prevPage));
    setEbtCount(count);
    setDeleting(undefined);
  }, [notifierUrl, authedConfig, cid, pid, page, ebtFilter, ebts, deleting, setEbts, setPage]);

  const getSpecifiedGeofences = useCallback(async () => {
    if (ebts === undefined) return;
    geofenceIds.current = ebts
      .filter(e => e.listenerType === 'geofence')
      .map(e => e.listenerId)
      .toString();

    if (!geofenceIds.current) return;
    setIsLoading(true);
    const { data: fences } = await axios.get<Geofence[]>(
      `${triggersUrl}/${cid}/${pid}/geofences/specified/${geofenceIds.current}`,
      authedConfig,
    );

    setGeofences(fences);
    setIsLoading(false);
  }, [ebts, triggersUrl, cid, pid, authedConfig, setIsLoading]);

  const getSpecifiedMicrofences = useCallback(async () => {
    if (ebts === undefined) return;
    microfenceIds.current = ebts
      .filter(e => e.listenerType === 'microfence')
      .map(e => e.listenerId)
      .toString();

    if (!microfenceIds.current) return;
    setIsLoading(true);
    const { data: fences } = await axios.get<{ id: string; name: string }[]>(
      `${triggersUrl}/${cid}/${pid}/microfences/specified/${microfenceIds.current}`,
      authedConfig,
    );

    setMicrofences(fences);
    setIsLoading(false);
  }, [ebts, triggersUrl, cid, pid, authedConfig, setIsLoading]);

  const getSpecifiedMultiPolygons = useCallback(async () => {
    if (ebts === undefined) return;
    multipolygonIds.current = ebts
      .filter(e => e.listenerType === 'geofence')
      .map(e => e.listenerId)
      .toString();

    if (!multipolygonIds.current) return;
    setIsLoading(true);
    const { data: clearedZones } = await axios.get<Geofence[]>(
      `${triggersUrl}/${cid}/${pid}/geofences/multipolygons/specified/${geofenceIds.current}`,
      authedConfig,
    );

    setMultipolygons(
      clearedZones.map(c => {
        return {
          ...c,
          type: 'multipolygon',
        };
      }),
    );
    setIsLoading(false);
  }, [ebts, triggersUrl, cid, pid, authedConfig, setIsLoading]);

  useEffect(() => {
    if (!refresh) return;
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([paginateEBTs(), refreshBoundaries()]);
      setIsLoading(false);
    };
    try {
      fetchData();
      setIsLoading(false);
      setRefresh(false);
    } catch (error) {
      return;
    }
  }, [refresh, setRefresh, paginateEBTs, refreshBoundaries, setIsLoading]);

  useEffect(() => {
    getSpecifiedGeofences();
    getSpecifiedMicrofences();
    getSpecifiedMultiPolygons();
  }, [getSpecifiedGeofences, getSpecifiedMicrofences, getSpecifiedMultiPolygons]);

  const getEventTileIcon = (eventTypeAndValue: string) => {
    if (eventTypeAndValue.includes('UNKNOWN')) return;
    const val = eventTypeAndValue.replaceAll(/\s/g, '');
    return (
      <Box
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: isMobile ? '25px' : '35px',
          },
        }}
      >
        {(() => {
          switch (val) {
            case 'PROXIMITY_ENTRY':
              return <Sensors />;
            case 'PROXIMITY_EXIT':
              return <SensorsOff />;
            case 'LOCATION_ENTRY':
              return <IconEntry />;
            case 'LOCATION_EXIT':
              return <IconExit />;
            case 'LOCATION_LEFT-TO-RIGHT':
              return <Crossing />;
            case 'LOCATION_RIGHT-TO-LEFT':
              return (
                <Box sx={{ '& svg': { rotate: '180deg' } }}>
                  <Crossing />
                </Box>
              );
            case 'LOCATION_DWELL':
              return <HistoryToggleOff />;
            case 'TEMPERATURE_BELOW':
              return <IconSingleTemperatureBelow />;
            case 'TEMPERATURE_ABOVE':
              return <IconSingleTemperatureAbove />;
            case 'SPO2_BELOW':
              return <IconSingleOxygenBelow />;
            case 'SPO2_ABOVE':
              return <IconSingleOxygenAbove />;
            case 'BATTERY_BELOW':
              return <Battery20 />;
            case 'BATTERY_ABOVE':
              return <Battery80 />;
            default:
              break;
          }
        })()}
      </Box>
    );
  };

  const getListeners = (ebt: EBT, actionType: 'EMAIL' | 'WEBHOOK') => {
    const typeTitle = getTypeTitle(
      ebt.listenerType,
      boundariesObj[ebt.listenerId]?.type,
      !!geofencesObj[ebt.listenerId] || !!multipolygonObj[ebt.listenerId],
      !!microfencesObj[ebt.listenerId],
    );
    const eventTitle = getEventTitle(
      ebt,
      boundariesObj[ebt.listenerId]?.higherIsEnter,
      geofencesObj[ebt.listenerId]?.type ?? multipolygonObj[ebt.listenerId]?.type,
    );

    return (
      <Grid
        style={{
          paddingBottom: '15px',
        }}
      >
        <Tooltip title={typeTitle}>
          <Typography>Event Type: {typeTitle}</Typography>
        </Tooltip>
        <Tooltip title={actionType}>
          <Typography>Action Type: {actionType}</Typography>
        </Tooltip>
        <Tooltip title={eventTitle}>
          <Typography>Event: {eventTitle}</Typography>
        </Tooltip>
        {ebt.listenerType === 'sensor' && (
          <Tooltip title={boundariesObj[ebt.listenerId]?.name}>
            <Typography>Label: {boundariesObj[ebt.listenerId]?.name}</Typography>
          </Tooltip>
        )}
        {ebt.listenerType === 'sensor' && (
          <Tooltip title={boundariesObj[ebt.listenerId]?.boundary}>
            <Typography>Value: {boundariesObj[ebt.listenerId]?.boundary}</Typography>
          </Tooltip>
        )}
        {ebt.listenerType === 'geofence' && ebt.dwellSeconds && (
          <Tooltip title={ebt.dwellSeconds}>
            <Typography>Seconds: {ebt.dwellSeconds}</Typography>
          </Tooltip>
        )}
        {ebt.listenerType === 'geofence' && (
          <Tooltip
            title={geofencesObj[ebt.listenerId]?.name ?? multipolygonObj[ebt.listenerId]?.name}
          >
            <Typography>
              Geofence:{' '}
              {geofencesObj[ebt.listenerId]?.name ?? multipolygonObj[ebt.listenerId]?.name}
            </Typography>
          </Tooltip>
        )}
        {ebt.listenerType === 'microfence' && (
          <Tooltip title={microfencesObj[ebt.listenerId]?.name}>
            <Typography>Microfence: {microfencesObj[ebt.listenerId]?.name}</Typography>
          </Tooltip>
        )}
      </Grid>
    );
  };

  const editEmail = async (email: EBT) => {
    window.document.body.scrollTop = document.documentElement.scrollTop = 0;
    setHideForm(false);
    setIsEditing(true);
    const foundBoundary = boundariesObj[email.listenerId];
    const foundGeofence = geofencesObj[email.listenerId];
    const foundMicrofence = microfencesObj[email.listenerId];
    const foundMultipolygon = multipolygonObj[email.listenerId];

    // Currently know way of singling out the correct page that the fence is associated with. I've created a ticket LTP-725.
    pageRef.current = 1;
    setEventIds({
      emailId: email.id,
      listenerId: email.listenerId,
    });
    const selectedTriggerValue = getTypeTitle(
      email.listenerType,
      boundariesObj[email.listenerId]?.type,
      !!geofencesObj[email.listenerId] || !!multipolygonObj[email.listenerId],
      !!microfencesObj[email.listenerId],
    );
    const selectedTriggerType = TriggerTypes.find(t => selectedTriggerValue === t.value);
    setSelectedTriggerType(selectedTriggerType);

    if (foundGeofence?.type === 'line') {
      setSelectedTriggerOption({ id: 'CROSSING', value: 'Crossing' });
      setSelectedCrossingDirection(email.entered ? 'LEFT' : 'RIGHT');
    } else {
      const selectedTriggerOptionValue = getEventTitle(
        email,
        boundariesObj[email.listenerId]?.higherIsEnter,
        geofencesObj[email.listenerId]?.type ?? multipolygonObj[email.listenerId]?.type,
      );
      setSelectedTriggerOption(
        selectedTriggerType?.options.find(o => selectedTriggerOptionValue === o.value),
      );
      setSelectedCrossingDirection(undefined);

      if (email.listenerType === 'geofence' && email.dwellSeconds) {
        setTriggerValue(String(email.dwellSeconds));
      }
      if (email.listenerType !== 'geofence' && email.listenerType !== 'microfence') {
        setEventName(foundBoundary?.name);
        setTriggerValue(String(foundBoundary?.boundary));
      }
    }

    const layerId =
      foundGeofence?.layerId ??
      foundMultipolygon?.layerId ??
      (foundMicrofence ? MICROFENCE_LAYER_ID : '');
    setSelectedDropdownLayer(layerId);

    if (foundGeofence) {
      const { data: geofence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${foundGeofence?.type}/${foundGeofence.id}`,
        authedConfig,
      );

      geofence.zone === FenceZone.cleared
        ? setSelectedTriggerSubType({ id: 'CLEARED_ZONE', value: 'Cleared Zone' })
        : setSelectedTriggerSubType({ id: 'GEOFENCE', value: 'Geofence' });
      setSelectedGridRowData({ id: geofence.id, row: geofence });
    } else if (foundMicrofence) {
      const { data: microfence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/microfences/${foundMicrofence.id}`,
        authedConfig,
      );
      setSelectedGridRowData({ id: microfence.id, row: microfence });
    } else if (foundMultipolygon) {
      const { data: clearedZone } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/multipolygon/${foundMultipolygon.id}`,
        authedConfig,
      );
      setSelectedTriggerSubType({ id: 'CLEARED_ZONE', value: 'Cleared Zone' });
      setSelectedGridRowData({ id: clearedZone.id, row: clearedZone });
    }

    setSelectedTableData([email.listenerId]);
    setSelectedTriggerAction({ id: 'EMAIL', value: 'Email' });
    setEmailData({
      message: email.text,
      subject: email.subject,
      address: email.recipients[0],
    });
  };

  const editWebhook = async (webhook: EBT) => {
    window.document.body.scrollTop = document.documentElement.scrollTop = 0;
    setHideForm(false);
    setIsEditing(true);

    const foundBoundary = boundariesObj[webhook.listenerId];
    const foundGeofence = geofencesObj[webhook.listenerId];
    const foundMicrofence = microfencesObj[webhook.listenerId];
    const foundMultipolygon = multipolygonObj[webhook.listenerId];

    // Currently know way of singling out the correct page that the fence is associated with. I've created a ticket LTP-725.
    pageRef.current = 1;
    setEventIds({
      webhookId: webhook.id,
      listenerId: webhook.listenerId,
    });
    const selectedTriggerValue = getTypeTitle(
      webhook.listenerType,
      boundariesObj[webhook.listenerId]?.type,
      !!geofencesObj[webhook.listenerId] || !!multipolygonObj[webhook.listenerId],
      !!microfencesObj[webhook.listenerId],
    );
    const selectedTriggerType = TriggerTypes.find(t => selectedTriggerValue === t.value);
    setSelectedTriggerType(selectedTriggerType);

    if (foundGeofence?.type === 'line') {
      setSelectedTriggerOption({ id: 'CROSSING', value: 'Crossing' });
      setSelectedCrossingDirection(webhook.entered ? 'LEFT' : 'RIGHT');
    } else {
      const selectedTriggerOptionValue = getEventTitle(
        webhook,
        boundariesObj[webhook.listenerId]?.higherIsEnter,
        geofencesObj[webhook.listenerId]?.type ?? multipolygonObj[webhook.listenerId]?.type,
      );
      setSelectedTriggerOption(
        selectedTriggerType?.options.find(o => selectedTriggerOptionValue === o.value),
      );
      setSelectedCrossingDirection(undefined);

      if (webhook.listenerType === 'geofence' && webhook.dwellSeconds) {
        setTriggerValue(String(webhook.dwellSeconds));
      }
      if (webhook.listenerType !== 'geofence' && webhook.listenerType !== 'microfence') {
        setEventName(foundBoundary?.name);
        setTriggerValue(String(foundBoundary?.boundary));
      }
    }

    const layerId =
      foundGeofence?.layerId ??
      foundMultipolygon?.layerId ??
      (foundMicrofence ? MICROFENCE_LAYER_ID : '');
    setSelectedDropdownLayer(layerId);

    if (foundGeofence) {
      const { data: geofence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${foundGeofence?.type}/${foundGeofence.id}`,
        authedConfig,
      );
      setSelectedTriggerSubType({ id: 'GEOFENCE', value: 'Geofence' });
      setSelectedGridRowData({ id: geofence.id, row: geofence });
    } else if (foundMicrofence) {
      const { data: microfence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/microfences/${foundMicrofence.id}`,
        authedConfig,
      );
      setSelectedGridRowData({ id: microfence.id, row: microfence });
    } else if (foundMultipolygon) {
      const { data: clearedZone } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/multipolygon/${foundMultipolygon.id}`,
        authedConfig,
      );
      setSelectedTriggerSubType({ id: 'CLEARED_ZONE', value: 'Cleared Zone' });
      setSelectedGridRowData({ id: clearedZone.id, row: clearedZone });
    }

    setSelectedTableData([webhook.listenerId]);
    setSelectedTriggerAction({ id: 'WEBHOOK', value: 'Webhook' });
    setWebhookData({
      method: (webhook.method as Method) ?? 'GET',
      url: webhook.url,
    });
  };

  const removeDwellTimeFromFence = async (layerId: string, id: string, type: GeofenceTypes) => {
    const fence = (
      await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
        authedConfig,
      )
    ).data;

    const geomobyProperties: Record<string, string> = fence.geomobyProperties;
    delete geomobyProperties['dwellSeconds'];

    await axios.patch(
      `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
      {
        geometry: fence.points,
        properties: {
          ...fence,
          geomobyProperties,
        },
        type: 'Feature',
      },
      authedConfig,
    );
  };

  const deleteAction = async (ebt: EBT, actionType: 'email' | 'webhook', actionId: string) => {
    try {
      if (ebt.listenerType === 'sensor') {
        await axios.delete(`${triggersUrl}/${cid}/${pid}/boundary/${ebt.listenerId}`, authedConfig);
      }
      await axios.delete(`${notifierUrl}/${cid}/${pid}/${actionType}/${actionId}`, authedConfig);
      if (eventIds?.listenerId === ebt.listenerId) {
        setClearForm(true);
      }
      if (ebt.dwellSeconds !== undefined) {
        const foundFence = geofences.find(f => f.id === ebt.listenerId);
        if (!foundFence) return;
        const { layerId, id, type } = foundFence;
        await removeDwellTimeFromFence(layerId, id, type);
      }
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      setSaveNotification({ id: SaveResult.FAIL, action: 'Delete' });
    }

    paginateEBTs();
  };

  return (
    <>
      <div
        style={{
          marginBottom: '20px',
          marginRight: '26px',
        }}
      >
        <Header icon={<DirectionsRun />}>Active events</Header>
      </div>

      <Grid
        container
        direction="column"
        style={{
          marginLeft: '40px',
          display: 'grid',
          gridTemplateColumns: '80px 80px',
          width: 'fit-content',
        }}
      >
        <Button onClick={() => setOpenFilterDialog(true)}>
          <span style={{ fontSize: '10px' }}>Filter</span>
          <FilterList />
        </Button>
        <Button
          onClick={() => {
            setEbtFilter(undefined);
            setEbts([]);
            setPage(1);
            setRefresh(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Clear</span>
          <Clear />
        </Button>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          marginBottom: '15px',
          marginTop: '-30px',
          paddingRight: '40px',
          alignContent: 'end',
        }}
      >
        <Typography>{`${ebtCount} ${Number(ebtCount) === 1 ? 'result' : 'results'}`}</Typography>
      </Grid>

      <Grid
        id="pagination-container"
        container
        justifyContent="left"
        onScroll={e => {
          const target = e.target as HTMLTextAreaElement;
          if (
            target.scrollTop + 50 > target.offsetTop * (page - 1) &&
            !isLoading &&
            ebtCount > ebts.length
          ) {
            paginateEBTs();
          }
        }}
        style={{
          paddingTop: '5px',
          marginLeft: '2%',
          border: 'inset #2D3748',
          maxWidth: '96%',
          width: 'fit-content, 30%',
          height: `1000px`,
          overflowX: 'hidden',
          overflowY: 'auto',
          flexFlow: 'column',
        }}
      >
        {ebts
          .filter(e => e.notifierType === 'EMAIL')
          ?.map(email => (
            <Box
              p={2}
              key={email.subject}
              style={{
                width: '100%',
                height: expandRow === email.id ? 'auto' : '200px',
                paddingBottom: '1px',
              }}
            >
              <Paper
                elevation={4}
                style={{
                  height: 'auto',
                  overflow: 'hidden',
                  background: email.id === eventIds?.emailId ? '#1E3748' : '#2D3748',
                }}
              >
                <Stack
                  p={4}
                  direction="column"
                  spacing={1}
                  style={{
                    overflowX: 'hidden',
                    paddingRight: '50px',
                    paddingBottom: isMobile ? '4px' : '20px',
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    style={{
                      display: 'grid',
                      gap: '2%',
                      gridTemplateColumns: isMobile ? '50% 60%' : '9% 31% 53% 1%',
                      width: '100%',
                    }}
                  >
                    {!isMobile && (
                      <Grid item>
                        {getEventTileIcon(
                          `${getTypeTitle(
                            email.listenerType,
                            boundariesObj[email.listenerId]?.type,
                            !!geofencesObj[email.listenerId] || !!multipolygonObj[email.listenerId],
                            !!microfencesObj[email.listenerId],
                          ).toUpperCase()}_
                        ${getEventTitle(
                          email,
                          boundariesObj[email.listenerId]?.higherIsEnter,
                          geofencesObj[email.listenerId]?.type ??
                            multipolygonObj[email.listenerId]?.type,
                        ).toUpperCase()}`,
                        )}
                      </Grid>
                    )}

                    <Grid
                      item
                      style={{
                        overflowX: 'auto',
                        maxWidth: isMobile ? '90%' : 'auto',
                        height: expandRow === email.id ? 'auto' : isMobile ? '90px' : '135px',
                      }}
                    >
                      <Box>
                        <Tooltip title={email.subject}>
                          <Typography variant="h6">{email.subject}</Typography>
                        </Tooltip>
                        <Tooltip title={email.recipients.join(',')}>
                          <Typography>Recipients: {email.recipients.join(',')}</Typography>
                        </Tooltip>
                        <Tooltip title={email.text}>
                          <Typography>Text: {email.text}</Typography>
                        </Tooltip>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      style={{
                        overflowY: expandRow === email.id ? 'hidden' : 'auto',
                        height: expandRow === email.id ? 'auto' : isMobile ? '90px' : '135px',
                      }}
                    >
                      {getListeners(email, 'EMAIL')}
                    </Grid>

                    {!isMobile && (
                      <Grid
                        item
                        container
                        direction="row"
                        spacing={2}
                        style={{
                          height: '100px',
                          marginTop: 'auto',
                        }}
                      >
                        <Grid item>
                          <Tooltip title={isLoading ? '' : 'Edit'}>
                            <Button
                              size="small"
                              disabled={isLoading}
                              onClick={() => {
                                editEmail(email);
                              }}
                            >
                              <Edit />
                            </Button>
                          </Tooltip>
                        </Grid>

                        <Grid item>
                          <Tooltip title={isLoading ? '' : 'Delete'}>
                            <Button
                              size="small"
                              disabled={isLoading}
                              onClick={() => {
                                setDeleting({ id: email?.id, type: 'email' });
                              }}
                            >
                              <Delete />
                            </Button>
                          </Tooltip>
                        </Grid>

                        {/*At the moment we don't have multiple listeners and the screen is big enough so no need to have this feature on a desktop*/}
                        {/*<Grid item>*/}
                        {/*  {*/}
                        {/*    <Tooltip title={expandRow !== email.id ? 'Show more' : 'Show less'}>*/}
                        {/*      <Button*/}
                        {/*        size="small"*/}
                        {/*        onClick={() =>*/}
                        {/*          setExpandRow(expandRow !== email.id ? email.id : undefined)*/}
                        {/*        }*/}
                        {/*      >*/}
                        {/*        <MoreVert />*/}
                        {/*      </Button>*/}
                        {/*    </Tooltip>*/}
                        {/*  }*/}
                        {/*</Grid>*/}
                      </Grid>
                    )}
                  </Grid>
                </Stack>
                {isMobile && (
                  <Grid
                    item
                    container
                    direction="column"
                    style={{
                      height: '40px',
                      display: 'grid',
                      gridTemplateColumns: '60% 10% 10% 10%',
                      paddingLeft: '7%',
                      width: '100%',
                    }}
                  >
                    <Grid item>
                      {getEventTileIcon(
                        `${getTypeTitle(
                          email.listenerType,
                          boundariesObj[email.listenerId]?.type,
                          !!geofencesObj[email.listenerId] || !!multipolygonObj[email.listenerId],
                          !!microfencesObj[email.listenerId],
                        ).toUpperCase()}_
                        ${getEventTitle(
                          email,
                          boundariesObj[email.listenerId]?.higherIsEnter,
                          geofencesObj[email.listenerId]?.type ??
                            multipolygonObj[email.listenerId]?.type,
                        ).toUpperCase()}`,
                      )}
                    </Grid>

                    <Grid item>
                      <Tooltip title={isLoading ? '' : 'Edit'}>
                        <Button
                          size="small"
                          disabled={isLoading}
                          onClick={() => {
                            editEmail(email);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title={isLoading ? '' : 'Delete'}>
                        <Button
                          size="small"
                          disabled={isLoading}
                          onClick={() => {
                            setDeleting({ id: email?.id, type: 'email' });
                          }}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      {
                        <Tooltip title={expandRow !== email.id ? 'Show more' : 'Show less'}>
                          <Button
                            size="small"
                            onClick={() =>
                              setExpandRow(expandRow !== email.id ? email.id : undefined)
                            }
                          >
                            <MoreVert />
                          </Button>
                        </Tooltip>
                      }
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Box>
          ))}

        {ebts
          .filter(e => e.notifierType === 'WEBHOOK')
          ?.map(webhook => (
            <Box
              p={2}
              key={webhook.method + webhook.url}
              style={{
                width: '100%',
                height: expandRow === webhook.id ? 'auto' : '200px',
                paddingBottom: '1px',
              }}
            >
              <Paper
                elevation={4}
                style={{
                  height: 'auto',
                  overflow: 'hidden',
                  background: webhook.id === eventIds?.webhookId ? '#1E3748' : '#2D3748',
                }}
              >
                <Stack
                  p={4}
                  direction="column"
                  spacing={1}
                  style={{
                    overflowX: 'hidden',
                    paddingRight: '50px',
                    paddingBottom: isMobile ? '4px' : '20px',
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    style={{
                      display: 'grid',
                      gap: '2%',
                      gridTemplateColumns: isMobile ? '50% 60%' : '9% 31% 53% 1%',
                      width: '100%',
                    }}
                  >
                    {!isMobile && (
                      <Grid item>
                        {getEventTileIcon(
                          `${getTypeTitle(
                            webhook.listenerType,
                            boundariesObj[webhook.listenerId]?.type,
                            !!geofencesObj[webhook.listenerId] ||
                              !!multipolygonObj[webhook.listenerId],
                            !!microfencesObj[webhook.listenerId],
                          ).toUpperCase()}_
                        ${getEventTitle(
                          webhook,
                          boundariesObj[webhook.listenerId]?.higherIsEnter,
                          geofencesObj[webhook.listenerId]?.type ??
                            multipolygonObj[webhook.listenerId]?.type,
                        ).toUpperCase()}`,
                        )}
                      </Grid>
                    )}

                    <Grid
                      item
                      style={{
                        overflowX: 'auto',
                        maxWidth: isMobile ? '90%' : 'auto',
                        height: expandRow === webhook.id ? 'auto' : isMobile ? '90px' : '135px',
                      }}
                    >
                      <Box>
                        <Tooltip title={webhook.method}>
                          <Typography variant="h6">{webhook.method}</Typography>
                        </Tooltip>
                      </Box>
                      <Box>
                        <Tooltip title={webhook.url}>
                          <Typography>{webhook.url}</Typography>
                        </Tooltip>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      style={{
                        overflowY: expandRow === webhook.id ? 'hidden' : 'auto',
                        height: expandRow === webhook.id ? 'auto' : isMobile ? '90px' : '135px',
                      }}
                    >
                      {getListeners(webhook, 'WEBHOOK')}
                    </Grid>

                    {!isMobile && (
                      <Grid
                        item
                        container
                        direction="row"
                        spacing={2}
                        style={{
                          height: '100px',
                          marginTop: 'auto',
                        }}
                      >
                        <Grid item>
                          <Tooltip title={isLoading ? '' : 'Edit'}>
                            <Button
                              size="small"
                              disabled={isLoading}
                              onClick={() => {
                                editWebhook(webhook);
                              }}
                            >
                              <Edit />
                            </Button>
                          </Tooltip>
                        </Grid>

                        <Grid item>
                          <Tooltip title={isLoading ? '' : 'Delete'}>
                            <Button
                              size="small"
                              disabled={isLoading}
                              onClick={() => {
                                setDeleting({ id: webhook?.id, type: 'webhook' });
                              }}
                            >
                              <Delete />
                            </Button>
                          </Tooltip>
                        </Grid>

                        {/*At the moment we don't have multiple listeners and the screen is big enough so no need to have this feature on a desktop*/}
                        {/*  <Grid item>*/}
                        {/*    {*/}
                        {/*      <Tooltip title={expandRow !== webhook.id ? 'Show more' : 'Show less'}>*/}
                        {/*        <Button*/}
                        {/*          size="small"*/}
                        {/*          onClick={() =>*/}
                        {/*            setExpandRow(expandRow !== webhook.id ? webhook.id : undefined)*/}
                        {/*          }*/}
                        {/*        >*/}
                        {/*          <MoreVert />*/}
                        {/*        </Button>*/}
                        {/*      </Tooltip>*/}
                        {/*    }*/}
                        {/*  </Grid>*/}
                      </Grid>
                    )}
                  </Grid>

                  {isMobile && (
                    <Grid
                      item
                      container
                      direction="column"
                      style={{
                        height: '40px',
                        display: 'grid',
                        gridTemplateColumns: '68% 13% 13% 13%',
                        width: '100%',
                      }}
                    >
                      <Grid item>
                        {getEventTileIcon(
                          `${getTypeTitle(
                            webhook.listenerType,
                            boundariesObj[webhook.listenerId]?.type,
                            !!geofencesObj[webhook.listenerId] ||
                              !!multipolygonObj[webhook.listenerId],
                            !!microfencesObj[webhook.listenerId],
                          ).toUpperCase()}_
                        ${getEventTitle(
                          webhook,
                          boundariesObj[webhook.listenerId]?.higherIsEnter,
                          geofencesObj[webhook.listenerId]?.type ??
                            multipolygonObj[webhook.listenerId]?.type,
                        ).toUpperCase()}`,
                        )}
                      </Grid>

                      <Grid item>
                        <Tooltip title={isLoading ? '' : 'Edit'}>
                          <Button
                            size="small"
                            disabled={isLoading}
                            onClick={() => {
                              editWebhook(webhook);
                            }}
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip title={isLoading ? '' : 'Delete'}>
                          <Button
                            size="small"
                            disabled={isLoading}
                            onClick={() => {
                              setDeleting({ id: webhook?.id, type: 'webhook' });
                            }}
                          >
                            <Delete />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        {
                          <Tooltip title={expandRow !== webhook.id ? 'Show more' : 'Show less'}>
                            <Button
                              size="small"
                              onClick={() =>
                                setExpandRow(expandRow !== webhook.id ? webhook.id : undefined)
                              }
                            >
                              <MoreVert />
                            </Button>
                          </Tooltip>
                        }
                      </Grid>
                    </Grid>
                  )}
                </Stack>
              </Paper>
            </Box>
          ))}
      </Grid>

      <Dialog open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Are you sure you want to delete this Event?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              if (deleting) {
                const deletable =
                  deleting.type === 'email'
                    ? ebts.filter(e => e.notifierType === 'EMAIL')?.find(e => e.id === deleting.id)
                    : ebts
                        .filter(e => e.notifierType === 'WEBHOOK')
                        ?.find(w => w.id === deleting.id);
                if (deletable) {
                  deleteAction(deletable, deleting.type, deleting.id);
                } else {
                  setRefresh(true);
                }
              }
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70%',
            maxHeight: '80%',
            width: '650px',
            height: '80%',
          },
        }}
      >
        <EBTFilterDialog
          setOpenFilterDialog={setOpenFilterDialog}
          setEbts={setEbts}
          ebtFilter={ebtFilter}
          setEbtFilter={setEbtFilter}
          enteredState={enteredState}
          setEnteredState={setEnteredState}
          dwellTimeMinimum={dwellTimeMinimum}
          setDwellTimeMinimum={setDwellTimeMinimum}
          setRefresh={setRefresh}
          setPage={setPage}
        ></EBTFilterDialog>
      </Dialog>
    </>
  );
};
export default EventsList;
