import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

interface IconSingleNotif {
  className?: string;
}

const IconSingleNotif: React.FC<IconSingleNotif> = ({ className }) => {
  return (
    <SvgIcon viewBox="0 0 398 356" className={className}>
      <path
        d="M395.483 1.916a7.583 7.583 0 00-7.718-1.427L4.875 144.888a7.513 7.513 0 00-4.855 6.478 7.5 7.5 0 003.863 7.107l123.192 68.111a7.6 7.6 0 007.706-.214l91.984-57.757s-67.589 65.839-68.782 67.073c-1.193 1.235-2.763 2.37-2.763 5.326l-.004 107.443a7.51 7.51 0 004.669 6.976 7.592 7.592 0 008.261-1.665l63.546-63.997 72.475 42.381a7.588 7.588 0 006.727.463 7.521 7.521 0 004.419-5.062L397.761 9.384a7.48 7.48 0 00-2.278-7.468z"
        fill="#F2F2F2"
      />
    </SvgIcon>
  );
};
export default IconSingleNotif;
