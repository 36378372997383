import { LoadingButton } from '@mui/lab';
import { Container, Grid, Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Copyright from '../Components/Global/Copyright';
import { IS_SIGNED_IN } from '../store/auth';
import { PasskeyInfo } from './PasskeyInfo';
import { usePasskeyRegister } from './usePasskeyRegister';
import { useRecoverJwts } from './useRecoverJwts';
import { useResetPassword } from './useResetPassword';

export function AccountRecovery() {
  const isSignedIn = useAtomValue(IS_SIGNED_IN);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const secret = query.get('secret') ?? 'n/a';
  const [mismatch, setMismatch] = useState(false);
  const [tooShort, setTooShort] = useState(false);
  const isButtonEnabled = password === password2 && password.length > 7;

  const [mode, setMode] = useState<'passkey' | 'password'>('passkey');

  const navigate = useNavigate();

  const {
    execute: recoverJwts,
    loading: recoverJwtsLoading,
    error: recoverJwtsError,
    data: recoverdJwts,
  } = useRecoverJwts({ secret });
  const {
    execute: registerPasskey,
    loading: registerPasskeyLoading,
    error: registerPasskeyError,
    data: passkeyVerified,
  } = usePasskeyRegister(recoverdJwts);
  const {
    execute: resetPassword,
    loading: resetPasswordLoading,
    error: resetPasswordError,
  } = useResetPassword({ password, jwts: recoverdJwts });

  const handleConfirmPasswordKeypress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) =>
      e.code === 'Enter' && isButtonEnabled && resetPassword(),
    [isButtonEnabled, resetPassword],
  );

  useEffect(() => {
    if (isSignedIn) navigate('/project-selection');
  }, [isSignedIn, navigate]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(90.26deg, #4CB8C4 0.14%, #3CD3AD 99.83%)',
      }}
    >
      <Grid container direction="column" justifyContent="center" height="100%">
        <Container component="main" maxWidth="xs">
          <Stack direction="column" spacing={2}>
            <img src="./GeoMobyW.png" alt="Logo Geomoby" width="100%" />
            <Paper elevation={5}>
              <Box p={2}>
                <Typography variant="h6" textAlign="center" sx={{ marginBottom: 1 }}>
                  Recover your account
                </Typography>
                {!recoverdJwts && (
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    loading={recoverJwtsLoading}
                    disabled={!!recoverdJwts}
                    onClick={recoverJwts}
                    sx={{ marginTop: 1 }}
                  >
                    Get started
                  </LoadingButton>
                )}
                {recoverJwtsError && (
                  <Typography variant="caption" color="red">
                    {(recoverJwtsError as Error).message}
                  </Typography>
                )}
                {recoverdJwts && mode === 'passkey' && (
                  <>
                    <PasskeyInfo />
                    <LoadingButton
                      style={{ marginTop: 10 }}
                      variant="contained"
                      loading={registerPasskeyLoading}
                      size="large"
                      fullWidth
                      color="secondary"
                      onClick={registerPasskey}
                      disabled={passkeyVerified}
                    >
                      Create passkey
                    </LoadingButton>
                    <Button variant="text" size="small" onClick={() => setMode('password')}>
                      Use password instead
                    </Button>

                    {registerPasskeyError && (
                      <Typography variant="caption" color="red" component="p">
                        {(registerPasskeyError as Error).message}
                      </Typography>
                    )}
                  </>
                )}

                {recoverdJwts && mode === 'password' && (
                  <form className={'sign-in-form'} noValidate autoComplete="off">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={tooShort}
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      margin="normal"
                      onChange={e => {
                        setPassword(e.target.value);
                        if (e.target.value.length > 7) setTooShort(false);
                      }}
                      onBlur={() => {
                        if (password.length <= 7) setTooShort(true);
                        if (password2.length && password !== password2) setMismatch(true);
                      }}
                    />
                    {tooShort && (
                      <Typography variant="caption" color="red">
                        Password must have length greater than 7
                      </Typography>
                    )}

                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={mismatch}
                      id="password-confirm"
                      name="password-confirm"
                      type="password"
                      label="Confirm password"
                      placeholder="Confirm password"
                      margin="normal"
                      onChange={e => {
                        setPassword2(e.target.value);
                        if (password === e.target.value) setMismatch(false);
                      }}
                      onKeyPress={handleConfirmPasswordKeypress}
                      onBlur={() => {
                        if (password !== password2) setMismatch(true);
                      }}
                    />
                    {mismatch && (
                      <Typography variant="caption" color="red">
                        Confirmation password does not match
                      </Typography>
                    )}

                    <LoadingButton
                      style={{ marginTop: 10 }}
                      variant="contained"
                      loading={resetPasswordLoading}
                      size="large"
                      fullWidth
                      color="secondary"
                      onClick={resetPassword}
                      disabled={!isButtonEnabled}
                    >
                      Reset password
                    </LoadingButton>
                    {resetPasswordError && (
                      <Typography variant="caption" color="red" component="p">
                        {(resetPasswordError as Error).message}
                      </Typography>
                    )}

                    <Button variant="text" size="small" onClick={() => setMode('passkey')}>
                      Use passkey instead
                    </Button>
                  </form>
                )}
              </Box>
            </Paper>
          </Stack>
          <Box mt={6}>
            <Copyright />
          </Box>
        </Container>
      </Grid>
    </Box>
  );
}
