import {
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDistance } from 'date-fns';
import React, { ReactNode } from 'react';
import { truncationStyle } from '../Components/Map/AssetRenderer';

export const Sidebar = ({ children }: { children: ReactNode }) => (
  <Stack spacing={6} padding={2}>
    {children}
  </Stack>
);

export const Item = ({ children }: { children: ReactNode }) => (
  <Stack spacing={2}>{children}</Stack>
);

export const Header = ({ icon, children }: { icon: ReactNode; children: ReactNode }) => (
  <div>
    <div>
      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
        <Box color="primary.main">{icon}</Box>
        <Typography variant="h5">{children}</Typography>
      </Stack>
    </div>
  </div>
);

export const SubHeader = ({
  icon,
  children,
  timestamp,
}: {
  icon: ReactNode;
  children: ReactNode;
  timestamp?: string;
}) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
      <Box color="primary.main">{icon}</Box>
      <Tooltip title={children}>
        <Typography style={truncationStyle} variant="h6">
          {children}
        </Typography>
      </Tooltip>
    </Stack>
    {timestamp && (
      <Tooltip
        title={formatDistance(new Date(timestamp), new Date(), {
          addSuffix: true,
        })}
      >
        <Typography variant="body2" style={truncationStyle}>
          {formatDistance(new Date(timestamp), new Date(), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>
    )}
  </Stack>
);

export const PaperBox = ({ children, ...rest }: BoxProps) => (
  <Paper>
    <Box p={2} overflow="auto" {...rest}>
      {children}
    </Box>
  </Paper>
);

export const OffsetPreTags = ({ children }: { children: ReactNode }) => (
  <div style={{ marginLeft: '2.5rem', lineHeight: '0.5rem' }}>{children}</div>
);

export const CenteredProgress = () => (
  <Stack direction="row" justifyContent="center">
    <CircularProgress />
  </Stack>
);
