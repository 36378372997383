export type ActionTypeId = 'EMAIL' | 'NOTIFICATION' | 'WEBHOOK';

export type ActionTypeValue = 'Email' | 'Notification' | 'Webhook';

export type CrossingTypeId = 'LEFT' | 'RIGHT';

export type CrossingTypeValue = 'Left-to-right' | 'Right-to-left';

export type EnteredTypeId =
  | 'ABOVE'
  | 'BELOW'
  | 'ENTER_GEOFENCE'
  | 'EXIT_GEOFENCE'
  | 'DWELL_GEOFENCE'
  | 'ENTER_MICROFENCE'
  | 'EXIT_MICROFENCE'
  | 'LEFT'
  | 'RIGHT';

export type EnteredTypeValue =
  | 'Above'
  | 'Below'
  | 'Enter'
  | 'Exit'
  | 'Dwell'
  | 'Left-to-right'
  | 'Right-to-left';

export type GeofenceTypeId = 'line' | 'polygon' | 'multipolygon';

export type GeofenceTypeValue = 'Line' | 'Polygon' | 'Multipolygon';

export type ListenerTypeId = 'geofence' | 'microfence' | 'sensor';

export type ListenerTypeValue =
  | 'Location'
  | 'Proximity'
  | 'Sensor (eg. Battery, Temperature, Spo2, etc)';

export type MethodTypeId = 'GET' | 'POST';

export type OrderTypeId = 'ASC' | 'DESC';

export type OrderTypeValue = 'Ascending' | 'Descending';

export type OrderByTypeId =
  | 'entered'
  | 'geofenceType'
  | 'listenerType'
  | 'method'
  | 'notifierType'
  | 'recipients'
  | 'subject'
  | 'text'
  | 'url';

export type OrderByTypeValue =
  | 'Event Trigger Option'
  | 'Event Trigger Type'
  | 'Geofence Type'
  | 'Method'
  | 'Notification Type'
  | 'Recipients'
  | 'Subject'
  | 'Text'
  | 'Url';

export type TriggerSubTypeId = 'CLEARED_ZONE' | 'GEOFENCE';

export type TriggerSubTypeValue = 'Geofence' | 'Cleared Zone';

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export type TriggerTypeId =
  | 'BATTERY'
  | 'CUSTOM'
  | 'ENVIRONMENTALMONITORING'
  | 'EXTENSOMETER'
  | 'INACTIVITY'
  | 'LOCATION'
  | 'MANDOWN'
  | 'PROXIMITY'
  | 'SPO2'
  | 'SCHEDULER'
  | 'TEMPERATURE'
  | 'WEBHOOK'
  | 'WELFARECHECKRESPONSE';

export type TriggerTypeValue =
  | 'Battery'
  | 'Custom'
  | 'Environmental Monitoring'
  | 'Extensometer'
  | 'Inactivity'
  | 'Location'
  | 'Man Down'
  | 'Proximity'
  | 'Scheduler'
  | 'Spo2'
  | 'Temperature'
  | 'Webhook'
  | 'Welfare Check Response';

export type TriggerTypeOptionsId =
  | 'ENTRY'
  | 'EXIT'
  | 'CROSSING'
  | 'DWELL'
  | 'BELOW'
  | 'ABOVE'
  | 'NEW_RULE'
  | 'GET'
  | 'POST'
  | 'STRING'
  | 'NUMBER';

export type TriggerTypeOptionsValue =
  | 'Entry'
  | 'Exit'
  | 'Crossing'
  | 'Dwell'
  | 'Below'
  | 'Above'
  | 'New rule'
  | 'GET'
  | 'POST'
  | 'String'
  | 'Number';

export type ActionType = { id: ActionTypeId; value: ActionTypeValue; disabled?: boolean };

export type CrossingType = { key: number; value: CrossingTypeId; label: CrossingTypeValue };

export type EnteredType = {
  id: EnteredTypeId;
  value: EnteredTypeValue;
  booleanValue: boolean;
  listenerTypeId: ListenerTypeId;
  geofenceTypeId?: 'line';
};

export type ListenerType = { id: ListenerTypeId; value: ListenerTypeValue };

export type EBT = {
  id: string;
  notifierType: ActionTypeId;
  geofenceType: GeofenceTypeId;
  listenerId: string;
  listenerType: ListenerTypeId;
  entered: boolean;
  dwellSeconds?: number;

  // Email
  subject: string;
  text: string;
  recipients: string[];
  sensorType: 'boundary';

  // Webhook
  url: string;
  method: MethodTypeId;
};

export type EBTFilter = {
  notifierType?: ActionType;
  geofenceType?: GeofenceType;
  listenerType?: ListenerType;
  sensorType?: 'boundary'; // We won't need to implement this unitl we have more than one option to choose from.
  dwellSeconds?: number;
  entered?: boolean;
  orderBy?: OrderByType;
  order?: OrderType;

  // Email
  recipient?: string;
  subject?: string;
  text?: string;

  // Webhook
  url?: string;
  method?: MethodTypeId;
};

export type EmailType = { address: string; subject: string; message: string };

export type EventIds = {
  emailId?: string;
  webhookId?: string;
  listenerId: string;
};

export type Geofence = {
  id: string;
  layerId: string;
  name: string;
  type: GeofenceTypeId;
  geomobyProperties?: Record<string, string>;
};

export type GeofenceType = { id: GeofenceTypeId; value: GeofenceTypeValue };

export type NotificationType = { title: string; body: string };

export type OrderType = { id: OrderTypeId; value: OrderTypeValue };

export type OrderByType = {
  id: OrderByTypeId;
  value: OrderByTypeValue;
  parentTypeId?: ActionTypeId;
};

export type TriggerOption = {
  id: TriggerTypeOptionsId;
  value: TriggerTypeOptionsValue;
  disabled?: boolean;
};

export type TriggerSubType = {
  id: TriggerSubTypeId;
  value: TriggerSubTypeValue;
};

export type TriggerType = {
  id: TriggerTypeId;
  value: TriggerTypeValue;
  options: TriggerOption[];
  subTypes?: TriggerSubType[];
  disabled?: boolean;
};

export type WebhookType = { method: Method; url: string };
