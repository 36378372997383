import React, { ReactNode, useState, useRef } from 'react';
import { Fab, Popover, Button, Grid } from '@mui/material';
import { Layers } from '@mui/icons-material';

export const ChangeLayer = <T extends string>({
  layer: selectedLayer,
  layers,
  setLayer,
}: {
  layer: T;
  layers: readonly T[];
  setLayer: (layer: T) => void;
}) => {
  const [selecting, setSelecting] = useState(false);

  const popoverRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Fab ref={popoverRef} color="default" size="small" onClick={() => setSelecting(!selecting)}>
        <Layers />
      </Fab>
      <Popover
        open={selecting}
        anchorEl={popoverRef.current}
        onClose={() => setSelecting(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction="column">
          {layers.map(layer => (
            <Grid item key={layer}>
              <Button
                color={layer === selectedLayer ? 'primary' : undefined}
                fullWidth
                key={layer}
                onClick={() => {
                  setSelecting(false);
                  setLayer(layer);
                }}
              >
                {layer}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};
