/* eslint-disable @typescript-eslint/no-empty-interface */
import { SvgIcon } from '@mui/material';
import React from 'react';

interface IconExtensometerProps {}

const IconExtensometer: React.FC<IconExtensometerProps> = () => {
  return (
    <SvgIcon viewBox="0 0 366 365">
      <svg
        height="400px"
        width="350px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <path
              d="M409.973,75.522h-24.866c-0.137,0-0.271,0.013-0.407,0.02c-0.136-0.007-0.27-0.02-0.407-0.02h-23.25
						c-2.252,0-4.391,0.991-5.847,2.708l-7.766,9.163c-2.423,2.859-2.423,7.05,0,9.909l3.567,4.21l-3.567,4.208
						c-2.423,2.859-2.423,7.051,0,9.91l3.567,4.208l-3.567,4.208c-2.423,2.859-2.423,7.05,0,9.909l3.567,4.21l-3.567,4.208
						c-2.423,2.86-2.423,7.05,0,9.91l7.766,9.163c1.456,1.718,3.595,2.708,5.847,2.708h23.25c0.137,0,0.271-0.013,0.407-0.02
						c0.136,0.007,0.27,0.02,0.407,0.02h24.866c7.135,0,13.394,6.259,13.394,13.395v64.247h-180v-64.247
						c0-7.136,6.259-13.395,13.395-13.395h24.865h23.251c2.252,0,4.391-0.991,5.847-2.708l7.766-9.163c2.423-2.86,2.423-7.05,0-9.91
						l-3.567-4.208l3.567-4.21c2.423-2.859,2.423-7.05,0-9.909l-3.567-4.208l3.567-4.208c2.423-2.859,2.423-7.051,0-9.91l-3.567-4.208
						l3.567-4.21c2.423-2.859,2.423-7.05,0-9.909l-7.766-9.163c-1.456-1.718-3.595-2.708-5.847-2.708h-23.251h-24.865
						c-56.259,0-102.028,45.77-102.028,102.028v27.953h-32.97c-4.232,0-7.663,3.43-7.663,7.663v10.093h-21.89v-84.409h14.694
						c4.232,0,7.663-3.43,7.663-7.663V97.632c0-4.233-3.431-7.663-7.663-7.663H30.835c-4.232,0-7.663,3.43-7.663,7.663v33.554
						c0,4.233,3.431,7.663,7.663,7.663H45.53v84.409H7.663c-4.232,0-7.663,3.43-7.663,7.663v64.618c0,4.233,3.431,7.663,7.663,7.663
						H45.53v84.395H30.835c-4.232,0-7.663,3.43-7.663,7.663v33.554c0,4.233,3.431,7.663,7.663,7.663h76.071
						c4.232,0,7.663-3.43,7.663-7.663v-33.554c0-4.233-3.431-7.663-7.663-7.663H92.212v-84.395h21.89v10.093
						c0,4.233,3.431,7.663,7.663,7.663h32.97v39.878c0,4.233,3.431,7.663,7.663,7.663h339.51c4.233,0,7.663-3.43,7.663-7.663v-41.956
						c1.491-1.398,2.428-3.38,2.428-5.586V177.55C512,121.292,466.231,75.522,409.973,75.522z M376.631,148.828h-12.036l-1.271-1.5
						l3.567-4.208c2.423-2.859,2.423-7.05,0-9.909l-3.567-4.21l3.567-4.208c2.423-2.859,2.423-7.051,0-9.91l-3.567-4.208l3.567-4.208
						c2.423-2.859,2.423-7.05,0-9.909l-3.567-4.21l1.271-1.5h12.036V148.828z M289.292,90.849h12.037l1.271,1.5l-3.567,4.21
						c-2.423,2.859-2.423,7.05,0,9.909l3.567,4.208l-3.567,4.208c-2.423,2.859-2.423,7.051,0,9.91l3.567,4.208l-3.567,4.21
						c-2.423,2.859-2.423,7.05,0,9.909l3.567,4.208l-1.271,1.5h-12.037V90.849z M38.498,123.523v-18.227h60.744v18.227H84.548H53.193
						H38.498z M76.885,138.849v84.409H60.857v-84.409H76.885z M99.242,402.924v18.227H38.498v-18.227h14.695h31.355H99.242z
						 M60.857,387.599v-84.395h16.029v84.395H60.857z M113.682,287.877H84.845c-0.099-0.004-0.196-0.015-0.296-0.015H53.193
						c-0.1,0-0.197,0.011-0.296,0.015h-37.57v-49.291h37.867h31.355h29.134V287.877z M154.736,305.633h-25.307V220.83h25.307V305.633z
						 M423.368,257.123v12.216h-180v-12.216H423.368z M423.368,284.666v20.968h-180v-20.968H423.368z M170.062,213.166V177.55
						c0-47.807,38.894-86.701,86.701-86.701h17.201v57.979h-17.201c-15.569,0-28.722,13.153-28.722,28.722v71.91v27.543v28.631h-57.979
						V213.166z M494.246,353.174H170.062V320.96h65.642h195.326h63.215V353.174z M496.674,305.632h-57.979v-28.631v-27.543v-71.91
						c-0.001-15.568-13.153-28.721-28.722-28.721h-17.203V90.849h17.203c47.806,0,86.701,38.894,86.701,86.701V305.632z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
export default IconExtensometer;
