import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

interface IProps {
  colorText?: string;
}

const Copyright: React.FC<IProps> = ({ colorText }) => {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="text.primary" href="https://geomoby.com/">
        GeoMoby
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
