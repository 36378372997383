import { Dispatch } from 'react';
import { StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';
import {
  AssetFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  OrderTypeValue,
  SearchType,
  SearchTypeValue,
  ToolFilter,
  ToolType,
} from '../types';
import { LayerFenceData, MicrofenceData } from '../Messages';
import { GridRowData } from '@material-ui/data-grid';
import { Point } from 'ol/geom';
import { BeaconFilterComponent } from './BeaconFilterComponent';
import { DeviceFilterComponent } from './DeviceFilterComponent';
import { GeofenceFilterComponent } from './GeofenceFilterComponent';
import { MicrofenceFilterComponent } from './MicrofenceFilterComponent';
import { ToolFilterComponent } from './ToolFilterComponent';
import { TrackerFilterComponent } from './TrackerFilterComponent';

export const FilterComponent = ({
  searchType,
  layerFilter,
  setLayerFilter,
  assetFilter,
  setAssetFilter,
  toolFilter,
  setToolFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  selectedMicrofence,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  setRefreshSearch,
  toolTypes,
  setToolTypes,
}: {
  searchType: SearchType | undefined;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  assetFilter: AssetFilter | undefined;
  setAssetFilter: Dispatch<AssetFilter | undefined>;
  toolFilter: ToolFilter | undefined;
  setToolFilter: Dispatch<ToolFilter | undefined>;
  geofenceFilter: GeofenceFilter | undefined;
  setGeofenceFilter: Dispatch<GeofenceFilter | undefined>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  selectedMicrofence: GridRowData | undefined;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  setRefreshSearch: Dispatch<boolean>;
  toolTypes: ToolType[];
  setToolTypes: Dispatch<ToolType[]>;
}) => {
  return (
    <>
      {searchType?.id === 'BEACONS' && (
        <BeaconFilterComponent
          assetFilter={assetFilter}
          setAssetFilter={setAssetFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></BeaconFilterComponent>
      )}

      {searchType?.id === 'GPSTRACKERS' && (
        <TrackerFilterComponent
          assetFilter={assetFilter}
          setAssetFilter={setAssetFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></TrackerFilterComponent>
      )}

      {searchType?.id === 'DEVICES' && (
        <DeviceFilterComponent
          assetFilter={assetFilter}
          setAssetFilter={setAssetFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></DeviceFilterComponent>
      )}

      {searchType?.id === 'GEOFENCES' && (
        <GeofenceFilterComponent
          layerFilter={layerFilter}
          setLayerFilter={setLayerFilter}
          geofenceFilter={geofenceFilter}
          setGeofenceFilter={setGeofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></GeofenceFilterComponent>
      )}

      {searchType?.id === 'MICROFENCES' && (
        <MicrofenceFilterComponent
          microfenceFilter={microfenceFilter}
          setMicrofenceFilter={setMicrofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></MicrofenceFilterComponent>
      )}

      {searchType?.id === 'TOOLS' && (
        <ToolFilterComponent
          toolFilter={toolFilter}
          setToolFilter={setToolFilter}
          toolTypes={toolTypes}
          setToolTypes={setToolTypes}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></ToolFilterComponent>
      )}
    </>
  );
};
