import { ThemeProvider, CssBaseline, CircularProgress } from '@mui/material';
import { enableAllPlugins } from 'immer';
import React, { ReactNode, Suspense } from 'react';
import { Routes } from './Components/Navigation/Routes';
import { ApiBaseProvider } from './hooks/geomoby/useApiBase';
import GeoMobyBaseTheme from './Style/GeoMobyBaseTheme';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import enAU from 'date-fns/locale/en-AU';
import { Provider } from 'jotai';
import { DevTools } from './util/DevTools';

export const App = () => {
  enableAllPlugins();

  return (
    <Provider>
      <Suspense fallback={<></>}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enAU}>
          <ApiBaseProvider
            ws={process.env.REACT_APP_LTP_WS ?? '/api'}
            xhr={process.env.REACT_APP_LTP_API ?? '/api'}
          >
            <ThemeProvider theme={GeoMobyBaseTheme}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </ApiBaseProvider>
        </LocalizationProvider>
      </Suspense>
    </Provider>
  );
};

export const DebugApp = () => (
  <DevTools>
    <App />
  </DevTools>
);
